import React, { Component } from "react";
import { withRouter } from "react-router-dom"; // new import
import { connect } from "react-redux"; // new import
import PropTypes from "prop-types"; // new import
import { Link } from "react-router-dom";
import {
    Container,
    Button,
    Row,
    Col,
    Form,
    FormControl
} from "react-bootstrap";

import { signupNewUser } from "./SignupActions"; // new import

class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            password2: "",
        };
    }
    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    // update function to call the action
    onSignupClick = () => {
        const userData = {
            username: this.state.username,
            password: this.state.password,
            password2: this.state.password2
        };
        this.props.signupNewUser(userData); 
    };

    render() {
        return (
            <Container>
                <Row>
                    <Col md="4">
                        <h1>Sign up</h1>
                        <Form>
                            <Form.Group controlId="usernameId">
                                <Form.Label>User name</Form.Label>
                                <Form.Control
                                    isInvalid={this.props.createUser.usernameError}
                                    type="text"
                                    name="username"
                                    placeholder="Enter user name"
                                    value={this.state.username}
                                    onChange={this.onChange}
                                />
                                <FormControl.Feedback type="invalid">
                                    {this.props.createUser.usernameError}
                                </FormControl.Feedback>
                            </Form.Group>
                            <br/>
                            <Form.Group controlId="passwordId">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    isInvalid={this.props.createUser.passwordError}
                                    type="password"
                                    name="password"
                                    placeholder="Enter password"
                                    value={this.password}
                                    onChange={this.onChange}
                                />
                            </Form.Group>
                            <br/>
                            <Form.Group controlId="password2Id">
                                <Form.Control
                                    isInvalid={this.props.createUser.passwordError}
                                    type="password"
                                    name="password2"
                                    placeholder="Repeat password"
                                    value={this.password2}
                                    onChange={this.onChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {this.props.createUser.passwordError}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form>
                        <br/>
                        <Button color="primary" onClick={this.onSignupClick}>
                            Sign up
                        </Button>
                        <p className="mt-2">
                            Already have account? <Link to="/login">Login</Link>
                        </p>
                    </Col>
                </Row>
            </Container>
        );
    }
}
// connect action and reducer
// replace 
// export default Signup;
// with code below:

Signup.propTypes = {
    signupNewUser: PropTypes.func.isRequired,
    createUser: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    createUser: state.createUser
});

export default connect(mapStateToProps, {
    signupNewUser
})(withRouter(Signup));
