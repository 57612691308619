import React, { useState, useEffect } from 'react';
import axios from "axios";
import { ThreeDots } from 'react-loader-spinner';
import '../../css/appsettings.css';


export default function Fitdownload(props) {
    const [isAsyncCall, setIsAsyncCall] = useState(false);
    const [downloadingFits, setDownloadingFits] = useState(false);
    const [testingGarminCreds, setTestingGarminCreds] = useState(false);
    const [garminCredsValid, setGarminCredsValid] = useState(true);


    // NOTE the empty dependency array [] at the end of useEffect. This will prevent useEffect from
    // being called ad infinitum, when state refreshes are done
    useEffect(() => {
        // If the environment supports async processing on the backend, 
        // the returns to the frontend will be differently structured and 
        // polling backend job progress is possible 
        if (process.env.REACT_APP_ENVASYNC === 'true') {
            setIsAsyncCall(true);
            doGetFitsFourstep(true);
        }
        else {
            doGetFitsFourstep(false);
        }

        // Call the function to download the FITs and process their contents
        setDownloadingFits(true);
                

    }, []); // includes empty dependency array here to prevent infinite loop of calls

    const closeGarminInvalidCredsPopup = () => {
        setGarminCredsValid(true);
        setDownloadingFits(false);
        // Callback to the parent window to close the overlay;
        props.callbackToShowModal(false);        
    }

    const doInitiateStepProcessor = (url) => {
        axios.get(url)
            .then((getResponse) => {
                console.log('Processed. Result:');
                console.log(getResponse);
                //setFullImporting(false);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const updateModalForFitGetter = (step) => {

        // Hide the progress bar and text until the credentials have been checked
        if (isAsyncCall && step === 0) {
            try {
                document.getElementById('processingDialogProgressText').style.visibility = 'hidden';
                document.getElementById('progressBarOuter').style.visibility = 'hidden';
            }
            catch (e) {
                console.error(e);
            }
        }
        let innerHTML = "";
        switch (step) {
            case 0:
                innerHTML = "Logging into Garmin Connect...";
                break;
            case 1:
                innerHTML = "Step 1/4: Downloading files from Garmin Connect";
                break;
            case 2:
                innerHTML = "Step 2/4: Reading data from FIT files";
                break;
            case 3:
                innerHTML = "Step 3/4: Updating weekly totals";
                break;
            case 4:
                innerHTML = "Step 4/4: Importing activity titles and notes";
                break;
            default:
                innerHTML = "Still working...";
        }
        if (document.getElementById('fitGetterProgress') != null) {
            document.getElementById('fitGetterProgress').innerHTML = innerHTML;
        }
    }

    // On the frontend, first verify the Garmin creds 
    // Step 0: verify Garmin login credentials
    // The call the backend url to kickoff, the four steps of the process to download and import the FIT files:
    // Step 1: download activities (FITs) and metadata (JSON) from Garmin Connect
    // Step 2: process fits 
    // Step 3: update weekly totals
    // Step 4: import activity titles and notes from downloaded metadata files
    // As the work is done, poll the updater to retrieve the job status and write the status to the frontend
    const doGetFitsFourstep = (asyncMode) => {

        // Update the text in the modal dialog: showing initial state, checking credentials
        setTimeout(updateModalForFitGetter, 100, 0);

        // Step 0: test login credentials
        if (process.env.REACT_APP_ENVNAME === 'DEV') console.log("Testing Garmin login credentials");
        axios.get(process.env.REACT_APP_TESTGARMINCREDS)
            .then((response) => {
                let validcredentials = response.data["validcreds"];
                if (process.env.REACT_APP_ENVNAME === 'DEV') console.log(response);
                if (!validcredentials) {
                    console.error('Invalid Garmin credentials');
                    setGarminCredsValid(false);
                }
                else {

                    // Update the text in the modal dialog: showing step-1 state
                    // Display this after 6 seconds
                    setTimeout(updateModalForFitGetter, 6000, 1);

                    // URL to kickoff the backend processing
                    let url = process.env.REACT_APP_PROCESSURL + '?allsteps=true';
                    //if (document.getElementById('refreshallfits').checked)
                    //    url += '&refreshall=True';
                    // Kickoff the 4-step backend processing
                    if (process.env.REACT_APP_ENVNAME === 'DEV') console.log('Initiating step processor'); 
                    doInitiateStepProcessor(url);

                    // A synchronous call was made. 
                    // Once the response returns, the process for that step is done
                    if (!asyncMode) {
                        // Todo: see if anything can be done to update on progress when synchronous
                        if (process.env.REACT_APP_ENVNAME === 'DEV') console.log('sync. no polling');

                    }
                    else {
                        if (process.env.REACT_APP_ENVNAME === 'DEV') console.log('async. starting polling');
                        // Start polling for progress updates
                        pollStatus4Step();
                    }
                }
            }
            ).catch((error) => {
                console.error(error);
            });
    } 

    const pollStatus4Step = () => {
        //console.log('pollStatus4Step');
        let url = process.env.REACT_APP_JOBSTATUS + '4step';
        //console.log(url);
        axios.get(url)
            .then((response) => {
                //console.log(response);

                //console.log("Processing pace zones. Response:");
                //console.log(getResponse);
                let status = response.data["status"];
                let step = parseInt(response.data["step"]);
                let progress = response.data["progress"];
                let process_state = response.data["process_state"];
                let last_error = response.data["lasterror"];
                let numTotal = response.data["numtotal"];
                let numProcessed = response.data["numprocessed"];
                let newWidth = parseInt(numProcessed / numTotal * 100) + '%';

                // Set the title of the modal popup to reflect the current step 
                updateModalForFitGetter(step);

                if (status === 'finished' && step === 4) {
                    if (process.env.REACT_APP_ENVNAME === 'DEV') {
                        console.log('closing');
                        console.log('status:', status);
                        console.log('step:', step);
                    }

                    // Finished processing
                    setDownloadingFits(false);
                    // Callback to the parent window to close the overlay;
                    props.callbackToShowModal(false);
                }
                // Finished a step, but not yet at step for, then keep polling
                else if (status === 'finished') {
                    // Recursively call self to re-poll in 100ms
                    setTimeout(pollStatus4Step, 100);                    
                }
                // Processing or started, keep polling and display progress
                else if (status === 'processing' || status === 'started') {
                    // Still processing, write the progress info into the overlay
                    if (step > 0) {

                        document.getElementById('processingDialogProgressText').style.visibility = 'visible';
                        document.getElementById('progressBarOuter').style.visibility = 'visible';

                        document.getElementById('processingDialogProgressText').innerHTML = 'Processing... ' + progress;
                        document.getElementById('progressBarInner').setAttribute("style", "width:" + newWidth);
                    }
                    // Recursively call self to re-poll in 100ms
                    setTimeout(pollStatus4Step, 100);
                }
                // Queued or undefined? The job is waiting to be processed, keep polling
                else if (status === 'queued' || status === undefined) {
                    if (process.env.REACT_APP_ENVNAME === 'DEV') console.log('Still wating on job start. Status=' + status);
                    // Recursively call self to re-poll in 100ms
                    setTimeout(pollStatus4Step, 100);                       
                }
                else {
                    // Unknown status
                    console.error('Unknown status for job. Status=' + status);
                    // Recursively call self to re-poll in 100ms
                    setTimeout(pollStatus4Step, 100);                    
                }

            }
            ).catch((error) => {
                console.error(error);
            });
    }



    return (
        <main className="container">
                    <div id="overlayPane">
                        <div id="processingDialogModalWindow">
                            {garminCredsValid ?
                                <div id="processingDialogInnerText">
                                    {testingGarminCreds ?
                                        'Testing Garmin login credentials...'
                                        : null}

                                    {downloadingFits ?
                                        (
                                            <>
                                                <span>Importing actvities from Garmin Connect&nbsp;&nbsp;</span>
                                                <img src="/GarminLogo.png" height="25px" width="25px" alt="Garmin Connect logo" title="Garmin Connect logo" />
                                                <br /><br />
                                                <span id="fitGetterProgress">Progress...</span>
                                                <br />
                                            </>
                                        ) : null}
                                    {!testingGarminCreds && isAsyncCall ?
                                        <>
                                            <br />
                                            <div id="processingDialogProgressText"></div>
                                            <br />
                                            <div id="progressBarOuter"><div id="progressBarInner"></div></div>
                                        </> : null}
                                    {(!isAsyncCall || testingGarminCreds) ?

                                        <div id="spinnerDiv">
                                            <ThreeDots color="hotpink" height={40} width={40} />
                                        </div>
                                        : null
                                    }
                                </div>
                                :
                                <div id="processingDialogInnerText">
                                    <div id="modalInvalidGarminCredsCloseWrapper">
                                        <span id="modalInvalidGarminCredsCloseButton" onClick={(e) => closeGarminInvalidCredsPopup()}>X</span>
                                    </div>
                                    <div id="errorTextInvalidGarminCreds">
                                        Invalid Garmin credentials. Please check the username and password of your Garmin Connect account under 'Settings'.
                                        <br/>
                                        Save these before importing your activities.
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
        </main>
    );
                        }



        
    
