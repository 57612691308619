import React, { useState } from "react";
import { useInterval } from './Utils';


export default function TextAnimate(parentprops) {

    const [displayText, setDisplayText] = useState(parentprops.text);

    useInterval(() => {
        setDisplayText("." + displayText);
    }, 300);
    return <div>{displayText}</div>;
}