import { useState } from "react";
import { Button } from "reactstrap";
import Modal from 'react-bootstrap/Modal'

export default function ConfirmDelete(props) {
    return (
        <Modal
            show='true'
            dialogClassName="confirmDelete"
            onHide={() => props.callback(false, false)}
            centered
            
        >
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    {props.text}<br/><br/>
                    <Button id="btnConfirmDelete" color="danger" className="btnMedium" onClick={() => props.callback(false, true)}>Yes</Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button id="btnCancelDelete" color="primary" className="btnMedium" onClick={() => props.callback(false, false)}>No</Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}
