import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button } from "reactstrap";
import axios from "axios";
import { toastOnError, prettyDateTime } from "../../utils/Utils";
import NavigationBar from "../../components/nav/NavigationBar";
import ActivityDataSummary from "../../components/dataviews/ActivityDataSummary";
import TableRowExpandedRow from "../../components/dataviews/TableRowExpandedRow";
import EfficiencyTable from "../../components/dataviews/EfficiencyTable";
import PowerZoneTable from "../../components/dataviews/PowerZoneTable";
import PaceZoneTable from "../../components/dataviews/PaceZoneTable";
import ConfirmDelete from "../../components/utils/ConfirmDelete";
import '../../css/activitydetails.css';

export default function ActivityDetails(parentprops) {
    const [dataFetched, setfetched] = useState(false);
    const [data, setData] = useState(null);
    const [activityID, setActivityID] = useState(useParams().activityid);
    const [toggleEditTitle, setToggleEditTitle] = useState(false);
    const [toggleEditNotes, setToggleEditNotes] = useState(false);
    const [url, setUrl] = useState(process.env.REACT_APP_APIBASEURL + '/api/activities/' + activityID + '/');
    const [actIdUp, setActIdUp] = useState(0)
    const [actIdDown, setActIdDown] = useState(0)
    const [siblingsFetched, setSiblingsFetched] = useState(false);
    const [toggleTriggerDeleteModal, setToggleTriggerDeleteModal] = useState(false);

    // NOTE the empty dependency array [] at the end of useEffect. This will prevent useEffect from
    // being called ad infinitum, when state refreshes are done
    useEffect(() => {
        if (activityID == null) {
            fetchNewestData(process.env.REACT_APP_APIBASEURL + '/api/activity/newest');
        }
        else {
            fetchData(url);
        }
    }, []); // includes empty dependency array here to prevent infinite loop of calls

    const fetchData = (actUrl) => {
        axios.get(actUrl)
            .then((response) => {
                let respData = response.data;
                setData(respData);
                fetchSibings(respData['activityID']);
                setfetched(true);
            }).catch((error) => {
                // If the fetch throws a 404, try a fetch with the ID as external ID for the activity 
                if (error.response.status == '404') {
                    fetcDataForExternalActivityId(activityID);
                }
                else {
                    console.error('ERROR retrieving activity data');
                    console.error(error);
                }
            });
    }

    const fetchNewestData = (actUrl) => {
        axios.get(actUrl)
            .then((response) => {
                let respData = response.data;
                let newestId = respData[0]['activityID'];
                let actUrl = process.env.REACT_APP_APIBASEURL + '/api/activities/' + newestId + '/'
                fetchData(actUrl);
                setUrl(actUrl);
                setActivityID(newestId);
            }).catch((error) => {
                console.error('ERROR retrieving activity data');
                console.error(error);
            });

    }

    const fetcDataForExternalActivityId = (externalID) => {
        axios.get(process.env.REACT_APP_APIBASEURL + '/api/activity/idfromexternal/' + externalID)
            .then((response) => {
                let respData = response.data;
                let nativeID = respData[0]['nativeID'];
                if (nativeID != null) {
                    // Set the browser address bar to contain the nativeID rather than the external one
                    window.history.pushState({ "html": response.html, "pageTitle": response.pageTitle }, "", "/activity/" + nativeID);

                    // Update the state variables to reflect the correct nativeID
                    let actUrl = process.env.REACT_APP_APIBASEURL + '/api/activities/' + nativeID + '/';
                    fetchData(actUrl);
                    setUrl(actUrl);
                    setActivityID(nativeID);

                    // Alternatively use a window.location.replace to make sure all data is pulled from the new native ID... but that triggers an ugly reload
                    //window.location.replace('/activity/' + nativeID);
                }
            }).catch((error) => {
                console.error('ERROR getting activity url for external ID');
                console.error(error);

            });
    }

    const fetchSibings = (actId) => {
        setSiblingsFetched(false);
        let urlSiblings = process.env.REACT_APP_APIBASEURL + '/api/activity/siblings/' + actId;
        axios.get(urlSiblings)
            .then((getResponse) => {
                let respData = getResponse.data;
                setActIdUp(respData[0]['up'] + 0);
                setActIdDown(respData[0]['down'] + 0);
                setSiblingsFetched(true);
            }).catch((error) => {
                console.error('ERROR retrieving activity siblings');
                console.error(error);
            });
    }

    const updateActivity = async (activityData) => {

        try {
            // Note: use a patch update, to only modify the specific  
            await axios.patch(url, activityData);
        } catch (error) {
            toastOnError(error);
        }
    }

    const deleteActivity = (requestConfirm, confirmed) => {
        if (requestConfirm) {
            // If the delete button was hit, without the modal having been triggered, trigger the modal
            setToggleTriggerDeleteModal(true);
            return;

        }
        else if (confirmed) {

            // Get the weeknum, to use in the call to update totals
            let weeknum = data.weeknum;
            // Tested: user A cannot delete an activity for user B
            // Laps will also be deleted via cascade delete
            axios
                .delete("/api/activities/" + activityID)
                .then(response => {
                    setToggleTriggerDeleteModal(false);

                    // Call the url to update the totals for the week in which the activity was deleted
                    axios
                        .get(process.env.REACT_APP_TOTALSURL + '?weeknum=' + weeknum)
                        .then(response => { })
                        .catch(error => {
                            toastOnError(error);
                        });

                    // Redirect to the latest activity
                    window.location.replace('/activity');
                })
                .catch(error => {
                    toastOnError(error);
                });
        }
        setToggleTriggerDeleteModal(false);

    };

    const updateActivityTitleClick = (e) => {
        e.preventDefault();

        let dict = {};
        let title = document.getElementById('activityTitleInput').value;
        dict['title'] = title;
        updateActivity(dict);

        // Write the values of the inputs back into the state's data dict,
        // to keep the state data in sync with the remote data
        let tempData = data;
        tempData['title'] = title;
        setData(tempData);
        setToggleEditTitle(false);

    }

    const updateActivityNotesClick = (e) => {
        e.preventDefault();

        let dict = {};
        let notes = document.getElementById('activityNotes').value;
        dict['notes'] = notes;
        updateActivity(dict);

        // Write the values of the inputs back into the state's data dict,
        // to keep the state data in sync with the remote data
        let tempData = data;
        tempData['notes'] = notes;
        setData(tempData);
        setToggleEditNotes(false);
    }



    return (
        <main>

            <NavigationBar parentprops={parentprops} />
            <div className="pageBody">
                <div className="row m-1">
                    <div className="col-md-12 col-sm-12 p-1">
                        <div className="card p-2" id="pageheaderbar">
                            <h5 className="p1">Activity details</h5>
                        </div>
                    </div>
                </div>

                {dataFetched ? (
                    <main>
                        {toggleTriggerDeleteModal ? (<ConfirmDelete title="Delete activity" text="Are you sure you want to delete this activity?" callback={deleteActivity} />) : null}
                        <div className="row m-1 px-2">
                            <div className="col-md-10 col-sm-10 p-1">
                                {toggleEditTitle ? (
                                    <div>
                                        <input type="text" style={{ 'width': '600px' }} id="activityTitleInput" defaultValue={data["title"]} />&nbsp;
                                        <a href="#" onClick={(e) => updateActivityTitleClick(e)}><img src="/greencheck.png" alt="save changes" title="save changes" /></a>
                                        <a href="#" onClick={(e) => setToggleEditTitle(false)}><img src="/greycross.png" alt="cancel edit" title="cancel edit" /></a>
                                    </div>
                                ) :
                                    (
                                        <div>
                                            <span style={{ 'width': '600px' }} id="activityTitle">{data["title"]}</span>&nbsp;&nbsp;
                                            <a href="#" onClick={(e) => setToggleEditTitle(true)}><img src="/pencil.png" alt="edit" title="edit" /></a>
                                        </div>
                                    )}
                            </div>
                            <div className="col-md-1 col-sm-1 p-1">
                            </div>
                            <div className="col-md-1 col-sm-1 p-1 text-end">
                                {siblingsFetched ? (
                                    <div>
                                        {actIdDown !== 0 ? (<a href={'/activity/' + actIdDown}><img style={{ 'height': '30px' }} src="/arrow_back.png" className="activityNavArrow" alt="Previous activity" title="Previous activity" /></a>) : null}
                                        {actIdUp !== 0 ? (<a href={'/activity/' + actIdUp}><img style={{ 'height': '30px' }} src="/arrow_forward.png" className="activityNavArrow" alt="Next activity" title="Next activity" /></a>) : null}
                                    </div>
                                ) : null}


                            </div>
                            <div id="activityDate">{prettyDateTime(data["date"])}</div>

                        </div>

                        <div className="row m-1 px-2"><div className="col-md-12 col-sm-12 p-0"><hr /></div></div>
                        <ActivityDataSummary data={data} />
                        <div className="row m-1 px-2"><div className="col-md-12 col-sm-12 p-0"><hr /></div></div>

                        <div className="row m-1 px-2">
                            <div className="col-md-6 col-sm-6 p-1">
                                <div className="card p-2" style={{ 'width': 'auto', 'height': '380px', }}>
                                    <div id="activityDate">Notes:</div>
                                    {toggleEditNotes ?
                                        (
                                            <main>
                                                <div>
                                                    <textarea id="activityNotes" style={{ 'width': '100%', 'height': '280px', }} defaultValue={data["notes"]} />
                                                </div>
                                            </main>
                                        ) :
                                        <div id="activityNotesBox" >{data["notes"]}</div>}
                                </div>
                            </div>
                            <div className="col-md-1 col-sm-1 p-1">
                                {toggleEditNotes ?
                                    (
                                        <main>
                                            <a href="#" onClick={(e) => updateActivityNotesClick(e)}><img src="/greencheck.png" alt="save changes" title="save changes" /></a>
                                            <a href="#" onClick={(e) => setToggleEditNotes(false)}><img src="/greycross.png" alt="cancel edit" title="cancel edit" /></a>
                                        </main>
                                    ) :
                                    <a href="#" onClick={(e) => setToggleEditNotes(true)}><img src="/pencil.png" alt="edit" title="edit" /></a>
                                }
                            </div>
                            <div className="col-md-5 col-sm-5 p-1">
                                <div className="card p-2">
                                    <h5>Time in pace zones</h5>
                                    <PaceZoneTable activityId={activityID} />

                                </div>
                            </div>
                        </div>

                        {data["avg_power"] !== '0' ? (
                            <div className="row m-1 px-2">
                                <div className="col-md-6 col-sm-6 p-1">
                                    <div className="card p-2">
                                        <h5>Efficiency in power zones</h5>
                                        <EfficiencyTable activityId={activityID} />

                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 p-1">
                                    <div className="card p-2">
                                        <h5>Time in power zones</h5>
                                        <PowerZoneTable activityId={activityID} />
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        <div className="row m-1 px-2">
                            <div className="col-md-12 col-sm-12 p-1">
                                <div className="card p-2">
                                    <TableRowExpandedRow data={data} theme="" />
                                </div>
                            </div>
                        </div>

                        <div className="row m-1 px-2">

                            <div id="activityIDS">Native ID: <span id="activityNativeID">{data["activityID"]}</span>,  Original ID: <span id="activityOriginalID">{data["externalID"]}</span></div>
                            <br />
                            <div className="col-md-12 col-sm-12 p-1 align-items-end">
                                <Button id="btnDeleteActivity" color="danger" onClick={(e) => deleteActivity(true, true)}>Delete activity</Button>
                            </div>
                        </div>


                    </main>
                ) : null}
            </div>
        </main>
    );
}






