import React, { Component } from "react";
import PaceZonesTable from "../../components/dataviews/PaceZonesTable";
import NavigationBar from "../../components/nav/NavigationBar";

export default class PaceZones extends Component {

    componentDidMount() {
    }
    render() {

        return (
            <main>
                <NavigationBar />
                <div className="pageBody">
                    <div className="row m-1">
                        <div className="col-md-12 col-sm-12 p-1">
                            <div className="card p-2" id="pageheaderbar">
                                <h5 className="p1">Pace Zones</h5>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="row m-1">
                            <div className="col-md-12 col-sm-12 p-1">
                                <h4>Pace zone setup: create an entry below, whenever your zone values change</h4>
                                <hr />
                                Zone settings:
                                <ul id="zonelist">
                                    <li>Name: a name by which to remember this pacezone. E.g. 'Spring 2022'</li>
                                    <li>Date: the start date, when these pace zones start to apply</li>
                                    <li>Easy pace: recovery pace or 'talking' pace</li>
                                    <li>Moderate pace: 'not quite comfortable' or 'need to push a little' pace</li>
                                    <li>Hard pace: Need to push hard, 'can definitely not talk' pace</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="row m-1">
                            <div className="col-md-12 col-sm-12 p-1">
                                <PaceZonesTable />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

