import React, { Component } from "react";
import Root from "./Root"; 
import { Route, Switch, BrowserRouter } from "react-router-dom"; // <--- remove BrowserRouter; using OLD react-router-dom v4.3.1 for compatibility with connected-react-router
import Home from "./views/app/Home";
import Signup from "./components/signup/Signup";
import Login from "./components/login/Login";
import PowerZones from "./views/app/PowerZones";
import PaceZones from "./views/app/PaceZones";
import Dashboard from "./views/app/Dashboard";
import DataTables from "./views/app/DataTables";
import ActivityDetails from "./views/app/ActivityDetails";
import ActivityOverview from "./views/app/ActivityOverview";
import AppSettings from "./views/app/AppSettings";
import ToDo from "./views/app/ToDo";
import { ToastContainer } from "react-toastify";
import requireAuth from "./utils/RequireAuth";
import './css/App.css';
import axios from "axios";
// Set the base URL for all axios calls 
axios.defaults.baseURL = process.env.REACT_APP_APIBASEURL;

class App extends Component {
    render() {
        return (
            <div>
                <Root> {/* Note keeping this in vs <Root> as that requires old v4.3.1 of react-router-dom, and causes issues. REDUCER rewrite needed!  */}
                    <ToastContainer hideProgressBar={true} newestOnTop={true} />
                    <Switch>
                        {/*<Route path="/signup" component={Signup} />*/}
                        <Route path="/login" component={Login} />
                        <Route path="/dashboard" component={requireAuth(Dashboard)} />
                        <Route path="/activity/:activityid" component={requireAuth(ActivityDetails)} />
                        <Route path="/activity" component={requireAuth(ActivityDetails)} />
                        <Route path="/activities" component={requireAuth(ActivityOverview)} />
                        <Route path="/pacezones" component={requireAuth(PaceZones)} />
                        <Route path="/powerzones" component={requireAuth(PowerZones)} />
                        <Route path="/datatables" component={requireAuth(DataTables)} />
                        <Route path="/settings" component={requireAuth(AppSettings)} />
                        <Route path="/todo" component={requireAuth(ToDo)} />
                        <Route exact path="/" component={Home} />
                        <Route path="*">No page there</Route>
                    </Switch>
                </Root>
            </div>
        );
    }
}

export default App;