import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Tableviews from "../../components/dataviews/Tableviews";
import { getWeekNum, getWeekYear } from '../../utils/Utils';
import NavigationBar from "../../components/nav/NavigationBar";

export default function DataTables() {

    const [weeknum, setWeekNum] = useState(useParams.weeknum);
    const [weeknumFetched, setWeeknumFetched] = useState(false);

    // NOTE the empty dependency array [] at the end of useEffect. This will prevent useEffect from
    // being called ad infinitum, when state refreshes are done
    useEffect(() => {

        if (weeknum == null) {
            let year = getWeekYear(new Date());
            let week = getWeekNum(new Date());
            setWeekNum(year.toString() + week.toString());

        }
        setWeeknumFetched(true);
    }, [weeknum]); // includes empty dependency array here to prevent infinite loop of calls



    return (
        <main>

            <NavigationBar />
            <div className="pageBody">
                <div className="row m-1">
                    <div className="col-md-12 col-sm-12 p-1">
                        <div className="card p-2" id="pageheaderbar">
                            <h5 className="p1">Data tables</h5>
                        </div>
                    </div>
                </div>

                <div className="row m-1">
                    <div className="col-md-12 col-sm-12 mx-auto p-1">
                        <div className="card p-2">
                            {weeknumFetched ? (
                                <Tableviews weekfilter_start={new Date().getFullYear() + '01'} weekfilter_end={weeknum} />
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </main>

    );
}


