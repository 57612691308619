import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Line } from "react-chartjs-2";
import { ThreeDots } from 'react-loader-spinner'
import { getWeekYear } from '../../utils/Utils';
import TextAnimate from '../../utils/TextAnimate';
import CustDropdownButton from './CustDropdownButton ';
//import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, } from 'chart.js';

export default function EfficiencyGraph(parentprops) {
    const [weekfilter_start, setWeekFilterStart] = useState(parentprops.weekfilter_start);
    const [weekfilter_end, setWeekFilterEnd] = useState(parentprops.weekfilter_end);
    const [rawData, setRawData] = useState({});
    const [rawDataFetched, setRawDataFetched] = useState(false);
    const [chartData, setChartData] = useState({});
    const [chartDataFetched, setChartDataFetched] = useState(false);
    const [chartOptions, setChartOptions] = useState({});
    const [P1Selected, setP1Selected] = useState(true);
    const [P2Selected, setP2Selected] = useState(true);
    const [P3Selected, setP3Selected] = useState(true);
    const [P4Selected, setP4Selected] = useState(true);
    const [P5Selected, setP5Selected] = useState(true);
    const [P6Selected, setP6Selected] = useState(false);
    const [P7Selected, setP7Selected] = useState(false);

    // NOTE the empty dependency array [] at the end of useEffect. This will prevent useEffect from
    // being called ad infinitum, when state refreshes are done
    useEffect(() => {
        fetchData(weekfilter_start, weekfilter_end);
    }, []); // includes empty dependency array here to prevent infinite loop of calls

    const handleCheckChange = (pzID) => {

        // HACK ALERT: constructing and passing the selectedZones array, to correctly read the checked values of the checkboxes
        // is done because writing them to state with useState and then reading them in updateChart(), doesn't give the state time to mutate, before
        // updateChart() is called. It SHOULD be possible to wait for the state change and only call updateChart() when the state change has taken place
        let selectedZones = [];
        for (let i = 0; i < 7; i++) { selectedZones[i] = eval('P' + (i + 1) + 'Selected'); }
        //console.log('states', selectedZones);

        switch (pzID) {
            case 'P1':
                setP1Selected(!P1Selected);
                selectedZones[0] = !P1Selected;
                break;
            case 'P2':
                setP2Selected(!P2Selected);
                selectedZones[1] = !P2Selected;
                break;
            case 'P3':
                setP3Selected(!P3Selected);
                selectedZones[2] = !P3Selected;
                break;
            case 'P4':
                setP4Selected(!P4Selected);
                selectedZones[3] = !P4Selected;
                break;
            case 'P5':
                setP5Selected(!P5Selected);
                selectedZones[4] = !P5Selected;
                break;
            case 'P6':
                setP6Selected(!P6Selected);
                selectedZones[5] = !P6Selected;
                break;
            case 'P7':
                setP7Selected(!P7Selected);
                selectedZones[6] = !P7Selected;
                break;
        }


        updateChart(rawData, selectedZones);

        return;
    }

    const fetchData = (week_start, week_end) => {
        setRawDataFetched(false);

        let url = process.env.REACT_APP_APIBASEURL + '/api/efficiencies?sort=weeknum&weekfilter_start=' + week_start + '&weekfilter_end=' + week_end;

        axios.get(url)
            .then((getResponse) => {
                let rawdata = getResponse.data;
                setRawData(rawdata);
                setRawDataFetched(true);
                let selectedZones = [];
                for (let i = 0; i < 7; i++) { selectedZones[i] = eval('P' + (i + 1) + 'Selected'); }
                updateChart(rawdata, selectedZones);

            }
            ).catch((error) => {
                console.error('ERROR retrieving efficiency data');
                console.error(error);
            });
    }

    const updateChart = (data, selectedZones) => {
        setChartDataFetched(false);
        let labels = [], valuesP1 = [], valuesP2 = [], valuesP3 = [], valuesP4 = [], valuesP5 = [], valuesP6 = [], valuesP7 = [];

        data.forEach((item) => {
            labels.push(item.weeknum);
            if (selectedZones[0]) valuesP1.push(item.P1_eff);
            if (selectedZones[1]) valuesP2.push(item.P2_eff);
            if (selectedZones[2]) valuesP3.push(item.P3_eff);
            if (selectedZones[3]) valuesP4.push(item.P4_eff);
            if (selectedZones[4]) valuesP5.push(item.P5_eff);
            if (selectedZones[5]) valuesP6.push(item.P6_eff);
            if (selectedZones[6]) valuesP7.push(item.P7_eff);
        });

        /*
        console.log('P1 selected:', P1Selected);
        console.log('P2 selected:', P2Selected);
        console.log('P3 selected:', P3Selected);
        console.log('P4 selected:', P4Selected);
        console.log('P5 selected:', P5Selected);
        console.log('P6 selected:', P6Selected);
        console.log('P7 selected:', P7Selected);
        */

        let dataSets = [];

        if (selectedZones[0]) {
            dataSets.push(
                {
                    label: 'P1',

                    data: [...valuesP1],
                    backgroundColor: [
                        'rgba(240, 137, 238, 0.2)',
                    ],
                    borderColor: [
                        'rgba(240, 137, 238, 1)',
                    ],
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(200, 200, 200, 1)',
                    hoverBorderColor: 'rgba(200, 200, 200, 1)',

                });
        }
        if (selectedZones[1]) {
            dataSets.push(
                {
                    label: 'P2',

                    data: [...valuesP2],
                    backgroundColor: [
                        'rgba(32, 92, 192, 0.2)',
                    ],
                    borderColor: [
                        'rgba(32, 92, 192, 1)',
                    ],
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(200, 200, 200, 1)',
                    hoverBorderColor: 'rgba(200, 200, 200, 1)',
                });
        }
        if (selectedZones[2]) {
            dataSets.push(
                {
                    label: 'P3',

                    data: [...valuesP3],
                    backgroundColor: [
                        'rgba(225, 22, 12, 0.2)',
                    ],
                    borderColor: [
                        'rgba(225, 22, 12, 1)',
                    ],
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(200, 200, 200, 1)',
                    hoverBorderColor: 'rgba(200, 200, 200, 1)',

                });
        }
        if (selectedZones[3]) {
            dataSets.push(
                {
                    label: 'P4',

                    data: [...valuesP4],
                    backgroundColor: [
                        'rgba(87, 184, 68, 0.2)',
                    ],
                    borderColor: [
                        'rgba(87, 184, 68, 1)',
                    ],
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(200, 200, 200, 1)',
                    hoverBorderColor: 'rgba(200, 200, 200, 1)',

                });
        }
        if (selectedZones[4]) {
            dataSets.push(
                {
                    label: 'P5',

                    data: [...valuesP5],
                    backgroundColor: [
                        'rgba(252, 202, 3, 0.2)',
                    ],
                    borderColor: [
                        'rgba(252, 202, 3, 1)',
                    ],
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(200, 200, 200, 1)',
                    hoverBorderColor: 'rgba(200, 200, 200, 1)',

                });
        }
        if (selectedZones[5]) {
            dataSets.push(
                {
                    label: 'P6',

                    data: [...valuesP6],
                    backgroundColor: [
                        'rgba(175, 132, 192, 0.2)',
                    ],
                    borderColor: [
                        'rgba(175, 132, 192, 1)',
                    ],
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(200, 200, 200, 1)',
                    hoverBorderColor: 'rgba(200, 200, 200, 1)',

                });
        }
        if (selectedZones[6]) {
            dataSets.push(
                {
                    label: 'P7',

                    data: [...valuesP7],
                    backgroundColor: [
                        'rgba(212, 115, 36, 0.2)',
                    ],
                    borderColor: [
                        'rgba(212, 115, 36, 1)',
                    ],
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(200, 200, 200, 1)',
                    hoverBorderColor: 'rgba(200, 200, 200, 1)',

                });
        }
        let chartData = {
            labels: [...labels],
            datasets: dataSets,
        };

        let chartOptions = {

            showLine: true,
            scales: {
                xAxes: {
                    display: true,
                    labelString: "Weeknum)"
                },
                yAxes: {
                    display: true,
                    labelString: "Efficiency (m/min/W)"
                }
            }
        }

        setChartData(chartData);
        setChartOptions(chartOptions);
        setChartDataFetched(true);
    }

    const buttonGroupData =
    {
        "buttontext": "Select period",
        "handler": "handleDateSelect",
        "domElementToUpdate": "headEfficiencyGraph",
        "domElementInnerHTMLvals": ["Power zone efficiency - This week", "Power zone efficiency - This month", "Power zone efficiency - This year", "Power zone efficiency - Last 3 months", "Power zone efficiency - Last 6 months", "Power zone efficiency - Last 12 months"],
        "ids": ["ddi_this_week", "ddi_this_month", "ddi_this_year", "ddi_3m", "ddi_6m", "ddi_12m"],
        "labels": ["This week", "This month", "This year", "Last 3 months", "Last 6 months", "Last 12 months"]
    };
    // Add the years for this year down to 2018 (no data earlier than 2021 in DB)
    let year = getWeekYear(new Date()) - 1;
    for (year; year > 2021; year--) {
        buttonGroupData["ids"].push('ddi_' + year);
        buttonGroupData["labels"].push(year);
        buttonGroupData["domElementInnerHTMLvals"].push('Power zone efficiency - ' + year);
    }
    buttonGroupData["ids"].push('ddi_all');
    buttonGroupData["labels"].push('All');
    buttonGroupData["domElementInnerHTMLvals"].push('Power zone efficiency - All time');

    return (
        <main>
            <div className="row mx-1">
                <div className="col-md-6 col-sm-6 p-1">
                    <h4 id="headEfficiencyGraph">Power zone efficiency - This year</h4>
                </div>
                <div className="col-md-4 col-sm-4 p-1 align-items-end">
                    {chartDataFetched ? (
                        <form>
                            <input
                                type="checkbox"
                                className=""
                                checked={P1Selected}
                                onChange={(e) => handleCheckChange('P1')}
                            />P1&nbsp;&nbsp;
                            <input
                                type="checkbox"
                                checked={P2Selected}
                                onChange={(e) => handleCheckChange('P2')}
                            />P2&nbsp;&nbsp;
                            <input
                                type="checkbox"
                                checked={P3Selected}
                                onChange={(e) => handleCheckChange('P3')}
                            />P3&nbsp;&nbsp;
                            <input
                                type="checkbox"
                                checked={P4Selected}
                                onChange={(e) => handleCheckChange('P4')}
                            />P4&nbsp;&nbsp;
                            <input
                                type="checkbox"
                                checked={P5Selected}
                                onChange={(e) => handleCheckChange('P5')}
                            />P5&nbsp;&nbsp;
                            <input
                                type="checkbox"
                                checked={P6Selected}
                                onChange={(e) => handleCheckChange('P6')}
                            />P6&nbsp;&nbsp;
                            <input
                                type="checkbox"
                                checked={P7Selected}
                                onChange={(e) => handleCheckChange('P7')}
                            />P7
                        </form>

                    ) : ('...')}
                </div>

                <div className="col-md-2 col-sm-2 p-1 align-items-end" id="buttoncol">
                    {
                        rawDataFetched ? (
                            <CustDropdownButton parentCallback={fetchData} buttonData={buttonGroupData} />
                        ) : <TextAnimate text="fetching results" />
                    }
                </div>

            </div>
            <hr />
            {
                chartDataFetched ?
                    (<div><Line data={chartData} options={chartOptions} /></div>)
                    :
                    (<div><div className="dotWrapper"><ThreeDots color="hotpink" height={40} width={40} /></div></div>)
            }
        </main >);
}

