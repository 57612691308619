import React, { Component } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
//import DropdownButton from 'react-bootstrap/Dropdown';
import { getWeekNum, getWeekYear } from '../../utils/Utils';

export default class CustDropdownButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownButtonText: "",
            buttonGroup: [],
          }
    }

    componentDidMount = () => {
        // Assumes a buttondata object is passed from the parent, 
        // containing data with which to populate the buttons
        this.renderDropdown(this.props.buttonData);
    }
    // Callback function for dropdown item select
    // Note that a callback needs to be implemented for each 
    // set of buttons rendered and the callback may need knowledge 
    // of the parent class functions
    handleDateSelect = (e) => {
        var period = e.target.id;
        var innerHTML = 'To Fill';
        // translate period to the weeknumber by which to cutoff the weeks
        var wfStart;
        var wfEnd = getWeekYear(new Date()) + getWeekNum(new Date());

        // Build dictionary with values for the wfStart week for index cases
        const wfEndVals = {};
        const wfStartVals = {
            "ddi_this_week": getWeekYear() + getWeekNum(),
            "ddi_this_month": getWeekYear(new Date(new Date().getFullYear(), new Date().getMonth(), 1)) + getWeekNum(new Date(new Date().getFullYear(), new Date().getMonth(), 1)),
            "ddi_this_year": getWeekYear(new Date()) + '01',
            "ddi_1m": this.weeknumMonthsPreviuous(1),
            "ddi_3m": this.weeknumMonthsPreviuous(3),
            "ddi_6m": this.weeknumMonthsPreviuous(6),
            "ddi_12m": this.weeknumMonthsPreviuous(12),
            "ddi_all": 200001,
        }
        // Build dictionary with values for the wfEnd week for index cases
        // Add the years for this year down to 2018 (no data earlier than 2018 in DB)
        for (let year = getWeekYear(new Date()) - 1; year > 2017; year--) {
            wfStartVals["ddi_" + year] = year + '01';
            wfEndVals["ddi_" + year] = year + '53';
        }

        wfStart = wfStartVals[period];
        if (wfEndVals[period] != null) wfEnd = wfEndVals[period];
        var i = 0; for (i = 0; i < this.props.buttonData["ids"].length; i++) { if (this.props.buttonData["ids"][i] == period) break; }
        innerHTML = this.props.buttonData["domElementInnerHTMLvals"][i];

        // Note fetchData() is called from this.Setstate as callback function on the Parent, 
        this.props.parentCallback(wfStart, wfEnd);

        // ToDo: get the id of the element to update from the function parameters
        document.getElementById(this.props.buttonData["domElementToUpdate"]).innerHTML = innerHTML;
    }

    // Function to subtract a number of months from a date and return
    // the weeknumber of the new date. If no date is passed, will take
    // today as the current date
    weeknumMonthsPreviuous = (monthsToSubtract, date = new Date()) => {
        // The subtraction counts in days, so multiply by average number of days in a month
        date.setDate(date.getDate() - Math.round(monthsToSubtract * 30.5));
        return (getWeekYear(date) + getWeekNum(date));
    }

    // Build items dropdown menu based on dictionary settings
    // Dictionary should be constructed as follows:
    // key 1: "buttontext": text to show on the main button
    // key 2: "handler": name of the handler that will handle callbacks. NOTE: THE HANDLER WILL NEED TO BE IMPLEMENTED
    // key 3: "domElementToUpdate": the id of the DOM element of which the innerHTML property needs updating after a dropdown element is selected
    // key 4: "domElementInnerHTMLvals: array of values to set the innerHTML of the updated DOM element to on update"
    // key 5: "ids": array of the ids/keys for the dropdown elements
    // key 6: "labels": array of the labels to show as text for the dropdown elements
    // The dropdown will call a handler 'handleselect' on the main class, that will handle callbacks from the dropdown buttons
    // Note: the domElementInnerHTMLvals[], ids[], and labels[] arrays must all be of equal length and the element positions in the arrays
    // must all correspond to each other, such that e.g. id[3] is the id of the third element, that must display with labels[3] and 
    // when that element is selected, domElementInnerHTMLvals[3] should be displayed as the innerHTML of the DOM element 'domElementToUpdate'
    renderDropdown = (buttonGroupData) => {
        const buttons = []       
        //NOTE: the 'key' field is needed as unique identifier for the component
        for (let i = 0; i < buttonGroupData["ids"].length; i++) {
            buttons.push(
                <Dropdown.Item onClick={eval('this.' + buttonGroupData["handler"])} id={buttonGroupData["ids"][i]} key={buttonGroupData["ids"][i]}>
                    {buttonGroupData["labels"][i]}
                </Dropdown.Item>
            )
        }
        this.setState({ dropdownButtonText: buttonGroupData["buttontext"], buttonGroup: buttons });
    }

    render = () => {
         return (
            <Dropdown size="sm">
                <Dropdown.Toggle variant="primary" className="btn btn-secondary btn-xs" size="sm">
                    {this.state.dropdownButtonText}
                </Dropdown.Toggle>
                <Dropdown.Menu id="dd_menu">
                    {this.state.buttonGroup}
                </Dropdown.Menu>
            </Dropdown>
        )
    }
}

