import React from "react";
import NavigationBar from "../../components/nav/NavigationBar";

export default function ToDo() {

    return (
        <main>
            <NavigationBar />
            <div className="pageBody">
                <div className="row m-1">
                    <div className="col-md-12 col-sm-12 p-1">
                        <div className="card p-2" id="pageheaderbar">
                            <h5 className="p1">ToDo</h5>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="row m-1">
                        <div className="col-md-12 col-sm-12 p-1">
                            <p><strong>Todo's</strong></p>
                            <p>- Data table: keep scroll position when loading new data</p>
                            <p>- More views of power/efficiency data</p>
                            <p>- ?Calendar: dynamic loading of more months, scrolling through the calendar?</p>
                            <p>- I now use the useRef() React hook, instead of document.getElementById(), to refer to the hidden input element. For why we should use useRef() instead of document.getElementById(), see Farmer (2015�2018).</p>                            
                            <p>- Refactor with new logging across all pages, frontend and backend</p>
                            
                            <p>- <s>Automated frontend testing</s></p>
                            <p>- <s>Progress bar at step 2/4 in FIT importer sometimes shows progress 3/1 and the bar overshoots to 300%</s></p>
                            <p>- <s>When new avatar image is uploaded, delete the old one</s></p>                
                            <p>- <s>Refactor async-only functions to merge with sync functions (helpers.py, garminimport.py)</s></p>
                            <p>- <s>Authentication for Redis (staging and prod). Figure out how django-rq configuration works for this.</s></p>
                            <p>- <s>Apply same changes to powerzones updater (frontend + backend) as for pacezones</s></p>
                            <p>- <s>Logging at debug level is happening on PROD, despite the loglevel being set to 'INFO'. See: https://docs.python.org/3/library/logging.html and https://docs.djangoproject.com/en/4.0/howto/logging/</s></p>
                            <p>- <s>Powerzones and Pacezones: add date picker to date input field></s></p>
                            <p>- <s>Calendar: Display multiple activities in calendar on one day</s></p>
                            <p>- <s>Calendar view with all activities</s></p>
                            <p>- <s>Upload profile pic as user ID photo</s></p>
                            <p>- <s>Pacezones: allow input of paces in MM:SS format instead of HH:MM:SS</s></p>
                            <p>- <s>BIGGY: If one user uploads an activity with an ID that already exists, that will overwrite another user's activity (or the user's own existing activity with that ID)</s></p>
                            <p>- <s>Add confirm dialog before deleting power/pace zones</s></p>
                            <p>- <s>Delete activities+laps? Or keep in admin tool</s></p>
                            <p>- <s>Modal: close icon, cross instead of bar: solution: align bootstrap and react bootstrap versions. See https://stackoverflow.com/questions/65472384/react-bootstrap-only-close-button-styling-not-working </s></p>
                            <p>- <s>Hamburger menu: add icons for mobile menu state below the Hamburger</s></p>
                            <p>- <s>Solve for JS errors when using modal window</s></p>
                            <p>- <s>Solve for null reference errors on powerzone and pacezone table views</s></p>
                            <p>- <s>Build out TableRowExpandedRow.js to render all activities in a table as totals rows</s></p>
                            <p>- <s>Collapse expanded row on column sort</s></p>
                            <p>- <s>Load FIT data from February 2020 to October 2020</s></p>
                            <p>- <s>Improve the 'solarized' theme of the tables: better visual linkage between header row and exapanded rows</s></p>
                            <p>- <s>Make activties a link in the Datatable and Expanded Row</s></p>
                            <p>- <s>Detail view for an activity+laps</s></p>
                            <p>- <s>Edit activities/laps</s></p>
                            <p>- <s>Javascript error: Invalid scale for chartjs</s></p>
                            <p>- <s>Javascript Warning: Functions are not valid as a React child. This may happen if you ...</s></p>
                            <p>- <s>Javascript Warning: findDOMNode is deprecated in StrictMode..</s></p>
                            <p>- <s>Remove hardcoded paths from Login, Signup, Logout auth views and refactor</s></p>
                            <p>- <s>Replace api2/colheaders by api/colheaders</s></p>
                            <p>- <s>Restyle File upload button: https://medium.com/web-dev-survey-from-kyoto/how-to-customize-the-file-upload-button-in-react-b3866a5973d8</s></p>
                            <p>- <s><strong>SECURITY</strong>: Upload directory and sqllite DB dirs no read/write 'Anyone' to allow file upload and DB writing. Create apache user with correct permissions.</s></p>
                        </div>
                    </div>

                    <div className="row m-1">
                        <div className="col-md-12 col-sm-12 mx-auto p-1">
                            <div className="card p-2">
                                <img src="20220123_120836.jpg" alt="Original sketch for the app" title="Original sketch for the app" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </main>
    );
}


