import React, { Component } from "react";
import PowerZonesTable from "../../components/dataviews/PowerZonesTable";
import NavigationBar from "../../components/nav/NavigationBar";

export default class PowerZones extends Component {

    componentDidMount() {
    }

    render() {

        return (
            <main>
                <NavigationBar />
                <div className="pageBody">
                <div className="row m-1">
                    <div className="col-md-12 col-sm-12 p-1">
                        <div className="card p-2" id="pageheaderbar">
                            <h5 className="p1">Power Zones</h5>
                        </div>
                    </div>
                </div>

                <div className="row m-1">
                        <div className="col-md-12 col-sm-12 p-1">
                            <h4>Power zone setup: create an entry below, whenever your zone values change</h4>
                            <hr />
                            Zone settings:
                        </div>
                </div>


                <div className="row m-1">
                    <div className="col-md-3 col-sm-3 p-0">
                            <ul id="zonelist">
                                <li>P1: Easy power (recovery)</li>
                                <li>P2: Moderate power (marathon)</li>
                                <li>P3: Threshold power (10k/hm)</li>
                            <li>P4: Interval power (5k to 1500m)</li>
                        </ul>
                    </div>

                    <div className="col-md-9 col-sm-9 p-0">
                        <ul id="zonelist">
                                <li>P5: Repetition power (1500m and faster)</li>
                                <li>P6 - optional: 400m power</li>
                                <li>P7 - optional: 100m power</li>
                            </ul>
                    </div>
                </div>

                <div className="row m-1">
                        <div className="col-md-12 col-sm-12 p-1">
                            <PowerZonesTable />
                        </div>
                    </div>
                    </div>
            </main>
        );
    }
}

