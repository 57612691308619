import React, { useState, useEffect } from 'react';
import { Input, FormGroup } from 'reactstrap';
import axios from "axios";
import ReactiveButton from 'reactive-button'; // See: https://arifszn.com/reactive-button/docs/usage

export default function Fileupload(props) {
    const [fileName, setFileName] = useState('');
    const [file, setFile] = useState(null);
    const [invalidFile, setInvalidFile] = useState(false);
    const [processing, setProcesing] = useState(false);
    const [buttonEnabled, setButtonEnabled] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [successText, setSuccessText] = useState('');
    const [maxSize, setMaxSize] = useState(null);
    const [fileTypeFilters, setFileTypeFilters] = useState(null);

    // NOTE the empty dependency array [] at the end of useEffect. This will prevent useEffect from
    // being called ad infinitum, when state refreshes are done
    useEffect(() => {
        if (props.type === 'avatar') {
            setMaxSize(process.env.REACT_APP_MAX_FILESIZE_AVATAR_UPLOAD);
            setFileTypeFilters(".jpg,.jpeg,.jfif,.png");
        }
        else if (props.type === 'fit') {
            setMaxSize(process.env.REACT_APP_MAX_FILESIZE_FIT_UPLOAD);
            setFileTypeFilters(".fit,.FIT");
        }

    }, []); // includes empty dependency array here to prevent infinite loop of calls

    const handleFileChange = ({ target: { files } }) => {
        setSuccessText('');
        setErrorText('');
        document.getElementById('uploadErrorTextFupl').innerHTML = '';
        document.getElementById('uploadSuccessTextFupl').innerHTML = '';

        if (!files.length) return;

        const [{ size, name }] = files;
        var err = '';

        // Test the file is not too large
        if (size > maxSize) {
            err = 'File too large. Max size=' + maxSize / 1000 + 'kB';
        }
 
        var regexPattern = null;
        var errTextWrongFileFormat = '';
        if (props.type === 'avatar') {
            regexPattern = /((\b(\.jpg)|(\b(\.jpeg))|(\b(\.png))|(\b(\.jfif)))\b$(?<!\.|_)){1}|(^(?!(?:[^\.]*\.){2}))/ig;
            errTextWrongFileFormat = 'Please choose a image file of type .jpg, .jpeg, .jfif or .png.';
        }
        if (props.type === 'fit') {
            regexPattern = /(\b(\.fit)\b$(?<!\.|_)){1}|(^(?!(?:[^\.]*\.){2}))/ig;
            errTextWrongFileFormat = 'Please choose a valid .FIT or .fit file.';
        }

        // Test that the file has the correct extension and has only one single '.' in it
        // See https://regex101.com/ for explanation of the regex
        let matches = name.match(regexPattern);
        if (matches == null || matches.length !== 2) {
            err = errTextWrongFileFormat;
        }

        // Test that the uploaded file has a filename consisting of URL safe (alphanumeric) chars, no spaces.
        // NOTE: hyphens (-), underscores (_) and full stops (.) SHOULD be allowed as these are all part of the
        // standard Garmin FIT file naming, which is xxxxxxxxxx_ACTIVITY.FIT where xxxxxxxxxx is the activity ID
        // See https://regex101.com/ for explanation of the regex
        if (name.search(/^(?!\.|_)[\w.-]*$(?<!\.|_)/gi) < 0) {
            err = 'Please use a file name containing only alphanumeric characters. Dashes (-), underscores (_) and dots (.) are also ok. \nHowever, no leading \'-\', \'.\' or \'_\' or special characters (e.g. spaces, (semi)colons, comma\'s, apostrophes, pound signs, dollar signs etc.) elsewhere in the filename can be processed.';
        }

        if (err !== '') {
            setInvalidFile(true);
            setErrorText(err);
            setProcesing(false);
            setButtonEnabled(false);
            return;
        }

        // No errors, all checks passed, process the file
        setErrorText('');
        setFileName(name);
        setInvalidFile(false);
        setButtonEnabled(true);
        setFile(files[0]);
    }

    const doUploadPost = () => {
        setSuccessText('');
        setErrorText('');

        if (file == null) return;

        const formData = new FormData();
        
        formData.append(process.env.REACT_APP_UPLOADFILE_FIELDNAME, file);
        // Append field specifying the filetype being uploaded
        formData.append('uploadfiletype', props.type);

        const url = process.env.REACT_APP_UPLOADURL;
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            }
        }

        // Call the API to upload and then process the uploaded file
        axios.post(url, formData, config)
            .then((response) => {
                if (props.type === 'avatar') {
                    doProcessGet('/api/userprofiles',props.type);
                }
                else if (props.type === 'fit') {
                    doProcessGet(process.env.REACT_APP_PROCESSURL + '?forcesynchronous=True',props.type);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const doProcessGet = (url, uploadType) => {
        console.log(url);
        axios.get(url)
            .then((getResponse) => {
                console.log(getResponse);
                if (uploadType === 'avatar') {

                    setProcesing(false);

                    let userProfileData = getResponse.data[0];
                    // Load the new avatar for the user into localstorage
                    let user = JSON.parse(localStorage.getItem("user"));
                    user["avatar"] = userProfileData.avatar
                    localStorage.setItem("user", JSON.stringify(user));

                    let successHTML = 'Succesfully uploaded profile picture.'
                    setSuccessText(successHTML);
                    document.getElementById('uploadSuccessTextFupl').innerHTML = successHTML;

                }
                else if (uploadType === 'fit') {

                    setProcesing(false);

                    //console.log("Uploaded and processed file(s). Response:");
                    // Check if the upload was successful, or whether the file alrerady existed
                    //console.log(getResponse.data);
                    let actId = Object.keys(getResponse.data)[0];
                    let result = getResponse.data[actId];
                    if (result.indexOf('ABORTED') > -1) {
                        let errorHTML = 'This activity aleady exists. Please <a href="/activity/' + actId + '">edit it</a> or delete it and re-upload.';
                        document.getElementById('uploadErrorTextFupl').innerHTML = errorHTML;
                    }
                    if (result.indexOf('IMPORTERROR') > -1) {
                        setErrorText(result.split('.')[1]);
                    }

                    if (result.indexOf('CREATED') > -1) {
                        let successHTML = 'Successfully uploaded activity <a href="/activity/' + actId + '">' + actId + '</a>. ' + result.split('.')[2] + ' counted.'
                        setSuccessText(successHTML);
                        document.getElementById('uploadSuccessTextFupl').innerHTML = successHTML;
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const buttonClick = (action, event) => {
        if (file != null) {
            if (action === 'upload') {
                setProcesing(true);
                doUploadPost();
            }
        }
    }

    return (
        <main className="container">
            <br />
            <div>
                <FormGroup>
                    <Input
                        style={{ 'height': '45px', 'color': 'black', 'paddingTop': '10px', 'paddingLeft': '15px', }}
                        type="file"
                        accept={fileTypeFilters}
                        id="uploadFileBrowser"
                        name="customFile"
                        label={fileName || 'choose pic file'}
                        onChange={handleFileChange}
                        invalid={invalidFile} />
                </FormGroup>
                <div id="uploadErrorTextFupl" className="uploadErrorText">{errorText}</div>
                <div id="uploadSuccessTextFupl" className="uploadSuccessText">{successText}</div>
            </div>
           
            <br/>
            <div>
                <ReactiveButton
                    id="button_upload"
                    buttonState={processing ? 'loading' : 'idle'}
                    disabled={!buttonEnabled}
                    errorText={errorText}
                    idleText={'Upload file'}
                    loadingText={'Uploading...'}
                    successText={'Done!'}
                    messageDuration={3000}
                    color="blue"
                    style={{ borderRadius: '5px' }}
                    animation={true}
                    size="large"
                    onClick={(e) => buttonClick('upload', e)}
                />
            </div>
            <br />
        </main>
    );
}



        
    
