import { push } from "connected-react-router";
import { toast } from "react-toastify";
import { SET_TOKEN, SET_CURRENT_USER, UNSET_CURRENT_USER } from "./LoginTypes";
import axios from "axios";
import { setAxiosAuthToken, toastOnError } from "../../utils/Utils";

/* Todo: But before, please check how the view will behave in the case of wrong credentials. 
 You should see errors displayed with error (red) toast.
 ToDo: make sure the error is displayed to the user, rather than via toast
 ToDo: understand the dispatch logic and remove the logout2 and unsetcurrentuser2 functions
       using the original logout and unsetcurrentuser functions again   
 */
export const login = (userData, redirectTo) => dispatch => {
    
    axios
        .post("/api/token/login/", userData)
        .then(response => {
            const { auth_token } = response.data;
            setAxiosAuthToken(auth_token);
            dispatch(setToken(auth_token));
            dispatch(getCurrentUser(redirectTo));
        })
        .catch(error => {
            dispatch(unsetCurrentUser());
            toastOnError(error);
        });
};

export const getCurrentUser = redirectTo => dispatch => {
    
    axios
        .get("/api/userprofiles/")
        .then(response => {
            let userProfileData = response.data[0];
            const user = {};
            user['username'] = userProfileData.username;
            user['email'] = userProfileData.email;
            user['avatar'] = userProfileData.avatar;
            user['firstname'] = userProfileData.firstname;
            user['lastname'] = userProfileData.lastname;
            user['lastlogin'] = userProfileData.lastlogin;
            user['upid'] = userProfileData.id;
            dispatch(setCurrentUser(user, redirectTo));
        })
        .catch(error => {
            console.error('could not get user', error);
            dispatch(unsetCurrentUser());
            toastOnError(error);
        });
};



export const setCurrentUser = (user, redirectTo) => dispatch => {
    localStorage.setItem("user", JSON.stringify(user));
    dispatch({
        type: SET_CURRENT_USER,
        payload: user
    });
    if (redirectTo !== "") {
        dispatch(push(redirectTo));
    }
};

export const setToken = token => dispatch => {
    setAxiosAuthToken(token);
    localStorage.setItem("token", token);
    dispatch({
        type: SET_TOKEN,
        payload: token
    });
};

export const unsetCurrentUser = () => dispatch => {
    setAxiosAuthToken("");
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    dispatch({
        type: UNSET_CURRENT_USER
    });
};


export const logout = () => dispatch => {
    axios
        .post("/api/token/logout/")
        .then(response => {
            dispatch(unsetCurrentUser());
            dispatch(push("/"));
            toast.success("Logout successful");
        })
        .catch(error => {
            dispatch(unsetCurrentUser());
            toastOnError(error);
        });
};


export const logout2 = () => {
    axios
        .post("/api/token/logout/")
        .then(response => {
            unsetCurrentUser2();
            // Redirect to root
            window.location.replace("/");
            //toast.success("Logout successful");
        })
        .catch(error => {
            unsetCurrentUser();
            toastOnError(error);
        });
};

export const unsetCurrentUser2 = () => {
    setAxiosAuthToken("");
    localStorage.removeItem("token");
    localStorage.removeItem("user");
};
