import React  from "react";
import Modal from 'react-bootstrap/Modal'
import Fileupload from "../../components/utils/Fileupload";

export default function ModalAvatarUpload(props) {

    return (
        <Modal
            show='true'
            onHide={() => props.callback(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Upload Profile Picture
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Fileupload type="avatar" />
                </div>
            </Modal.Body>
        </Modal>
    );
}
