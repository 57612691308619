import React, { Component } from 'react';
import axios from "axios";
import { Bar } from "react-chartjs-2";
import { ThreeDots } from 'react-loader-spinner'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getWeekYear, stackedBarFormatter } from '../../utils/Utils';
import TextAnimate from '../../utils/TextAnimate';
import CustDropdownButton from './CustDropdownButton ';

export default class WeekTotalKms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            weeknum: this.props.weeknum,
            weekfilter_start: this.props.weekfilter_start,
            weekfilter_end: this.props.weekfilter_end,
            dataFetched: false,
            chartData: {},
            chartOptions: {},
            buttonGroup: {},
        }

    }

    componentDidMount = () => {
        this.fetchData(this.props.weekfilter_start, this.props.weekfilter_end);
    }

    fetchData = (week_start, week_end) => {

        this.setState({ dataFetched: false, });

        let url = process.env.REACT_APP_APIBASEURL + '/api/totals/?sort=weeknum&weekfilter_start=' + week_start
        url += '&weekfilter_end=' + week_end;
        axios.get(url)
            .then((getResponse) => {
                let data = getResponse.data;
                let labels = [];
                let valuesEasy = [];
                let valuesModerate = [];
                let valuesHard = [];

                data.forEach((item) => {
                    labels.push(item.weeknum);
                    valuesEasy.push(item.kmsEasy);
                    valuesModerate.push(item.kmsModerate);
                    valuesHard.push(item.kmsHard);

                });

                let chartdata = {
                    labels: [...labels],
                    datasets: [
                        {
                            label: 'Easy',
                            stack: 'stack1',
                            data: [...valuesEasy],
                            backgroundColor: [
                                'rgba(75, 192, 192, 0.2)',
                            ],
                            borderColor: [
                                'rgba(75, 192, 192, 1)',
                            ],
                            borderWidth: 1,
                            hoverBackgroundColor: 'rgba(200, 200, 200, 1)',
                            hoverBorderColor: 'rgba(200, 200, 200, 1)',

                        },
                        {
                            label: 'Moderate',
                            stack: 'stack1',
                            data: [...valuesModerate],
                            backgroundColor: [
                                'rgba(153, 102, 255, 0.2)',
                            ],
                            borderColor: [
                                'rgba(153, 102, 255, 1)',
                            ],
                            borderWidth: 1,
                            hoverBackgroundColor: 'rgba(200, 200, 200, 1)',
                            hoverBorderColor: 'rgba(200, 200, 200, 1)',

                        },
                        {
                            label: 'Hard',
                            stack: 'stack1',
                            data: [...valuesHard],
                            backgroundColor: [
                                'rgba(255, 159, 64, 0.2)'
                            ],
                            borderColor: [
                                'rgba(255, 159, 64, 1)'
                            ],
                            borderWidth: 1,
                            hoverBackgroundColor: 'rgba(200, 200, 200, 1)',
                            hoverBorderColor: 'rgba(200, 200, 200, 1)',

                        }
                    ]
                };

                let chartOptions = {
                    plugins: {
                        datalabels: {
                            display: true,
                            color: "grey",
                            formatter: stackedBarFormatter,
                            render: "percentage",
                            anchor: "end",
                            offset: 5,
                            align: "start"
                        }
                    },
                    legend: {
                        display: false
                    }
                };

                this.setState({ dataFetched: true, chartData: chartdata, chartOptions: chartOptions });
            }).catch((error) => {
                console.error('ERROR retrieving weektotals data');
                console.error(error);
            });

    }

    render() {

        const buttonGroupData =
        {
            "buttontext": "Select period",
            "handler": "handleDateSelect",
            "domElementToUpdate": "headTotalKms",
            "domElementInnerHTMLvals": ["Weekly total kms  - This week", "Weekly total kms  - This month", "Weekly total kms  - This year", "Weekly total kms  - Last 3 months", "Weekly total kms  - Last 6 months", "Weekly total kms  - Last 12 months"],
            "ids": ["ddi_this_week", "ddi_this_month", "ddi_this_year", "ddi_3m", "ddi_6m", "ddi_12m"],
            "labels": ["This week", "This month", "This year", "Last 3 months", "Last 6 months", "Last 12 months"]
        };
        // Add the years for this year down to 2018 (no data earlier than 2018 in DB)
        let year = getWeekYear(new Date()) - 1;
        for (year; year > 2017; year--) {
            buttonGroupData["ids"].push('ddi_' + year);
            buttonGroupData["labels"].push(year);
            buttonGroupData["domElementInnerHTMLvals"].push('Weekly total kms  - ' + year);
        }
        buttonGroupData["ids"].push('ddi_all');
        buttonGroupData["labels"].push('All');
        buttonGroupData["domElementInnerHTMLvals"].push('Weekly total kms  - All time');

         if (this.state.dataFetched) {
            return (
                <main>
                    <div className="row mx-1">
                        <div className=" col-md-10 col-sm-10 p-1">
                            <h4 id="headTotalKms">Weekly total kms - This year</h4>
                        </div>
                        <div className="col-md-2 col-sm-2 p-1" id="buttoncol">
                            <CustDropdownButton id="customDropDownButtonWeeklyTotalKms" parentCallback={this.fetchData} buttonData={buttonGroupData} />
                        </div>
                    </div>
                    <hr/>
                    <div><Bar data={this.state.chartData} plugins={[ChartDataLabels]} options={this.state.chartOptions}/></div>
                 </main>);
        }
        else {
             return (
                 <main>
                     <div className="row mx-1">
                         <div className=" col-md-10 col-sm-2 p-1">
                             <h4 id="headTotalKms">Weekly total kms - This year</h4>
                         </div>
                         <div className="col-md-2 col-sm-2 p-1" id="buttoncol">
                             <TextAnimate text="fetching results" />
                         </div>
                     </div>
                     <hr />
                     <div style={{ 'minHeight': '800px' }}><div className="dotWrapper"><ThreeDots color="hotpink" height={40} width={40} /></div></div>
                 </main >
             );
         }
    }
    
}