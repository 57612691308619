import React, { Component } from 'react';
import axios from "axios";
import { Line } from "react-chartjs-2";
import { Scatter } from "react-chartjs-2";
import { ThreeDots } from 'react-loader-spinner'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getWeekYear, stackedBarFormatter } from '../../utils/Utils';
import TextAnimate from '../../utils/TextAnimate';
import CustDropdownButton from './CustDropdownButton ';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';


export default class PowerVsSpeed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            weekfilter_start: this.props.weekfilter_start,
            weekfilter_end: this.props.weekfilter_end,
            dataFetched: false,
            chartData: {},
            chartOptions: {},
        }
    }

    componentDidMount = () => {
        this.fetchData(this.props.weekfilter_start, this.props.weekfilter_end);
    }

    // Not needed, function to sort JSON array
    sort_by_key = (array, key) => {
        return array.sort(function (a, b) {
            var x = a[key]; var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }   

    fetchData = (week_start, week_end) => {
        this.setState({ dataFetched: false, });

        let url = process.env.REACT_APP_APIBASEURL + '/api/laps/?power_from=200&sort=speed&weekfilter_start=' + week_start
        url += '&weekfilter_end=' + week_end;
        axios.get(url)
            .then((getResponse) => {
                let data = getResponse.data;
                let labels = [];
                let values = [];

                data.forEach((item) => {
                    labels.push(item.power);
                    values.push(item.speed);

                });

                let chartdata = {
                    labels: [...values],
                    datasets: [
                        {
                            label: 'Power vs Speed',

                            data: [...labels],
                            backgroundColor: [
                                'rgba(75, 192, 192, 0.2)',
                            ],
                            borderColor: [
                                'rgba(75, 192, 192, 1)',
                            ],
                            borderWidth: 1,
                            hoverBackgroundColor: 'rgba(200, 200, 200, 1)',
                            hoverBorderColor: 'rgba(200, 200, 200, 1)',

                        },
                    ]
                };

                let chartOptions = {

                    showLine: false,
                    scales: {
                        xAxes: {
                            display: true,
                            labelString: "Speed (m/s)"
                        },
                        yAxes: {
                            display: true,
                            labelString: "Power (W)"
                        }
                    }
                }
                this.setState({ dataFetched: true, chartData: chartdata, chartOptions: chartOptions });
            }).catch((error) => {
                console.error('ERROR retrieving power/speed data');
                console.error(error);
            });
    }

    render = () => {
        const buttonGroupData =
        {
            "buttontext": "Select period",
            "handler": "handleDateSelect",
            "domElementToUpdate": "headPowerVsSpeed",
            "domElementInnerHTMLvals": ["Power vs Speed - This week", "Power vs Speed - This month", "Power vs Speed - This year", "Power vs Speed - Last 3 months", "Power vs Speed - Last 6 months", "Power vs Speed - Last 12 months"],
            "ids": ["ddi_this_week", "ddi_this_month", "ddi_this_year", "ddi_3m", "ddi_6m", "ddi_12m"],
            "labels": ["This week", "This month", "This year", "Last 3 months", "Last 6 months", "Last 12 months"]
        };
        // Add the years for this year down to 2018 (no data earlier than 2018 in DB)
        let year = getWeekYear(new Date()) - 1;
        for (year; year > 2017; year--) {
            buttonGroupData["ids"].push('ddi_' + year);
            buttonGroupData["labels"].push(year);
            buttonGroupData["domElementInnerHTMLvals"].push('Power vs Speed - ' + year);
        }
        buttonGroupData["ids"].push('ddi_all');
        buttonGroupData["labels"].push('All');
        buttonGroupData["domElementInnerHTMLvals"].push('Power vs Speed - All time');

        if (this.state.dataFetched) {
            return (
                <main>
                    <div className="row mx-1">
                        <div className="col-md-8 col-sm-8 p-1">
                            <h4 id="headPowerVsSpeed">Power vs speed - This week</h4>
                        </div>
                        <div className="col-md-4 col-sm-4 p-1 align-items-end" id="buttoncol">
                            <CustDropdownButton parentCallback={this.fetchData} buttonData={buttonGroupData} />
                        </div>
                    </div>
                    <hr />
                    <div><Line data={this.state.chartData} options={this.state.chartOptions} /></div>
                </main>);
        }
        else {
            return (
                <main>
                    <div className="row mx-1">
                        <div className=" col-md-8 col-sm-8 p-1">
                            <h4 id="headPowerVsSpeed">Power vs speed - This week</h4>
                        </div>
                        <div className="col-md-4 col-sm-4 p-1" id="buttoncol">
                            <TextAnimate text="fetching results"/>
                        </div>
                    </div>
                    <hr />
                    <div style={{ 'minHeight': '350px' }}><div className="dotWrapper"><ThreeDots color="hotpink" height={40} width={40} /></div></div>
                    </main>
            );
        }
    }

}