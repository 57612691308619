import React, { useState, useEffect } from "react";
import axios from "axios";
import '../../css/activitycalendar.css';
import { capitalizeFirst, addDays, getFirstMonday, getWeekYear, getWeekNum, dateAsYYYYMMDD, textEllipsis } from '../../utils/Utils.js';
import useBreakpoints from "../../utils/useBreakpoints";
import { ThreeDots } from 'react-loader-spinner'

export default function ActivityCalendar(props) {
    const [dataFetched, setfetched] = useState(false);
    const [colHeaders, setColHeaders] = useState(null);
    const [data, setData] = useState(null);
    const [totalsData, setTotalsData] = useState(null);
    const [calendarMonthToView, setCalendarMonthToView] = useState((new Date().getFullYear().toString()) + (new Date()).getMonth().toString());
    const [weeksInMonth, setWeeksInMonth] = useState(null);

    // Helper function to make use of breakpoints and set the contents of the responsive menu based on those
    const point = useBreakpoints();
    const shortMonthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    useEffect(() => {
        let headers = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'Week\'s Totals',];
        setColHeaders(headers);
        //let seedDate = new Date();

        // If localstorage contains a selected month, save it into the state
        let actCalMonth = localStorage.getItem('activityCalendarMonth');
        if (actCalMonth != null) {
            setCalendarMonthToView(actCalMonth);
        }
        else {
            actCalMonth = (new Date()).getFullYear().toString() + (new Date()).getMonth().toString();
        }
        let seedDate = new Date(actCalMonth.substring(0, 4), actCalMonth.substring(4), 1);

        let startDate = new Date(seedDate);
        // Open the window to retrieve activities/totals to 7 days prior start of the month
        startDate.setDate(startDate.getDate() - 7);
        let weekStart = getWeekYear(startDate).toString() + getWeekNum(startDate).toString();

        let endDate = new Date(seedDate);
        // Open the window to retrieve activities/totals to 7 days post end of the month
        endDate.setDate(endDate.getDate() + 40);
        let weekEnd = getWeekYear(endDate).toString() + getWeekNum(endDate).toString();

        //Set the first day of the month to the first Monday, to setup the calendar
        let firstDay = getFirstMonday(new Date(seedDate.getFullYear(), seedDate.getMonth(), 1));
        loadCalendar(weekStart, weekEnd, firstDay, actCalMonth);

    }, []); // includes empty dependency array here to prevent infinite loop of calls

    const loadCalendar = (weekStart, weekEnd, startDay, calMonthSelected, fromPicker = false) => {

        // Get the activity data
        fetchData(weekStart, weekEnd, calMonthSelected, fromPicker);

        // Build the calendar
        setupCalendar(startDay);

    }

    const dateSelectorClicked = (newMonth) => {
        setCalendarMonthToView(newMonth);
    }

    const fillMonthDropdown = (oldestDate) => {
        document.querySelector('#weekSelector').options.length = 0;

        document.querySelector('#calendarHorizontalScrollMenu').innerHTML = "";

        // Set the startdate to the FIRST day of the startdate passed
        let startDate = new Date(oldestDate.getFullYear(), oldestDate.getMonth(), 1);
        // Set the enddate to the LAST day of this month
        let dt = new Date();
        let endDate = new Date(dt.getFullYear(), dt.getMonth() + 1, 0);

        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        // Hack needed here to prevent that doing a date.getMonth() -1 on 31-March, will still be a date in March
        let yearMonthToWrite = null;
        let yearMonthWritten = null;
        while (endDate > startDate) {
            yearMonthToWrite = getWeekYear(endDate).toString() + endDate.getMonth().toString();
            if (yearMonthToWrite != yearMonthWritten) {
                let txt = monthNames[endDate.getMonth()] + ' ' + endDate.getFullYear().toString();
                let val = endDate.getFullYear().toString() + endDate.getMonth().toString();
                let el = new Option(txt, val);
                document.querySelector('#weekSelector').add(el);
                yearMonthWritten = yearMonthToWrite;
            }
            endDate.setMonth(endDate.getMonth() - 1);
        }
    }

    const fillMonthPicker = (oldestDate, calMonthSelected, fromPicker = false) => {
        document.querySelector('#calendarHorizontalScrollMenu').innerHTML = "";
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        // Set the startdate to the FIRST day of the startdate passed
        let startDate = new Date(oldestDate.getFullYear(), oldestDate.getMonth(), 1);
        // Set the enddate to the LAST day of this month
        let dt = new Date();
        let endDate = new Date(dt.getFullYear(), dt.getMonth() + 1, 0);

        let lengthCounter = 0;
        let hit = false;

        let elToFocus = null;

        while (startDate <= endDate) {
            let txt = monthNames[startDate.getMonth()] + ' ' + startDate.getFullYear().toString();
            let val = startDate.getFullYear().toString() + startDate.getMonth().toString();
            let itemChild = document.createElement("span");
            itemChild.id = "monthbutton_" + val;
            itemChild.innerHTML = " " + txt + " ";
            itemChild.onclick = function () { handleMonthChange(val, true); };

            if (val === calMonthSelected) {
                itemChild.className = "calendarHorizontalScrollMenuItem calendarHorizontalScrollMenuItemSelected";
                document.getElementById(props.callbackelement).innerHTML = "Activity Overview - " + txt;
                hit = true;
                elToFocus = itemChild.id;
            }
            else {
                itemChild.className = "calendarHorizontalScrollMenuItem";
            };
            document.querySelector('#calendarHorizontalScrollMenu').appendChild(itemChild);
            itemChild = document.createElement("span");
            itemChild.innerHTML = "&nbsp;";
            document.querySelector('#calendarHorizontalScrollMenu').appendChild(itemChild);

            // Increment the lengthCounter. This variable acts as a proxy to count
            // the number of pixels required to scroll the right month into view
            if (!hit) lengthCounter += txt.length;

            startDate.setMonth(startDate.getMonth() + 1);
        }

        // Hack: add on another 10px wide span, to prevent the last item from being slightly hidden under the edge of the frame
        // as the scroll doesn't go right to the end
        let itemChild = document.createElement("span");
        itemChild.innerHTML = "&nbsp;&nbsp;&nbsp;";
        document.querySelector('#calendarHorizontalScrollMenu').appendChild(itemChild);

        // Set the scroll position of the month picker to make sure the selected date is visible
        if (!fromPicker) {
            // Element to the extreme right of the srollbar
            document.getElementById(elToFocus).scrollIntoView(true);
            // Then scroll it back to the center of the display
            let l = document.querySelector('#calendarHorizontalScrollMenu').scrollLeft;
            // if the element was scrolled, then l will have a >0 value. In that case center the element
            let w = document.querySelector('#calendarHorizontalScrollMenu').offsetWidth;
            if (l > 0) document.querySelector('#calendarHorizontalScrollMenu').scrollLeft += w / 2;
        }
    }

    const setupCalendar = (firstDay) => {

        // Set the number of weeks to display
        // ToDo: determine number of weeks to display, based on whether 5 or 6 rows are needed to get a full month calendar 
        var numWeeksToShow = 6;
        
        // Populate array with dates of each day in the the month.
        var monthDates = [];
        let monthChanges = 0;
        let monthShowing = firstDay.getMonth();
        // Let the counter run to 42 days (6 weeks) to populate the calendar tiles
        for (let i = 0; i < 42; i++) {
            let dayDate = addDays(firstDay, i);
            monthDates[i] = dayDate;
            // Keep track of the number of month changes. If a month change occurs twice before the last row has 
            // been drawn, that indicates the calendar has sufficient rows to show the full month
            if (dayDate.getMonth() !=  monthShowing) {
                monthShowing = dayDate.getMonth();
                monthChanges++;
            }

            // Switching to 5 week-rows instead of 6 week-rows:
            // (1) If the first day of the month coincides with the first day of the calendar, the monthchange will occur after week 1
            if (monthChanges == 0 && i>7) {
                numWeeksToShow = 5;   
            }
            // (2) If the first day of the month coincides with the first day of the calendar, the month is February and not a leapyear 
            if (monthChanges == 1 && i==28 && monthShowing ==1) {
                numWeeksToShow = 4;
            }
            // (3) If the month was already switched and switches again in row 5            
            if (monthChanges == 2 && (i>27 && i<35)) {
                numWeeksToShow = 5;
            }
        }

        let monthWeeks = [];
        for (let j = 0; j < numWeeksToShow; j++) {

            // Get the weeknumber. This is stored in the first element of the dates array for each week
            let dayDate = new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate() + (j * 7));
            let weekNumber = getWeekYear(dayDate).toString() + getWeekNum(dayDate).toString();

            var weekDates = {};
            weekDates['weeknum'] = weekNumber;
            let counter = 0;
            for (let i = j * 7; i < (j + 1) * 7; i++) {
                let dt = monthDates[i];
                weekDates['day' + (counter + 1).toString()] = dt;
                counter++;
            }
            monthWeeks[j] = weekDates;
        }
        setWeeksInMonth(monthWeeks);
    }

    const fetchData = (weekStart, weekEnd, calMonthSelected, fromPicker) => {
        setfetched(false);
        let url = process.env.REACT_APP_APIBASEURL + '/api/activities';
        url += '?weekfilter_start=' + weekStart
        url += '&weekfilter_end=' + weekEnd;

        let totalsUrl = process.env.REACT_APP_APIBASEURL + '/api/totals';
        totalsUrl += '?weekfilter_start=' + weekStart
        totalsUrl += '&weekfilter_end=' + weekEnd;

        axios.get(url)
            .then((getResponse) => {
                setData(getResponse.data);
            })
            .then(
                axios.get(process.env.REACT_APP_APIBASEURL + '/api/activity/oldest')
                    .then((getResponse) => {
                        let oldestDate = new Date(getResponse.data[0]['date']);

                        // Populate the month selector
                        fillMonthDropdown(oldestDate);

                        // Populate the horizontal month picker 
                        fillMonthPicker(oldestDate, calMonthSelected, fromPicker);
                    })
            )
            .then(
                axios.get(totalsUrl)
                    .then((getResponse) => {
                        setTotalsData(getResponse.data);
                        setfetched(true);
                    })
            )
            .catch((error) => {
                console.error('ERROR retrieving data');
                console.error(error);
            });
    }

    const handleChange = () => {
        let selectedVal = document.querySelector('#weekSelector').value;
        handleMonthChange(selectedVal);
        return;
    }

    const handleMonthChange = (newMonth, fromPicker = false) => {
        setCalendarMonthToView(newMonth);
        localStorage.setItem('activityCalendarMonth', newMonth);

        let firstMonday = getFirstMonday(new Date(newMonth.substring(0, 4), newMonth.substring(4), 1));

        let seedDate = new Date(firstMonday);

        // Start date to fetch activities for: 10 days prior to start of month
        seedDate.setDate(seedDate.getDate() - 10);
        let weekStart = getWeekYear(seedDate).toString() + getWeekNum(seedDate).toString();

        // End date to fetch activities for: 20 days beyond end of month
        // As seedDate was set to -10, this will be +50
        seedDate.setDate(seedDate.getDate() + 50);

        let weekEnd = getWeekYear(seedDate).toString() + getWeekNum(seedDate).toString();

        loadCalendar(weekStart, weekEnd, firstMonday, newMonth, fromPicker);
    }

    const renderTableHeader = () => {
        return colHeaders.map((key, index) => {
            if (index === 7) {
                return <th className="totalsHeader" key={index + key}>{capitalizeFirst(key)}</th>
            }
            else {
                return <th key={index + key}>{capitalizeFirst(key)}</th>
            }
        })
    }

    const directToTotals = (weekNum) => {
        window.location = '/dashboard?weeknum=' + weekNum;
    }

    const getTotalsByWeeknum = (weeknum, tData) => {
        for (let i = 0; i < tData.length; i++) {
            let keyHit = Object.keys(tData[i]).find(key => tData[i][key] === parseInt(weeknum));
            if (keyHit != null) {
                if (point != 'sm' && point != 'xs') {
                    return (
                        <div className="totalsBox">
                            <div className="totalsTotalKms"><span className="totalKmsValue">{tData[i]['display_kmstotal']}</span><div className="totalsLabel">distance</div></div>
                            <div className="totalsShareEasy"><span className="shareLabel">Easy kms: </span><span className="shareEasyValue">{tData[i]['display_shareeasy']}</span></div>
                            <div className="totalsShareModerate"><span className="shareLabel">Moderate kms: </span><span className="shareModerateValue">{tData[i]['display_sharemoderate']}</span></div>
                            <div className="totalsShareHard"><span className="shareLabel">Hard kms: </span><span className="shareHardValue">{tData[i]['display_sharehard']}</span></div>
                            <div className="totalsWeeknumBox"><a href={'/dashboard?weeknum=' + tData[i]['weeknum']}>Dashboard for week {tData[i]['weeknum']}</a></div>
                        </div>
                    );
                }
                else {
                    return (
                        <div className="totalsBoxClickable" title={'Click to view dashboard for week ' + tData[i]['weeknum']} onClick={(e) => directToTotals(tData[i]['weeknum'])}>
                            <div className="totalsTotalKms"><span className="totalKmsValue">{tData[i]['display_kmstotal']}</span><div className="totalsLabel">distance</div></div>
                            <div className="totalsShareEasy"><span className="shareLabel">Easy kms: </span><span className="shareEasyValue">{tData[i]['display_shareeasy']}</span></div>
                            <div className="totalsShareModerate"><span className="shareLabel">Moderate kms: </span><span className="shareModerateValue">{tData[i]['display_sharemoderate']}</span></div>
                            <div className="totalsShareHard"><span className="shareLabel">Hard kms: </span><span className="shareHardValue">{tData[i]['display_sharehard']}</span></div>
                        </div>
                    );
                }
            }
        }
        return '';
    }

    const directToActivity = (actId) => {
        window.location = '/activity/' + actId['actId'].toString();
    }

    const getKeyByValue = (object, value) => {
        return Object.keys(object).filter(key => object[key] === value);
    }

    // Returns a 'tile' with activity data, which is the contents of the cell
    // Iterates through the array of activityData (Array of Dicts)
    // When a matching date is found for one of the Dict elements,
    // stores the index of the array in the hitIndices array, which is then used in the 
    // Return statement, to pull tyhe correct data out of tyhe activityData dict
    const getActivityTileByDate = (dt, activityData) => {

        var calDate = dateAsYYYYMMDD(dt);
        let hitIndices = [];
        let actIds = [];
        let dayDistance = 0;

        for (let i = 0; i < activityData.length; i++) {

            var actData = activityData[i];
            let keyHit = Object.keys(actData).find(key => dateAsYYYYMMDD(actData[key]) === calDate);

            if (keyHit != null) {
                // Add the index for which a hit occured 
                // to the array
                hitIndices.push(i);
                actIds.push(actData['activityID']);

                // Add the distance for the activity to the sum for the day
                dayDistance += parseFloat(actData['distance']);
            }
        }
        // Keep only the additional activities in the array of activity IDs
        // as the last element (the first activity of the day) is already rendered on the tile
        actIds.pop();

        let isSmallViewport = false;
        let sliceLength = 60;
        if (point === 'sm' || point === 'xs') {
            isSmallViewport = true;
            sliceLength = 20;
        }

        let lastIndex = hitIndices.length - 1;
        if (hitIndices.length > 0) {
            let actId = activityData[hitIndices[lastIndex]]['activityID'];
            let actDistance = activityData[hitIndices[lastIndex]]['display_distance'];
            let actDuration = activityData[hitIndices[lastIndex]]['display_duration'];
            let actTitle = activityData[hitIndices[lastIndex]]['title'];
            let actAvgPace = activityData[hitIndices[lastIndex]]['avg_pace'];
            let actAvgPower = activityData[hitIndices[lastIndex]]['avg_power'];

            let isRace = false;
            if (actTitle != null) {
                isRace = (actTitle.substring(0, 1) === 'R');
            }

            return (
                <>
                    <div id={'activityTile_'+ actId} className={isRace ? ("activityCalendarActSummary activityCalendarActSummaryRace") : ("activityCalendarActSummary")} onClick={(e) => directToActivity({ actId })}>
                        {actId}
                        <br/>
                        <a href={'/activity/' + actId}>{textEllipsis(actTitle, sliceLength)}</a>
                        {(!isSmallViewport && hitIndices.length ==1) ? (
                            <div className="activityCalendarActSummaryExtendedDetailsBox">
                                <div className="activityCalendarActSummaryExtendedDetailsItemContainer">
                                    <span className="activityCalendarActSummaryExtendedDetailsLabel">Distance:&nbsp;&nbsp;</span>
                                    <span className="activityCalendarActSummaryExtendedDetailsValue">{actDistance}</span>
                                </div>
                                <div className="activityCalendarActSummaryExtendedDetailsItemContainer">
                                    <span className="activityCalendarActSummaryExtendedDetailsLabel">Time:&nbsp;&nbsp;</span>
                                    <span className="activityCalendarActSummaryExtendedDetailsValue">{actDuration}</span>
                                </div>
                                <div className="activityCalendarActSummaryExtendedDetailsItemContainer">
                                    <span className="activityCalendarActSummaryExtendedDetailsLabel">Avg Pace:&nbsp;&nbsp;</span>
                                    <span className="activityCalendarActSummaryExtendedDetailsValue">{actAvgPace}</span>
                                </div>
                                {(actAvgPower != "0") ? (
                                    <div className="activityCalendarActSummaryExtendedDetailsItemContainer">
                                        <span className="activityCalendarActSummaryExtendedDetailsLabel">Avg Power:&nbsp;&nbsp;</span>
                                        <span className="activityCalendarActSummaryExtendedDetailsValue">{actAvgPower}</span>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                    </div>
                    {(hitIndices.length > 1) ? (
                        <div className="activityCalendarExtraActivitiesBox">
                            <div className="activityCalendarExtraActivitiesBoxHeader">{lastIndex} more {getSingleOrPlural(lastIndex)}</div>
                            {actIds.map(getAdditionalActivityLinks)}
                            <br/>
                            <span className="activityCalendarExtraActivitiesBoxDaytotalLabel">Day total: </span>
                            <span className="activityCalendarExtraActivitiesBoxDaytotalValue">{dayDistance.toFixed(2)} km</span>
                        </div>
                    ) : null}
                </>

            );
        }
    }

    const getAdditionalActivityLinks = (actId) => {
        if (point === 'sm' || point === 'xs') {
            return (<span key={actId}><a href={'/activity/' + actId}>{textEllipsis(actId.toString(), 5)}</a>&nbsp;</span>);
        }
        return (<span key={actId}><a href={'/activity/' + actId}>{actId.toString()}</a>&nbsp;</span>);
    }

    const getSingleOrPlural = (extraActivityCount) => {
        if (extraActivityCount > 1) return 'activities';
        return 'activity';
    }

    const renderDateString = (dt) => {
        if (point != 'sm' && point != 'xs') {
            return (
                <div className="datelabel">
                    {shortMonthNames[parseInt(dt.getMonth())]} {dt.getFullYear()}
                </div>
            );
        }
        return '';
    }

    const writeDataRow = (dates, rowIndex) => {
        // Destructure a series of activities for a week
        const { weeknum, day1, day2, day3, day4, day5, day6, day7 } = dates; //destructuring. See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Destructuring_assignment
        return (
            <tr key={day1.toString() + weeknum}>
                <td><div className="daylabel">{day1.getDate()}</div>{renderDateString(day1)}{getActivityTileByDate(day1, data)}</td>
                <td><div className="daylabel">{day2.getDate()}</div>{renderDateString(day2)}{getActivityTileByDate(day2, data)}</td>
                <td><div className="daylabel">{day3.getDate()}</div>{renderDateString(day3)}{getActivityTileByDate(day3, data)}</td>
                <td><div className="daylabel">{day4.getDate()}</div>{renderDateString(day4)}{getActivityTileByDate(day4, data)}</td>
                <td><div className="daylabel">{day5.getDate()}</div>{renderDateString(day5)}{getActivityTileByDate(day5, data)}</td>
                <td><div className="daylabel">{day6.getDate()}</div>{renderDateString(day6)}{getActivityTileByDate(day6, data)}</td>
                <td><div className="daylabel">{day7.getDate()}</div>{renderDateString(day7)}{getActivityTileByDate(day7, data)}</td>
                <td className="totalsCell"><div className="totalsBox">{getTotalsByWeeknum(weeknum, totalsData)}</div></td>
            </tr>
        )
    }

    const renderTableData = () => {
        if (weeksInMonth == null) return '';
        // Use array map function to loop through the data elements
        return weeksInMonth.map(writeDataRow);
    }

    return (
        <>
            <div id="calendarHorizontalScrollMenu" className="calendarHorizontalScrollMenu"></div>
            <br/>
            
            <div>
                {dataFetched ? (
                    <div style={{ 'overflowX': 'auto' }}>
                        <table id='activityCalendarTable'>
                            <tbody>
                                <tr>{renderTableHeader()}</tr>
                                {data != null ? (renderTableData()) : null}
                            </tbody>
                        </table>
                    </div>
                ) :
                    (<div className="containerNoData"><div className="dotWrapper"><ThreeDots color="hotpink" height={40} width={40} /></div></div>)
                }
            </div>

            <br/>
            <form>
                <label>
                    Month:&nbsp;
                    <select id="weekSelector" value={calendarMonthToView} onChange={handleChange} />
                </label>
            </form>
            <br />
        </>
    );

}