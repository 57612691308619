import React, { Component } from 'react';
import axios from "axios";
import DataTable, { createTheme } from 'react-data-table-component';
import { ThreeDots } from 'react-loader-spinner'
import { getWeekYear, capitalizeFirst } from '../../utils/Utils';
import TextAnimate from '../../utils/TextAnimate';
import CustDropdownButton from './CustDropdownButton ';
import TableRowExpandedRow from "./TableRowExpandedRow.js";

// A super simple expandable component.
const ExpandedComponent = ({ data }) => <TableRowExpandedRow data={data} theme="solarized_exp"/>

// createTheme creates a new theme named solarized that overrides the built in dark theme
//ToDo: Put this code in an include
createTheme('solarized', {
    text: {
        font: 'Arial, Helvetica, sans- serif',
        primary: '#268bd2',
        secondary: '#2aa198',
    },
    background: {
        default: '#0b2d52',
    },
    context: {
        background: '#cb4b16',
        text: '#FFFFFF',
    },
    divider: {
        default: '#046d7d',
    },
    action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
    },
}, 'dark');


export default class Tableviews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataFetched: false,
            weekfilter_start: this.props.weekfilter_start,
            weekfilter_end: this.props.weekfilter_end,
            tableData: [],
            tableHeaders: [],
            tableKeyfield: '',
            modelSelected: 'Totals',
            rowsExpanded: [],
        }

        // This binding is necessary to make `this` work in the callback
        this.buttonClick = this.buttonClick.bind(this);
        // Initiate the buttongriupata with the Totals model
        this.buttonGroupData = this.setButtonGroupData('Totals');
    }

    modelNames =
        {
            PaceZone: 'pacezones',
            PowerZone: 'powerzones',
            Totals: 'totals',
            Activity: 'activities',
            Lap: 'laps',
        };

    keyFields =
        {
            PaceZone: 'name',
            PowerZone: 'name',
            Totals: 'weeknum',
            Activity: 'activityID',
            Lap: 'actLap',
        };

    setButtonGroupData(modelString) {
        let buttonGroupData =
        {
            "buttontext": "Select period",
            "handler": "handleDateSelect",
            "domElementToUpdate": "headDataTables",
            "domElementInnerHTMLvals":
                [
                    modelString + " - This week",
                    modelString + " - This month",
                    modelString + " - This year",
                    modelString + " - Last month",
                    modelString + " - Last 3 months",
                    modelString + " - Last 6 months",
                    modelString + " - Last 12 months"
                ],
            "ids": ["ddi_this_week", "ddi_this_month", "ddi_this_year", "ddi_1m", "ddi_3m", "ddi_6m", "ddi_12m"],
            "labels": ["This week", "This month", "This year", "Last month", "Last 3 months", "Last 6 months", "Last 12 months"]
        };
        // Add the years for this year down to 2018 (no data earlier than 2018 in DB)
        let year = getWeekYear(new Date()) - 1;
        for (year; year > 2017; year--) {
            buttonGroupData["ids"].push('ddi_' + year);
            buttonGroupData["labels"].push(year);
            buttonGroupData["domElementInnerHTMLvals"].push(modelString + ' - ' + year);
        }
        buttonGroupData["ids"].push('ddi_all');
        buttonGroupData["labels"].push('All');
        buttonGroupData["domElementInnerHTMLvals"].push(modelString + ' - All time');
        return buttonGroupData;
    }

    componentDidMount = () => {
        this.fetchData(this.state.weekfilter_start, this.state.weekfilter_end, this.state.modelSelected);
    }

    buttonClick = (modelName, event) => {
        // Invoke setState with fetchData in the call, to ensure fetchData is not called 
        // before the dataFetched flag is set to false
        this.setState
            (
                {
                    modelSelected: modelName,
                    dataFetched: false,
                },
                this.fetchData(this.state.weekfilter_start, this.state.weekfilter_end, modelName));

        // And then, hackity hack, get the title of the table and push the correct modelname into it
        let el = document.getElementById("headDataTables");
        // Use the '-' character to find where the name of the model ends
        let index = el.innerHTML.indexOf("-");
        let model = capitalizeFirst(this.modelNames[modelName]);
        // Inject the model name back into the innerHtml
        el.innerHTML = model + ' ' + el.innerHTML.substring(index);
        // Also update the buttonGroupData with the new model name
        this.buttonGroupData = this.setButtonGroupData(model);

    }

    fetchData = (week_start, week_end, modelName = this.state.modelSelected) => {
        this.setState({ dataFetched: false });

        let dataurl = process.env.REACT_APP_APIBASEURL + '/api/' + this.modelNames[modelName];
        dataurl += '?weekfilter_start=' + week_start
        dataurl += '&weekfilter_end=' + week_end;

        // first get the headers
        axios.get(process.env.REACT_APP_APIBASEURL + '/api/colheaders')
            .then((getResponse) => {

                let headers = getResponse.data[modelName];
                let columns = [];
                for (let i = 0; i < headers.length; i++) {
                    let colDef = {};

                    let selector = 'row.' + headers[i];
                    colDef['selector'] = row => eval(selector);
                    
                    colDef['id'] = headers[i];
                    // If the fieldname contains 'display_' remove this and capitalize all fieldnames
                    let name = headers[i].replace('display_', '');
                    name = name[0].toUpperCase() + name.substring(1);
                    colDef['name'] = name;

                    // Make columns sortable
                    colDef['sortable'] = true;

                    // If this is a distance field, format to two decimals 
                    if (headers[i] == 'distance') {
                        colDef['format'] = row => row.distance.toFixed(2);
                    }

                    // Add hyperlink if activityId column
                    if (modelName == 'Activity' && headers[i] == 'activityID') {
                        colDef['cell'] = row => <a className="DataTableRowLink" href={'/activity/' + row.activityID}>{row.activityID}</a>;
                    }

                    // Add hyperlink if Title (of actvity) column
                    if (modelName == 'Activity' && headers[i] == 'title') {
                        colDef['cell'] = row => <a className="DataTableRowLink" href={'/activity/' + row.activityID}>{row.title}</a>;
                    }
                    columns.push(colDef);
                }
                this.setState({ tableHeaders: columns, tableKeyfield: this.keyFields[modelName], });
            }).then(
                axios.get(dataurl)
                    .then((getResponse) => {
                        this.setState({ dataFetched: true, tableData: getResponse.data, weekfilter_start: week_start, weekfilter_end: week_end });
                    }).catch((error) => {
                        console.error('ERROR retrieving weektotals data');
                        console.error(error);
                    }));
        return;
    }
    
    rowSort = (e) => {
        
        // Retrieve the ids of the rows that have been expanded
        // This is very hacky, relying on the React-DataTable js usage of the data-testid attribute and 
        // the aria-label atttribute to identify the rows to be collapsed. It may break if the implementation
        // of React DataTable changes
        var buttons = document.querySelectorAll("[data-testid^='expander-button-'][aria-label='Collapse Row']");

        // Note buttons[i].onclick(); Does NOT WORK. Call the method, not the event handler.
        // This method is called to effectively collapse all expanded rows, relying on the HIDDEN expand button
        for (let i = 0; i < buttons.length; i++) buttons[i].click();
    }

    renderTable = () => {
        if (this.state.dataFetched) {
            if (this.state.modelSelected == 'Activity' || this.state.modelSelected == 'Totals') {
                return (
                    <DataTable
                        keyField={this.state.tableKeyfield}
                        columns={this.state.tableHeaders}
                        data={this.state.tableData}
                        expandableRows
                        expandableRowsComponent={ExpandedComponent}
                        expandOnRowClicked
                        pagination
                        highlightOnHover
                        //striped
                        theme="solarized"
                        onSort={this.rowSort}

                    />);
            }
            else {
                return (
                    <DataTable
                        keyField={this.state.tableKeyfield}
                        columns={this.state.tableHeaders}
                        data={this.state.tableData}
                        pagination
                        highlightOnHover
                        //striped
                        theme="solarized"
                        onSort={this.rowSort}
                    />);
            }
        }
        else {
            return (<div className="dotWrapper"><ThreeDots color="hotpink" height={40} width={40} /></div>);
        }
    }

    buttonClassName(modelname) {
        if (this.state.modelSelected == modelname) return "btn btn-warning";
        return "btn btn-primary";
    }



    render = () => {
        return (
            <main>
                <div className="row mx-1">
                    <div className="col-mdheadDataTables-12 col-sm-12 p-1">
                        <div><h4 id="headDataTables">Totals - This year</h4></div>
                    </div>
                </div>
                <div className="row mx-1">
                    <div className="col-mdheadDataTables-10 col-sm-10 p-1">
                        <div className="btn-group btn-group-sm">
                            <button type="button" className={this.buttonClassName('Totals')} onClick={(e) => this.buttonClick('Totals', e)}>
                                Weekly Totals
                            </button>

                            &nbsp;
                            <button type="button" className={this.buttonClassName('Activity')} onClick={(e) => this.buttonClick('Activity', e)}>
                                Activities
                            </button>
                            &nbsp;
                            <button type="button" className={this.buttonClassName('Lap')} onClick={(e) => this.buttonClick('Lap', e)}>
                                Laps
                            </button>

                            &nbsp;
                            <button type="button" className={this.buttonClassName('PaceZone')} onClick={(e) => this.buttonClick('PaceZone', e)}>
                                Pace Zones
                            </button>
                            &nbsp;
                            <button type="button" className={this.buttonClassName('PowerZone')} onClick={(e) => this.buttonClick('PowerZone', e)}>
                                Power Zones
                            </button>
                        </div>
                    </div>
                    <div className="col-md-2 col-sm-2 p-1" id="buttoncol">
                        {((this.state.modelSelected != 'PaceZone') && (this.state.modelSelected != 'PowerZone')) ? (
                            (this.state.dataFetched) ? (
                                <CustDropdownButton parentCallback={this.fetchData} buttonData={this.buttonGroupData} />
                            ) : <TextAnimate text="fetching results" />
                        ) : null}
                    </div>  
                    </div>
                    <hr />
                    <div>
                        {this.renderTable()}
                    </div>
             </main>
        );
    }
}