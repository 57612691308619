import React, { useState, useEffect } from "react";
import axios from "axios";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toastOnError, capitalizeFirst } from "../../utils/Utils";
import ConfirmDelete from "../../components/utils/ConfirmDelete";
import { ThreeDots } from 'react-loader-spinner';
import '../../css/datatable.css';

export default function PowerZonesTable(parentprops) {
    const [dataFetched, setfetched] = useState(false);
    const [colHeaders, setColHeaders] = useState(null);
    const [data, setData] = useState(null);
    const [url, setUrl] = useState(process.env.REACT_APP_APIBASEURL + '/api/powerzones/');
    const [toggleTriggerDeleteModal, setToggleTriggerDeleteModal] = useState(false);
    const [zoneToDelete, setZoneToDelete] = useState(null);
    const [fieldErrors, setFieldErrors] = useState(false);
    const [processingPowerZonesForActivities, setProcessingPowerZonesForActivities] = useState(false);
    const [currentZoneProcessing, setCurrentZoneProcessing] = useState(null);
    const [isAsyncCall, setIsAsyncCall] = useState(false);
    // Needed to control the visibility if the Datepicker boxes
    const [startDate, setStartDate] = useState(new Date());
    const [newZoneDate, setNewZoneDate] = useState(new Date());
    const [zoneDates, setZoneDates] = useState({});

    // NOTE the empty dependency array [] at the end of useEffect. This will prevent useEffect from
    // being called ad infinitum, when state refreshes are done
    useEffect(() => {
        fetchData(url);
        // This is needed, as the headers cannot bne pulled from the data in the
        // case where a user has not yet entered any powerzones
        //fetchHeaders("/api/colheaders");
    }, []); // includes empty dependency array here to prevent infinite loop of calls

    const fetchData = (url) => {
        setfetched(false);

        axios.get(url)
            .then((getResponse) => {
                // Fill the state dict with dates, to be used in the Datepicker component
                populateZoneDates(getResponse.data);
                setData(getResponse.data);
            })
            .then(
                axios.get("/api/colheaders")
                    .then((getResponse) => {
                        let headers = getResponse.data['PowerZone']
                        setColHeaders(headers);
                        setfetched(true);
                    })
            )
            .catch((error) => {
                console.error('ERROR retrieving data');
                console.error(error);
            });
    }
    const populateZoneDates = (dataDict) => {
        if (dataDict === null) return;
        let dict = {};
        for (let i = 0; i < dataDict.length; i++) {
            dict[dataDict[i]['id']] = new Date(dataDict[i]['date']);
        }
        dict['NEW'] = new Date();
        setZoneDates(dict);
    }
    const addPowerZone = (powerZoneData) => {
        axios
            .post("/api/powerzones/", powerZoneData)
            .then(response => { fetchData(url); })
            .catch(error => {
                toastOnError(error);
                setfetched(true);
            });
    };

    const updatePowerZone = async (powerZoneData, powerZoneId) => {
        try {
            setCurrentZoneProcessing(powerZoneData['name']);
            let response
                = await axios
                    .put("/api/powerzones/" + powerZoneId + "/", powerZoneData)
                    .then(updatePowerZonesForActivities(powerZoneId))
                    .catch(error => { toastOnError(error); });
            // --> Not used, replaced by overlay and messaging in popup box
            //document.getElementById('updateConfirmation_' + powerZoneId).className = 'updateConfirmationVisible';
        } catch (error) {
            toastOnError(error);
        }
    }

    const updatePowerZonesForActivities = (powerZoneId) => {
        setProcessingPowerZonesForActivities(true);
        // Call the API to process the power zones
        let url = process.env.REACT_APP_PROCESSPOWERZONESSURL + "?powerzone=" + powerZoneId;
        //console.log(url);
        axios.get(url)
            .then((getResponse) => {
                //console.log("Processing power zone. Response:");
                //console.log(getResponse);
                let jobId = getResponse.data["job_id"];
                if (jobId === -1) {
                    // A synchronous call was made. 
                    // Once the response returns, the process is done
                    // In the mean time, no status updates can be retrieved
                    setProcessingPowerZonesForActivities(false);
                    setCurrentZoneProcessing(null);

                    // Re-run the update totals call
                    axios.get(process.env.REACT_APP_TOTALSURL).catch((error) => {
                        console.log(error);
                    });
                }
                else {
                    // Spinner is only needed for synchronous calls. For async calls it is replaced by the progress bar
                    setIsAsyncCall(true);
                    pollJobStatus(jobId);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const pollJobStatus = (jobId) => {

        document.getElementById('processingDialogProgressText').style.visibility = 'visible';
        document.getElementById('progressBarOuter').style.visibility = 'visible';
        //console.log('Polling job ', jobId);
        let url = process.env.REACT_APP_JOBSTATUS + jobId;
        axios.get(url)
            .then((getResponse) => {

                //console.log("Processing pace zones. Response:");
                //console.log(getResponse);
                let status = getResponse.data["status"];
                let progress = getResponse.data["progress"];
                let numTotal = getResponse.data["numtotal"];
                let numProcessed = getResponse.data["numprocessed"];
                let newWidth = parseInt(numProcessed / numTotal * 100) + '%';

                if (status === 'finished') {
                    // Done, close the overlay
                    setProcessingPowerZonesForActivities(false);
                    setCurrentZoneProcessing(null);

                    // Re-run the update totals call
                    axios.get(process.env.REACT_APP_TOTALSURL).catch((error) => {
                        console.log(error);
                    });
                }
                else if (status === 'processing') {
                    // Still processing, write the progress info into the overlay
                    document.getElementById('processingDialogProgressText').innerHTML = 'Laps processed: ' + progress;
                    document.getElementById('progressBarInner').setAttribute("style", "width:" + newWidth);
                    // Recursively call self to re-poll in 1s
                    setTimeout(pollJobStatus, 100, jobId);
                }
                else {
                    // Unknown status
                    console.error('Unable to retrieve job status for job ', jobId)
                }

            })
            .catch((error) => {
                console.log(error);
            });
    }

    const deletePowerZone = (powerZoneId) => {
        setZoneToDelete(powerZoneId);
        deletePowerZoneWithConfirm(true, false);
    };

    const deletePowerZoneWithConfirm = (requestConfirm, confirmed) => {
        if (requestConfirm) {
            // If the delete button was hit, without the modal having been triggered, trigger the modal
            setToggleTriggerDeleteModal(true);
            return;

        }
        else if (confirmed) {
            axios
                .delete("/api/powerzones/" + zoneToDelete)
                .then(response => { fetchData(url); })
                .catch(error => {
                    toastOnError(error);
                });
        }
        setToggleTriggerDeleteModal(false);

    };

    const rowDelete = (rowId) => {
        let powerZoneId = rowId['id'].toString();
        deletePowerZone(powerZoneId);
    }

    const setZoneDate = (date, rowId) => {
        // Needed to get the Datepicker to close
        setStartDate(new Date());

        // Hide the update success text
        document.getElementById('updateConfirmation_' + rowId).className = 'updateConfirmationHidden';

        // Update the newly passed date into the state dict
        let dict = zoneDates;
        dict[rowId] = date;
        setZoneDates(dict);
    }

    // Validate that all fields in the row meet criteria
    const validateRow = (rowId) => {

        // Note form field validation for the date is not needed, as incorrect dates will be set back
        // to the original date by the Datepicker component

        // Hide the update success text
        document.getElementById('updateConfirmation_' + rowId).className = 'updateConfirmationHidden';

        // Set button to active and enabled
        let errors = false;
        if (rowId === 'NEW') {
            document.getElementById('rowButton_' + rowId).className = "btnStandard btnStandardAddNew";
        }
        else {
            document.getElementById('rowButton_' + rowId).className = "btnStandard btnStandardUpdate";
        }
        document.getElementById('rowButton_' + rowId).disabled = false;
        let res1 = false, res2 = false, res3 = false, res4 = false, res5 = false, res6 = false, res7 = false, res8 = false;
        res1 = validatePowerField(rowId, 'P1_' + rowId, 'P1_errortext' + rowId, 'rowButton_' + rowId);
        res2 = validatePowerField(rowId, 'P2_' + rowId, 'P2_errortext' + rowId, 'rowButton_' + rowId);
        res3 = validatePowerField(rowId, 'P3_' + rowId, 'P3_errortext' + rowId, 'rowButton_' + rowId);
        res4 = validatePowerField(rowId, 'P4_' + rowId, 'P4_errortext' + rowId, 'rowButton_' + rowId);
        res5 = validatePowerField(rowId, 'P5_' + rowId, 'P5_errortext' + rowId, 'rowButton_' + rowId);
        res6 = validatePowerField(rowId, 'P6_' + rowId, 'P6_errortext' + rowId, 'rowButton_' + rowId);
        res7 = validatePowerField(rowId, 'P7_' + rowId, 'P7_errortext' + rowId, 'rowButton_' + rowId);

        res8 = validateTitleField(rowId, 'name_' + rowId, 'name_errortext' + rowId, 'rowButton_' + rowId);

        errors = errors + res1 + res2 + res3 + res4 + res5 + res6 + res7 + res8;

        // Set button to inactive and disabled style if errors found
        if (errors > 0) {
            document.getElementById('rowButton_' + rowId).className = "btnStandard btnStandardDisabled";
            document.getElementById('rowButton_' + rowId).disabled = true;
        }
        setFieldErrors(errors);
    }
    const validateTitleField = (rowId, inputId, errTextEl, buttonId) => {
        let titleValue = document.getElementById(inputId).value.toString();
        let err = '';

        document.getElementById(errTextEl).innerHTML = '';
        if (titleValue.length === 0) {
            err = 'Empty name. Please provide a name for the pacezone.';
            document.getElementById(errTextEl).innerHTML = err;
            return true;
        }
        if (titleValue.length > 99) {
            err = 'Please use max 100 characters.';
            document.getElementById(errTextEl).innerHTML = err;
            return true;
        }
        return false;
    }
    const validatePowerField = (rowId, inputId, errTextEl, buttonId) => {

        // Reset error text to empty
        document.getElementById(errTextEl).innerHTML = '';

        // Note that Power fields P1-P5 are compulsory but fields P6 and P7 are optional
        // Different treatment is needed

        // Regex for the power format. Will allow entries from 0 to 9999
        let regexPattern = /^[1-9][0-9]{0,3}$/gm;

        let err = 'Please enter a power value between 0 and 10,000';
        if (parseInt(inputId.substring(1, 2)) > 5) {
            // Regex for the power format. Will allow entries from 0 to 9999 or an empty string
            regexPattern = /(^[1-9][0-9]{0,3}$)|(^$)/gm
            err = 'Please enter a power value between 0 and 10,000 or leave blank.';
        }

        let paceValue = document.getElementById(inputId).value.toString();

        let hits = paceValue.search(regexPattern);
        if (hits == null || hits < 0) {
            document.getElementById(errTextEl).innerHTML = err;
            return true;
        }
        return false;
    }

    const rowClick = (rowId, isNew) => {
        let powerZoneId = rowId['id'].toString();

        let dict = {};
        for (let i = 0; i < colHeaders.length; i++) {
            let elName = colHeaders[i] + '_' + powerZoneId;
            let val = document.getElementById(elName).value;
            if (val != '') dict[colHeaders[i]] = val;
            else dict[colHeaders[i]] = null;
        }

        if (isNew) {
            addPowerZone(dict);
        }
        else {
            updatePowerZone(dict, powerZoneId);
            // In the case of a field update, write the values of the inputs back into the state's data dict,
            // to keep the state data in sync with the remote data
            let tempData = data;
            for (let i = 0; i < tempData.length; i++) {
                if (tempData[i]['id'] == powerZoneId) {
                    // The id key-value pair needs adding back in
                    // as this is not present in the data coming from the table
                    dict['id'] = powerZoneId;
                    tempData[i] = dict;
                }
            }
            setData(tempData);
        }
    }

    const formatColHeaders = (columnTitle) => {
        switch (columnTitle) {
            case 'name':
                return 'Power zone name';
            case 'date':
                return 'Date (yyyy-mm-dd)';
            case 'P1':
                return 'P1 (W)';
            case 'P2':
                return 'P2 (W)';
            case 'P3':
                return 'P3 (W)'
            case 'P4':
                return 'P4 (W)';
            case 'P5':
                return 'P5 (W)';
            case 'P6':
                return 'P6 (W)';
            case 'P7':
                return 'P7 (W)';
            default:
                return columnTitle;
        }
    }

    const renderTableHeader = () => {
        return colHeaders.map((key, index) => {
            return <th align="right" key={index}>{formatColHeaders(key)}</th>
        })
        return;
    }

    const writeDataRow = (powerZone) => {
        const { id, name, date, P1, P2, P3, P4, P5, P6, P7 } = powerZone //destructuring. See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Destructuring_assignment

        return (
            <tr key={id}>
                <td style={{ 'width': '20px', 'display': 'none', }}><input type="text" id={'id_' + id} value={id} readOnly /></td>
                <td className="cellW210">
                    <input type="text" id={'name_' + id} style={{ 'textAlign': 'right' }} className="cellW200" onChange={(e) => validateRow(id)} defaultValue={name} />
                    <br />
                    <span id={'name_errortext' + id} className="errorTextWrongPaceFormat"></span>
                </td>
                <td className="cellW110">
                    <Datepicker
                        id={'date_' + id}
                        className="dateSelector"
                        dateFormat="yyyy-MM-dd"
                        selected={zoneDates[id]}
                        onChange={(date) => setZoneDate(date, id)}
                        fixedHeight

                    />
                    <span id={'date_errortext' + id} className="errorTextWrongPaceFormat"></span>
                </td>
                <td className="cellW60">
                    <input type="text" id={'P1_' + id} className="cellW50" onChange={(e) => validateRow(id)} defaultValue={P1} />
                    <br />
                    <span id={'P1_errortext' + id} className="errorTextWrongPaceFormat"></span>
                </td>
                <td className="cellW60">
                    <input type="text" id={'P2_' + id} className="cellW50" onChange={(e) => validateRow(id)} defaultValue={P2} />
                    <br />
                    <span id={'P2_errortext' + id} className="errorTextWrongPaceFormat"></span>
                </td>
                <td className="cellW60">
                    <input type="text" id={'P3_' + id} className="cellW50" onChange={(e) => validateRow(id)} defaultValue={P3} />
                    <br />
                    <span id={'P3_errortext' + id} className="errorTextWrongPaceFormat"></span>

                </td>
                <td className="cellW60">
                    <input type="text" id={'P4_' + id} className="cellW50" onChange={(e) => validateRow(id)} defaultValue={P4} />
                    <br />
                    <span id={'P4_errortext' + id} className="errorTextWrongPaceFormat"></span>
                </td>
                <td className="cellW60">
                    <input type="text" id={'P5_' + id} className="cellW50" onChange={(e) => validateRow(id)} defaultValue={P5} />
                    <br />
                    <span id={'P5_errortext' + id} className="errorTextWrongPaceFormat"></span>

                </td>
                <td className="cellW60">
                    <input type="text" id={'P6_' + id} className="cellW50" onChange={(e) => validateRow(id)} defaultValue={P6} />
                    <br />
                    <span id={'P6_errortext' + id} className="errorTextWrongPaceFormat"></span>
                </td>
                <td className="cellW60">
                    <input type="text" id={'P7_' + id} className="cellW50" onChange={(e) => validateRow(id)} defaultValue={P7} />
                    <br />
                    <span id={'P7_errortext' + id} className="errorTextWrongPaceFormat"></span>
                </td>
                <td className="cellW100">
                    <button id={'rowButton_' + id} className="btnStandardUpdate btnStandard" onClick={(e) => rowClick({ id }, false)}>
                        Update
                    </button>
                    <span id={'updateConfirmation_' + id} className="updateConfirmationHidden">Successfully updated</span>
                </td>
                <td className="cellW100">
                    <button id={'deleteButton_' + id} className="btnStandardDelete btnStandard" onClick={(e) => rowDelete({ id })}>
                        Delete
                    </button>
                </td>
            </tr>
        )
    }

    const renderTableData = () => {
        if (data == null) return '';
        // Use array map function to loop through the data elements
        return data.map(writeDataRow);
    }

    const renderEmptyRow = () => {
        let id = 'NEW';
        return (
            <tr key={id}>
                <td style={{ 'width': '20px', 'display': 'none', }}><input type="text" id={'id_' + id} value="" readOnly /></td>
                <td className="cellW210">
                    <input type="text" id={'name_' + id} style={{ 'textAlign': 'right' }} className="cellW200" onChange={(e) => validateRow(id)} defaultValue="New zones entry" />
                    <br />
                    <span id={'name_errortext' + id} className="errorTextWrongPaceFormat"></span>
                </td>
                <td className="cellW110">
                    <Datepicker
                        id={'date_' + id}
                        className="dateSelector"
                        dateFormat="yyyy-MM-dd"
                        selected={zoneDates[id]}
                        onChange={(date) => setZoneDate(date, id)}
                        fixedHeight
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                    />
                </td>
                <td className="cellW60">
                    <input type="text" id={'P1_' + id} className="cellW50" onChange={(e) => validateRow(id)} defaultValue="111" />
                    <br />
                    <span id={'P1_errortext' + id} className="errorTextWrongPaceFormat"></span>
                </td>
                <td className="cellW60">
                    <input type="text" id={'P2_' + id} className="cellW50" onChange={(e) => validateRow(id)} defaultValue="222" />
                    <br />
                    <span id={'P2_errortext' + id} className="errorTextWrongPaceFormat"></span>
                </td>
                <td className="cellW60">
                    <input type="text" id={'P3_' + id} className="cellW50" onChange={(e) => validateRow(id)} defaultValue="333" />
                    <br />
                    <span id={'P3_errortext' + id} className="errorTextWrongPaceFormat"></span>
                </td>
                <td className="cellW60">
                    <input type="text" id={'P4_' + id} className="cellW50" onChange={(e) => validateRow(id)} defaultValue="444" />
                    <br />
                    <span id={'P4_errortext' + id} className="errorTextWrongPaceFormat"></span>
                </td>
                <td className="cellW60">
                    <input type="text" id={'P5_' + id} className="cellW50" onChange={(e) => validateRow(id)} defaultValue="555" />
                    <br />
                    <span id={'P5_errortext' + id} className="errorTextWrongPaceFormat"></span>
                </td>
                <td className="cellW60">
                    <input type="text" id={'P6_' + id} className="cellW50" onChange={(e) => validateRow(id)} defaultValue="666" />
                    <br />
                    <span id={'P6_errortext' + id} className="errorTextWrongPaceFormat"></span>
                </td>
                <td className="cellW60">
                    <input type="text" id={'P7_' + id} className="cellW50" onChange={(e) => validateRow(id)} defaultValue="777" />
                    <br />
                    <span id={'P7_errortext' + id} className="errorTextWrongPaceFormat"></span>
                </td>
                <td className="cellW100">
                    <button id={'rowButton_' + id} className="btnStandardAddNew btnStandard" onClick={(e) => rowClick({ id }, true)}>
                        Add New
                    </button>
                    <span id={'updateConfirmation_' + id} className="updateConfirmationHidden">Successfully updated</span>
                </td>
                <td className="cellW100"></td>
            </tr>
        )
    }

    return (
        <>
            <div>
                {processingPowerZonesForActivities ?

                    <div id="overlayPane">
                        <div id="processingDialogModalWindow">
                            <div id="processingDialogInnerText">Updating and applying new entries for powerzone <i>{currentZoneProcessing}</i>.
                                <br /><br />
                                <div id="processingDialogProgressText">
                                    Please bear with the lap cruncher... this may take some time.&nbsp;
                                </div>
                                <br />
                                {isAsyncCall ?
                                    <div id="progressBarOuter"><div id="progressBarInner"></div></div>
                                    :
                                    <div id="spinnerDiv">
                                        <ThreeDots className="spinner" color="#00BFFF" height={40} width={40} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
            </div>
            {toggleTriggerDeleteModal ? (<ConfirmDelete title="Delete Powerzone?" text="Are you sure you want to delete this powerzone?" callback={deletePowerZoneWithConfirm} />) : null}
            <div>
                {dataFetched ? (
                    <div style={{ 'overflowX': 'auto' }}>
                        <table id='datatable'>
                            <tbody>
                                <tr>{renderTableHeader()}<th></th><th></th></tr>
                                {data != null ? (renderTableData()) : null}
                                {renderEmptyRow()}
                            </tbody>
                        </table>
                    </div>
                ) : <div className="dotWrapper"><ThreeDots color="hotpink" height={40} width={40} /></div>}
            </div>
        </>

    )
}