import React, { Component } from 'react';
import axios from "axios";
//import { BarChart } from "../components/Chart";
import ChartDataLabels from 'chartjs-plugin-datalabels';
// Note these two are needed to make the chartjs charts render properly
import { Chart as ChartJS } from 'chart.js/auto'
import { Chart } from 'react-chartjs-2'
import { Bar } from "react-chartjs-2";
import { ThreeDots } from 'react-loader-spinner'
import '../../css/weeksummary.css';


export default class Weeksummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            weeknum: this.props.weeknum,
            dataFetched: false,
            totalkms: '',
            totalactivities: 0,
            noactivities: false,
            kmshares: {'easy': '0%', 'moderate': '0%', 'hard': '0%'},
            chartData: {},
        }
        
    }

    componentDidMount= () => {
        this.fetchData();
    }
    fetchData = () => {
        let url = process.env.REACT_APP_APIBASEURL + '/api/totals?weeknum=' + this.state.weeknum;
        axios.get(url)
            .then((getResponse) => {
                
                let respData = getResponse.data;

                if (respData.length === 0) {
                    this.setState({ noactivities: true });
                    return;
                }
                let data = respData[0];
                
                let labels = [];
                let values = [];

                labels.push('Total kms');
                values.push(data.kmsTotal);
                labels.push('Easy kms');
                values.push(data.kmsEasy);
                labels.push('Moderate kms');
                values.push(data.kmsModerate);
                labels.push('Hard kms');
                values.push(data.kmsHard);

                this.setState({
                    "totalkms": data.display_kmstotal,
                    "totalactivities": data.activitycount,
                    "kmshares": {
                        "easy": data.display_shareeasy,
                        "moderate": data.display_sharemoderate,
                        "hard": data.display_sharehard,
                    }
                });

                let chartdata = {
                    labels: [...labels],
                    datasets: [{
                        label: 'Totals',
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255,99,132,1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1,
                        hoverBackgroundColor: 'rgba(200, 200, 200, 1)',
                        hoverBorderColor: 'rgba(200, 200, 200, 1)',
                        data: [...values]
                    }]
                };

                let chartOptions = {
                    plugins: {
                        datalabels: {
                            display: true,
                            color: "grey",
                            formatter: Math.round,
                            render: "percentage",
                            anchor: "end",
                            offset: 20,
                            align: "start"
                        }
                    },
                    legend: {
                        display: false
                    }
                };
                this.setState({ dataFetched: true, chartData: chartdata, chartOptions: chartOptions, noactivities: false });
            }
            ).catch((error) => {
                // Note: a 404 idicates the totals record does not exist
                if (error.response.status == 404) {
                    this.setState({ noactivities: true });
                }
                else {
                    console.error('ERROR retrieving weeksummary data');
                    console.error(error);
                }
            });
    }
        

    render = () => {
        if (this.state.dataFetched && !this.state.noactivities) {
            return (
                <main>
                    <div><h4>Summary for week {this.state.weeknum}</h4></div>
                    <hr/>
                    <div id="summaryKmsAndActivityCount"><span id="summaryTotalKms">Total kms:</span> {this.state.totalkms} <span id="summaryActivityCount">Activities:</span> {this.state.totalactivities}</div>
                    <div>
                        <strong>Easy kms: </strong> {this.state.kmshares['easy']}
                         <strong> Moderate kms: </strong> {this.state.kmshares['moderate']}
                         <strong> Hard kms: </strong> {this.state.kmshares['hard']}
                    </div>
                    <hr/>
                    
                    <div><Bar data={this.state.chartData} plugins={[ChartDataLabels]} options={this.state.chartOptions}/></div>
                </main>);
            }
        else if (this.state.noactivities) {
            return (<div id="noActivitiesThisWeek">No activities yet this week, get out and run!</div>);
        }
        else {
            return (<div className="dotWrapper"><ThreeDots color="hotpink" height={40} width={40} /></div>);
        }
    }
    
}