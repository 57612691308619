import React, { Component } from "react";
import { withRouter } from "react-router-dom";  
import { connect } from "react-redux";          
import PropTypes from "prop-types";             
import { Link } from "react-router-dom";
import { Container, Button, Row, Col, Form } from "react-bootstrap";
import { login } from "./LoginActions.js";
import axios from "axios";
import { setAxiosAuthToken, toastOnError } from "../../utils/Utils";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: ""
        };
    }
    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    onLoginClick = () => {
        const userData = {
            username: this.state.username,
            password: this.state.password
        };
        // If populated, retrieve the page to redirect to from the querystring
        let toPage = new URLSearchParams(window.location.search).get('next');
        if (toPage == null) {
            toPage = process.env.REACT_APP_LOGINSUCCESSURL;
        }
        this.props.login(userData, toPage); 
    };

    render() {
        return (
            <Container>
                <Row>
                    <Col md="4">
                        <h1>Login</h1>
                        <Form>
                            <Form.Group controlId="usernameId">
                                <Form.Label>Username</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="username"
                                    placeholder="Enter user name"
                                    value={this.state.username}
                                    onChange={this.onChange}
                                />
                                <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="passwordId">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="password"
                                    placeholder="Enter password"
                                    value={this.state.password}
                                    onChange={this.onChange}
                                />
                                <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                            </Form.Group>
                        </Form>

                        <br />

                        <Button color="primary" onClick={this.onLoginClick}>
                            Login
                        </Button>
                        {/*
                        <p className="mt-2">
                            Don't have account? <Link to="/signup">Signup</Link>
                        </p>*/}
                    </Col>
                </Row>
            </Container>
        );
    }
}

// connect action and store and component
Login.propTypes = {
    login: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, {
    login
})(withRouter(Login));