import React  from "react";
import Modal from 'react-bootstrap/Modal'
import Fileupload from "../../components/utils/Fileupload";

export default function ModalFitUpload(props) {

    return (
        <Modal
            show='true'
            onHide={() => props.callback(false)}
            dialogClassName="modal-90w"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Upload FIT
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Fileupload type="fit" />
                </div>
            </Modal.Body>
        </Modal>
    );
}
