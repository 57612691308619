import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Weeksummary from "../../components/dataviews/Weeksummary";
import WeekTotalKms from "../../components/dataviews/WeekTotalKms";
import EfficiencyGraph from "../../components/dataviews/EfficiencyGraph";
import PowerVsSpeed from "../../components/dataviews/PowerVsSpeed";
import { getWeekNum, getWeekYear } from '../../utils/Utils';
import { logout } from "../../components/login/LoginActions";
import NavigationBar from "../../components/nav/NavigationBar";

class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            weeknumfetched: false,
            weeknum: 200001,
        };
    }

    onLogout = () => {
        this.props.logout();
    };

    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let weeknum = params.get('weeknum');
        if (weeknum != null) {
            this.setState({ weeknum: weeknum });
        }
        else {
            let year = getWeekYear(new Date());
            let week = getWeekNum(new Date());
            weeknum = year.toString() + week.toString();
            this.setState({ weeknum: weeknum });
        }
        this.setState({ weeknumfetched: true });
    }

    render() {
        //const { user } = this.props.auth;
        return (
            <main>

                <NavigationBar />
                <div className="pageBody">
                    <div className="row m-1">
                        <div className="col-md-12 col-sm-12 p-1">
                            <div className="card p-2" id="pageheaderbar">
                                <h5 className="p1">Dashboard</h5>
                            </div>
                        </div>
                    </div>

                    <div className="row m-1">
                        <div className="col-md-6 col-sm-6 p-1">
                            <div className="card p-2">
                                {this.state.weeknumfetched ? (
                                    <Weeksummary weeknum={this.state.weeknum} />
                                ) : null}
                            </div>
                        </div>

                        <div className="col-md-6 col-sm-6 mx-auto p-1">
                            <div className="card p-2">
                                {this.state.weeknumfetched ? (
                                    <PowerVsSpeed weekfilter_start={this.state.weeknum} weekfilter_end={this.state.weeknum} />
                                ) : null}
                            </div>
                        </div>
                    </div>

                    <div className="row m-1">
                        <div className="col-md-12 col-sm-12 mx-auto p-1">
                            <div className="card p-2">

                                {this.state.weeknumfetched ? (
                                    <EfficiencyGraph weekfilter_start={new Date().getFullYear() + '01'} weekfilter_end={this.state.weeknum} />
                                ) : null}
                            </div>
                        </div>
                    </div>

                    <div className="row m-1">
                        <div className="col-md-12 col-sm-12 mx-auto p-1">
                            <div className="card p-2">

                                {this.state.weeknumfetched ? (
                                    <WeekTotalKms weekfilter_start={new Date().getFullYear() + '01'} weekfilter_end={this.state.weeknum} />
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        );
    }
}

Dashboard.propTypes = {
    logout: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, {
    logout
})(withRouter(Dashboard));