import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Bar } from "react-chartjs-2";
import { ThreeDots } from 'react-loader-spinner'
import { asPercentage } from '../../utils/Utils';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default function PaceZoneTable(parentprops) {
    const [weekfilter_start, setWeekFilterStart] = useState(parentprops.weekfilter_start);
    const [weekfilter_end, setWeekFilterEnd] = useState(parentprops.weekfilter_end);
    const [activityId, setActivityId] = useState(parentprops.activityId);
    const [rawData, setRawData] = useState({});
    const [rawDataFetched, setRawDataFetched] = useState(false);
    const [chartData, setChartData] = useState({});
    const [chartDataFetched, setChartDataFetched] = useState(false);
    const [chartOptions, setChartOptions] = useState({});

    // NOTE the empty dependency array [] at the end of useEffect. This will prevent useEffect from
    // being called ad infinitum, when state refreshes are done
    useEffect(() => {
        fetchData(activityId);
    }, []); // includes empty dependency array here to prevent infinite loop of calls

    const fetchData = (activityId) => {
        setRawDataFetched(false);

        let url = process.env.REACT_APP_APIBASEURL + '/api/activity/timeinzones/' + activityId;

        axios.get(url)
            .then((getResponse) => {
                let data = getResponse.data;
                setRawData(data);
                setRawDataFetched(true);
                updateChart(data);
            }
            ).catch((error) => {
                console.error('ERROR retrieving pacezone data');
                console.error(error);
            });
    }

    const updateChart = (data) => {
        setChartDataFetched(false);
        let labels = ['Easy', 'Moderate', 'Hard'];
        let values = [];

        // Extract the efficiency data
        let dict = data[1];
        values[0] = dict['paceZoneEasyShare'];
        values[1] = dict['paceZoneModerateShare'];
        values[2] = dict['paceZoneHardShare'];
        
        const horBarData = {
            labels: labels,
            
            datasets: [
                {
                    data: values,
                    axis: 'y',
                    label: 'Duration per zone',
                    backgroundColor: [
                        'rgba(140, 191, 111, 0.2)',
                        'rgba(252, 186, 3, 0.2)',
                        'rgba(255, 0, 43, 0.2)',

                    ],
                    borderColor: [
                        'rgba(140, 191, 111,1)',
                        'rgba(252, 186, 3, 1)',
                        'rgba(255, 0, 43, 1)',

                    ],
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(200, 200, 200, 1)',
                    hoverBorderColor: 'rgba(200, 200, 200, 1)',
                    hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                    hoverBorderColor: 'rgba(255,99,132,1)',

                    
                },
            ]
        };
        

        let chartOptions = {
            indexAxis: 'y',
            maintainAspectRatio: true,
            responsive: true,
            scaleShowValues: true,
            scales: {
                yAxes: {
                    ticks: {
                        autoSkip: false
                    }
                }
            },
            plugins: {
                title: { display: true, text: 'Share in zone (%)', font: { size: 14, } },
                legend: { display: false, position: 'right' },
                datalabels: {
                    display: true,
                    font: { size: 12, },
                    color: "grey",
                    formatter: asPercentage,
                    render: "percentage",
                    anchor: "end",
                    offset: 5,
                    align: "start"
                }
            },
            legend: {
                display: false
            }
        };

        setChartOptions(chartOptions);
        setChartData(horBarData);
        setChartDataFetched(true);

    }

    return (
        <main>
            <hr />
            {
                chartDataFetched ?
                    (<div><Bar data={chartData} options={chartOptions} plugins={[ChartDataLabels]} /></div>)
                    :
                    (<div><ThreeDots color="#00BFFF" height={40} width={40} /></div>)
            }
        </main >);
}

