import React, { useState, useEffect } from 'react';
import { Input, FormGroup } from 'reactstrap';
import axios from "axios";
import ReactiveButton from 'reactive-button'; // See: https://arifszn.com/reactive-button/docs/usage
import NavigationBar from "../../components/nav/NavigationBar";
import { toast } from "react-toastify";
import useBreakpoints from "../../utils/useBreakpoints";
import ModalAvatarUpload from "../../views/app/ModalAvatarUpload";
import { ThreeDots } from 'react-loader-spinner';
import '../../css/appsettings.css';

export default function AppSettings(props) {
    const [fileName, setFileName] = useState('');
    const [file, setFile] = useState(null);
    const [invalidFile, setInvalidFile] = useState(false);
    const [processing, setProcesing] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [readingTotals, setReadingTotals] = useState(false);
    const [processingPowerzones, setProcessingPowerzones] = useState(false);
    const [processingPacezones, setProcessingPacezones] = useState(false);
    const [gettingTitles, setGettingTitles] = useState(false);
    const [buttonEnabled, setButtonEnabled] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [successText, setSuccessText] = useState('');
    const [reApplySuccessText, setReApplySuccessText] = useState('');
    const [modalAvatarUpload, setModalAvatarUpload] = useState(false);
    const [isAsyncCall, setIsAsyncCall] = useState(false);
    const [processingFits, setProcessingFits] = useState(false);
    const [fullImporting, setFullImporting] = useState(false);
    const [testingGarminCreds, setTestingGarminCreds] = useState(false);
    const [garminCredsValid, setGarminCredsValid] = useState(true);
    const [downloadingFiles, setDownloadingFiles] = useState(false);
    const [checkboxRefreshAllActivitiesChecked, setCheckboxRefreshAllActivitiesChecked] = useState(false);

    // Helper function to make use of breakpoints and set the contents of the responsive menu based on those
    const point = useBreakpoints();

    // NOTE the empty dependency array [] at the end of useEffect. This will prevent useEffect from
    // being called ad infinitum, when state refreshes are done
    useEffect(() => {
        // If the environment is not DEV, we are on a Linux environment and can do async processing on the backend
        if (process.env.REACT_APP_ENVASYNC === 'true') {
            setIsAsyncCall(true);
        }
    }, []); // includes empty dependency array here to prevent infinite loop of calls

    // Toggle function for the togglestate of the avatar upload modal window
    // Passsed to the upload modal as callback, to control the togglestate from there
    const toggleAvatarUpload = () => {
        setModalAvatarUpload(!modalAvatarUpload);
    };

    const handleFitFileChange = ({ target: { files } }) => {
        setSuccessText('');
        setErrorText('');
        document.getElementById('uploadErrorTextAppSettings').innerHTML = '';

        if (!files.length) return;

        const [{ size, name }] = files;
        var err = '';

        // Test the file is not too large
        if (size > process.env.REACT_APP_MAX_FILESIZE_FIT_UPLOAD) {
            err = 'File too large. Max size=' + process.env.REACT_APP_MAX_FILESIZE_FIT_UPLOAD / 1000 + 'kB';
        }

        // Test that the file has a .fit or .FIT extension and has only one single '.' in it
        // See https://regex101.com/ for explanation of the regex
        let matches = name.match(/(\b(\.fit)\b$(?<!\.|_)){1}|(^(?!(?:[^\.]*\.){2}))/ig);
        if (matches == null || matches.length !== 2) {
            err = 'Please choose a valid .FIT or .fit file.';
        }

        // Test that the uploaded file has a filename consisting of URL safe (alphanumeric) chars, no spaces.
        // NOTE: hyphens (-), underscores (_) and full stops (.) SHOULD be allowed as these are all part of the
        // standard Garmin FIT file naming, which is xxxxxxxxxx_ACTIVITY.FIT where xxxxxxxxxx is the activity ID
        // See https://regex101.com/ for explanation of the regex
        if (name.search(/^(?!\.|_)[\w.-]*$(?<!\.|_)/gi) < 0) {
            err = 'Please use a FIT file name containing only alphanumeric characters. Dashes (-), underscores (_) and dots (.) are also ok. \nHowever, no leading \'-\', \'.\' or \'_\' or special characters (e.g. spaces, (semi)colons, comma\'s, apostrophes, pound signs, dollar signs etc.) elsewhere in the filename can be processed.';
        }

        if (err !== '') {
            setInvalidFile(true);
            //toast.error(err);
            setErrorText(err);
            setProcesing(false);
            setButtonEnabled(false);
            return;
        }

        // No errors, all checks passed, process the file
        setErrorText('');
        setFileName(name);
        setInvalidFile(false);
        setButtonEnabled(true);
        setFile(files[0]);
    }

    const doUploadPost = () => {
        setSuccessText('');

        if (file == null) return;
        if (file.size > process.env.REACT_APP_MAX_FILESIZE_FIT_UPLOAD) {
            let err = 'File too large. Max size=' + process.env.REACT_APP_MAX_FILESIZE_FIT_UPLOAD / 1000 + 'kB';
            console.error(err);
            //toast.error(err);
            setErrorText(err);
            setUploading(false);
            return;
        }
        if (file.name.indexOf('.fit') + file.name.indexOf('.FIT') <= 0) {
            let err = 'Please choose a valid FIT file';
            console.error(err);
            toast.error(err);
            setUploading(false);
            return;
        }

        const formData = new FormData();
        formData.append(process.env.REACT_APP_UPLOADFILE_FIELDNAME, file);

        // Append field specifying the filetype being uploaded
        formData.append('uploadfiletype', 'fit');

        const url = process.env.REACT_APP_UPLOADURL;
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            }
        }

        // Call the API to process the uploaded file
        axios.post(url, formData, config)
            .then((response) => {
                setUploading(false);
                // Show success message for uploaded file
                setSuccessText("Successfully uploaded. Note: not yet processed.")

            })
            .catch((error) => {
                console.error(error);
            });
    }

    const doProcessGet = (url) => {
        axios.get(url)
            .then((getResponse) => {
                console.log('Processed. Result:');
                console.log(getResponse);
                setProcesing(false);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const doInitiateStepProcessor = (url) => {
        axios.get(url)
            .then((getResponse) => {
                console.log('Processed. Result:');
                console.log(getResponse);
                //setFullImporting(false);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const doTotalsGet = (url) => {
        // Call the API to process the totals for all files
        axios.get(url)
            .then((getResponse) => {
                setReadingTotals(false);

                console.log("processed totals. Response:");
                console.log(getResponse);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const doProcessPowerZones = (url) => {

        // Call the API to process the power zones
        axios.get(url)
            .then((getResponse) => {
                let jobId = getResponse.data["job_id"];
                let mode = getResponse.data["mode"];
                if (mode === 'synchronous') {
                    // A synchronous call was made. 
                    // Once the response returns, the process is done
                    // In the mean time, no status updates can be retrieved
                    setReApplySuccessText("Successfully applied zone values to all activities.");
                    setProcessingPowerzones(false);

                    // Re-run the update totals call
                    axios.get(process.env.REACT_APP_TOTALSURL).catch((error) => {
                        console.error(error);
                    });
                }
                else if (mode === 'asynchronous') {
                    setIsAsyncCall(true);
                    pollJobStatus(jobId);
                }
                console.log("processed power zones. Response:");
                console.log(getResponse);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const doProcessPaceZones = (url) => {
        // Call the API to process the power zones
        axios.get(url)
            .then((getResponse) => {
                let jobId = getResponse.data["job_id"];
                let mode = getResponse.data["mode"];
                //console.log('job=', jobId);
                if (jobId == null) {
                    console.error('Job Id not defined');
                }
                if (mode === 'synchronous') {
                    // A synchronous call was made. 
                    // Once the response returns, the process is done
                    // In the mean time, no status updates can be retrieved
                    setReApplySuccessText("Successfully applied zone values to all activities.");
                    setProcessingPacezones(false);

                    // Re-run the update totals call
                    axios.get(process.env.REACT_APP_TOTALSURL).catch((error) => {
                        console.error(error);
                    });
                }
                else if (mode === 'asynchronous') {
                    setIsAsyncCall(true);
                    pollJobStatus(jobId);
                }
                console.log("processed pace zones. Response:");
                console.log(getResponse);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const saveGarminCredentials = async () => {
        setTestingGarminCreds(true);
        document.getElementById('resultCredentialTest').innerHTML = "";
        let vals = {};
        let garminUserName = document.getElementById('garminusername').value;
        let garminPassword = document.getElementById('garminpassword').value;
        vals['garminusername'] = garminUserName;
        vals['garminpassword'] = garminPassword;
        let usr = JSON.parse(localStorage.getItem("user"));
        let upid = usr['upid'];
        let response =
            await axios
                .patch("/api/userprofiles/" + upid + "/", vals)
                .then(
                    // Test whether these new credentials are valid for login
                    axios.get(process.env.REACT_APP_TESTGARMINCREDS)
                        .then((response) => {
                            let res = response.data;
                            if (process.env.REACT_APP_ENVNAME === 'DEV') {
                                console.log('cred test result:', res);
                            }
                            if (res["validcreds"]) {
                                document.getElementById('resultCredentialTest').innerHTML = 'Garmin login credentials correct!';
                                document.getElementById('resultCredentialTest').className = 'resGarminCredTestCorrect';
                            }
                            else {
                                document.getElementById('resultCredentialTest').innerHTML = 'Garmin login credentials incorrect!';
                                document.getElementById('resultCredentialTest').className = 'resGarminCredTestWrong';
                            }
                            setTestingGarminCreds(false)
                        })
                )
                .catch(error => { console.error(error); });
    }

    const closeGarminInvalidCredsPopup = () => {
        setGarminCredsValid(true);
        setProcessingFits(false);
        setFullImporting(false);
    }

    const doGetTitles = () => {
        // Import titles and notes for the activities
        axios.get(process.env.REACT_APP_APPLYGARMINTITLESSURL)
            .then((response) => {
                setGettingTitles(false);
            })
            .catch((error) => {
                console.error(error);
            })
    }

    const doDownloadFiles = () => {
        // Download the JSON and FIT files that are not yet downloaded
        axios.get(process.env.REACT_APP_IMPORTGARMINFITSURL)
            .then((response) => {
                setDownloadingFiles(false);
            })
            .catch((error) => {
                console.error(error);
            })
    }

    const updateModalForFitGetter = (step) => {

        // Hide the progress bar and text until the credentials have been checked
        if (isAsyncCall && step === 0) {
            try {
                document.getElementById('processingDialogProgressText').style.visibility = 'hidden';
                document.getElementById('progressBarOuter').style.visibility = 'hidden';
            }
            catch (e) {
                console.error(e);
            }
        }
        let innerHTML = "";
        switch (step) {
            case 0:
                innerHTML = "Logging into Garmin Connect...";
                break;
            case 1:
                innerHTML = "Step 1/4: Downloading files from Garmin Connect";
                break;
            case 2:
                innerHTML = "Step 2/4: Reading data from FIT files";
                break;
            case 3:
                innerHTML = "Step 3/4: Updating weekly totals";
                break;
            case 4:
                innerHTML = "Step 4/4: Importing activity titles and notes";
                break;
            default:
                innerHTML = "Still working...";
        }
        if (document.getElementById('fitGetterProgress') != null) {
            document.getElementById('fitGetterProgress').innerHTML = innerHTML;
        }
    }

    // To be used in the async call
    const processInSequence = (step, jobId) => {

        // Check that a non-null jobID was passed
        if (jobId === null) {
            console.error('Job Id not defined');
            setProcessingFits(false);
            setFullImporting(false)
            return;
        }
        if (process.env.REACT_APP_ENVNAME === 'DEV') {
            console.log('Polling job ', jobId, ' step: ', step);
        }
        let url = process.env.REACT_APP_JOBSTATUS + jobId;

        axios.get(url)
            .then((response) => {

                let status = response.data["status"];
                let progress = response.data["progress"];
                let numTotal = response.data["numtotal"];
                let numProcessed = response.data["numprocessed"];
                let newWidth = parseInt(numProcessed / numTotal * 100) + '%';

                if (process.env.REACT_APP_ENVNAME === 'DEV') console.log('step: ', step, '|', status, '|', progress, '|', newWidth);

                if (status === 'processing') {
                    // Still processing, write the progress info into the overlay
                    // TODO: step-specific text and progress bar for each step (colours?)
                    if (step > 0) {
                        //console.log('step=', step);
                        //console.log('show progressbar');
                        document.getElementById('processingDialogProgressText').style.visibility = 'visible';
                        document.getElementById('progressBarOuter').style.visibility = 'visible';

                        document.getElementById('processingDialogProgressText').innerHTML = 'Processing... ' + progress;
                        document.getElementById('progressBarInner').setAttribute("style", "width:" + newWidth);
                    }
                    // Recursively call self to re-poll in 100ms
                    setTimeout(processInSequence, 100, step, jobId);
                    return;
                }
                if (status === 'queued') {
                    //console.log('queued');
                    // Recursively call self to re-poll in 100ms
                    setTimeout(processInSequence, 100, step, jobId);
                    document.getElementById('processingDialogProgressText').innerHTML = 'Task queued, please be patient';
                    return;
                }

                // When the job has finished, we can move on to the next step
                if (status === 'finished') {

                    if (step === 1) {
                        // Done with step 1, progress to step 2
                        // Step 2: process imported FIT files
                        axios.get(process.env.REACT_APP_PROCESSURL)
                            .then((response) => {
                                updateModalForFitGetter(2);
                                processInSequence(2, response.data["job_id"]);
                            })
                            .catch((error) => { console.error(error) })
                    }
                    if (step === 2) {
                        // Done with step 2, progress to step 3
                        // Step 3: update weekly totals
                        axios.get(process.env.REACT_APP_TOTALSURL)
                            .then((response) => {
                                updateModalForFitGetter(3);
                                processInSequence(3, response.data["job_id"]);
                            })
                            .catch((error) => { console.error(error) })
                    }
                    if (step === 3) {
                        // Done with step 3, progress to step 4
                        // Step 4: get activity titles and notes
                        axios.get(process.env.REACT_APP_APPLYGARMINTITLESSURL)
                            .then((response) => {
                                updateModalForFitGetter(4);
                                processInSequence(4, response.data["job_id"]);
                            })
                            .catch((error) => { console.error(error) })
                    }

                    if (step === 4) {
                        // Done, close the overlay
                        setProcessingFits(false);
                    }
                    return;
                }


                // Unknown condition, log an error
                console.error('Unable to process job ', jobId, ' and step: ', step);
                console.error('Job status =', status);

            })
            .catch((error) => {
                console.error(error);
            });
    }

    // Sequentially call the four steps of the process to download and import the FIT files:
    // Step 0: verify Garmin login credentials
    // Step 1: download activities (FITs) and metadata (JSON) from Garmin Connect
    // Step 2: process fits 
    // Step 3: update weekly totals
    // Step 4: import activity titles and notes from downloaded metadata files
    const doGetFits = () => {

        // Update the text in the modal dialog: showing initial state, checking credentials
        setTimeout(updateModalForFitGetter, 100, 0);

        // Step 0: test login credentials
        axios.get(process.env.REACT_APP_TESTGARMINCREDS)
            .then((response) => {
                let validcredentials = response.data["validcreds"];
                if (!validcredentials) {
                    console.error('Invalid Garmin credentials');
                    setGarminCredsValid(false);
                }
                else {

                    // Update the text in the modal dialog: showing step-1 state
                    // Display this after 2 seconds
                    setTimeout(updateModalForFitGetter, 6000, 1);

                    let url = process.env.REACT_APP_IMPORTGARMINFITSURL;
                    if (document.getElementById('refreshallfits').checked)
                        url += '?refreshall=true';

                    //Step 1: download activities(FITs) and metadata(JSON) from Garmin Connect
                    axios.get(url)
                        .then((response) => {
                            //console.log(response);

                            let mode = response.data["mode"];

                            // A synchronous call was made. 
                            // Once the response returns, the process for that step is done
                            if (mode === 'synchronous') {

                                // Step 2: process fits. 
                                // Set modal to tell the user this is happening
                                updateModalForFitGetter(2);
                                // Call the URL to do the processing
                                axios.get(process.env.REACT_APP_PROCESSURL)
                                    .then((response) => {

                                        // Step 3: update weekly totals
                                        // Set modal to tell the user this is happening
                                        updateModalForFitGetter(3);
                                        // Call the URL to do the processing
                                        axios.get(process.env.REACT_APP_TOTALSURL)

                                            .then((response) => {

                                                // Step 4: get activity titles and notes
                                                // Set modal to tell the user this is happening
                                                updateModalForFitGetter(4);
                                                // Call the URL to do the processing
                                                axios.get(process.env.REACT_APP_APPLYGARMINTITLESSURL)
                                                    // When this point is reached, all 4 steps have completed
                                                    // and the dialog can be closed
                                                    .then((response) => { setProcessingFits(false); })
                                            })
                                            .catch((error) => { console.error(error) })
                                    }).catch((error) => { console.error(error) });
                            }
                            else if (mode === 'asynchronous') {

                                let jobID = response.data["job_id"];

                                // Kick off the sequential processing of the steps
                                processInSequence(1, jobID)
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
            }
            ).catch((error) => {
                console.error(error);
            });
    }

    // On the frontend, first verify the Garmin creds 
    // Step 0: verify Garmin login credentials
    // The call the backend url to kickoff, the four steps of the process to download and import the FIT files:
    // Step 1: download activities (FITs) and metadata (JSON) from Garmin Connect
    // Step 2: process fits 
    // Step 3: update weekly totals
    // Step 4: import activity titles and notes from downloaded metadata files
    // As the work is done, poll the updater to retrieve the job status and write the status to the frontend
    const doGetFitsFourstep = () => {

        // Update the text in the modal dialog: showing initial state, checking credentials
        setTimeout(updateModalForFitGetter, 100, 0);

        // Step 0: test login credentials
        axios.get(process.env.REACT_APP_TESTGARMINCREDS)
            .then((response) => {
                let validcredentials = response.data["validcreds"];
                if (process.env.REACT_APP_ENVNAME === 'DEV') {
                    console.log("Testing Garmin login credentials");
                    console.log(response);
                }
                if (!validcredentials) {
                    console.error('Invalid Garmin credentials');
                    setGarminCredsValid(false);
                }
                else {

                    // Update the text in the modal dialog: showing step-1 state
                    // Display this after 6 seconds
                    setTimeout(updateModalForFitGetter, 6000, 1);

                    // URL to kickoff the backend processing
                    let url = process.env.REACT_APP_PROCESSURL + '?allsteps=true';
                    if (document.getElementById('refreshallfits').checked)
                        url += '&refreshall=True';
                    // Kickoff the 4-step backend processing
                    //console.log('Initiating step processor'); 
                    doInitiateStepProcessor(url);

                    // A synchronous call was made. 
                    // Once the response returns, the process for that step is done
                    if (!isAsyncCall) {
                        // Todo: see if anything can be done to update on progress when synchronous
                        //console.log('sync. no polling');

                    }
                    else {
                        //console.log('async. starting polling');
                        // Start polling for progress updates
                        pollStatus4Step();
                    }
                }
            }
            ).catch((error) => {
                console.error(error);
            });
    }

    const pollStatus4Step = () => {
        //console.log('pollStatus4Step');
        let url = process.env.REACT_APP_JOBSTATUS + '4step';
        //console.log(url);
        axios.get(url)
            .then((response) => {
                //console.log(response);

                //console.log("Processing pace zones. Response:");
                //console.log(getResponse);
                let status = response.data["status"];
                let step = parseInt(response.data["step"]);
                let progress = response.data["progress"];
                let process_state = response.data["process_state"];
                let last_error = response.data["lasterror"];
                let numTotal = response.data["numtotal"];
                let numProcessed = response.data["numprocessed"];
                let newWidth = parseInt(numProcessed / numTotal * 100) + '%';

                // Set the title of the modal popup to reflect the current step 
                updateModalForFitGetter(step);

                if (status === 'finished' && step === 4) {

                    //console.log('closing');
                    //console.log('status:', status);
                    //console.log('step:', step);

                    // Done, close the overlay;
                    setFullImporting(false);
                }
                // Finished a step, but not yet at step for, then keep polling
                else if (status === 'finished') {
                    // Recursively call self to re-poll in 100ms
                    setTimeout(pollStatus4Step, 100);                    
                }
                // Processing or started, keep polling and display progress
                else if (status === 'processing' || status === 'started') {
                    // Still processing, write the progress info into the overlay
                    if (step > 0) {

                        document.getElementById('processingDialogProgressText').style.visibility = 'visible';
                        document.getElementById('progressBarOuter').style.visibility = 'visible';

                        document.getElementById('processingDialogProgressText').innerHTML = 'Processing... ' + progress;
                        document.getElementById('progressBarInner').setAttribute("style", "width:" + newWidth);
                    }
                    // Recursively call self to re-poll in 100ms
                    setTimeout(pollStatus4Step, 100);
                }
                // Queued or undefined? The job is waiting to be processed, keep polling
                else if (status === 'queued' || status === undefined) {
                    console.log('Still wating on job start. Status=' + status);
                    // Recursively call self to re-poll in 100ms
                    setTimeout(pollStatus4Step, 100);                       
                }
                else {
                    // Unknown status
                    console.error('Unknown status for job. Status=' + status);
                    // Recursively call self to re-poll in 100ms
                    setTimeout(pollStatus4Step, 100);                    
                }

            }
            ).catch((error) => {
                console.error(error);
            });
    }

    const pollJobStatus = (jobId) => {

        document.getElementById('processingDialogProgressText').style.visibility = 'visible';
        document.getElementById('progressBarOuter').style.visibility = 'visible';
        //console.log('Polling job ', jobId);
        let url = process.env.REACT_APP_JOBSTATUS + jobId;
        axios.get(url)
            .then((getResponse) => {

                //console.log("Processing pace zones. Response:");
                //console.log(getResponse);
                let status = getResponse.data["status"];
                let progress = getResponse.data["progress"];
                let numTotal = getResponse.data["numtotal"];
                let numProcessed = getResponse.data["numprocessed"];
                let newWidth = parseInt(numProcessed / numTotal * 100) + '%';

                if (status === 'finished') {
                    // Done, close the overlay
                    setProcessingPowerzones(false);
                    setProcessingPacezones(false);
                    setReApplySuccessText("Successfully applied zone values to all activities.");

                    // Re-run the update totals call
                    axios.get(process.env.REACT_APP_TOTALSURL).catch((error) => {
                        console.error(error);
                    });
                }
                else if (status === 'processing') {
                    // Still processing, write the progress info into the overlay
                    document.getElementById('processingDialogProgressText').innerHTML = 'Laps processed: ' + progress;
                    document.getElementById('progressBarInner').setAttribute("style", "width:" + newWidth);
                    // Recursively call self to re-poll in 1s
                    setTimeout(pollJobStatus, 100, jobId);
                }
                else {
                    // Unknown status
                    console.error('Unable to retrieve job status for job ', jobId)
                }

            })
            .catch((error) => {
                console.error(error);
            });
    }

    const buttonClick = (action, event) => {

        switch (action) {

            case 'upload':
                if (file != null) {
                    setUploading(true);
                    doUploadPost();
                }
                break;
            case 'process':
                setProcesing(true);
                doProcessGet(process.env.REACT_APP_PROCESSURL);
                break;
            case 'processtotals':
                setReadingTotals(true);
                doTotalsGet(process.env.REACT_APP_TOTALSURL);
                break;
            case 'initiatestepprocessor':
                setFullImporting(true);
                doGetFitsFourstep();
                break;
            case 'processpowerzones':
                setReApplySuccessText("");
                setProcessingPowerzones(true);
                doProcessPowerZones(process.env.REACT_APP_PROCESSPOWERZONESSURL);
                break;
            case 'processpacezones':
                setReApplySuccessText("");
                setProcessingPacezones(true);
                doProcessPaceZones(process.env.REACT_APP_PROCESSPACEZONESSURL);
                break;
            case 'downloadfiles':
                setDownloadingFiles(true);
                setGarminCredsValid(true);
                doDownloadFiles();
                break;
            case 'getfits':
                setProcessingFits(true);
                setGarminCredsValid(true);
                doGetFits();
                break;
            case 'gettitles':
                setGettingTitles(true);
                doGetTitles(process.env.REACT_APP_APPLYGARMINTITLESSURL)
                break;
            case 'submitgarmindata':
                saveGarminCredentials();
                break;
            case 'boxchecked':
                // Toggle box
                //document.getElementById('refreshallfits').checked = !document.getElementById('refreshallfits').checked;
                // Toggle state
                setCheckboxRefreshAllActivitiesChecked(!checkboxRefreshAllActivitiesChecked);
                if (document.getElementById('refreshallfits').checked) {
                    document.getElementById('cautionText').className = 'cautionTextRed';
                }
                else {
                    document.getElementById('cautionText').className = 'cautionTextWhite';
                }
                break;
            default:
                break;
        }
    }

    var usr = JSON.parse(localStorage.getItem("user"));
    var avatarImage = () => { return "<></>"; }
    if (usr != null) {
        avatarImage = () => { return (<img className="bigAvatarImage" src={usr["avatar"]} />); }
    }
    return (


        <>
            {modalAvatarUpload ? (<ModalAvatarUpload callback={toggleAvatarUpload} />) : null}
            <NavigationBar />

            <div>
                {(processingPacezones || processingPowerzones || processingFits || testingGarminCreds || fullImporting) ?

                    <div id="overlayPane">
                        <div id="processingDialogModalWindow">
                            {garminCredsValid ?
                                <div id="processingDialogInnerText">
                                    {testingGarminCreds ?
                                        'Testing Garmin login credentials...'
                                        : null}
                                    {(processingPacezones || processingPowerzones) ?
                                        'Updating and applying zone values for all activities....'
                                        : null}
                                    {(processingFits || fullImporting) ?
                                        (
                                            <>
                                                <span>Importing actvities from Garmin Connect&nbsp;&nbsp;</span>
                                                <img src="/GarminLogo.png" height="25px" width="25px" alt="Garmin Connect logo" title="Garmin Connect logo" />
                                                <br /><br />
                                                <span id="fitGetterProgress">Progress...</span>
                                                <br />
                                            </>
                                        ) : null}
                                    {!testingGarminCreds && isAsyncCall ?
                                        <>
                                            <br />
                                            <div id="processingDialogProgressText"></div>
                                            <br />
                                            <div id="progressBarOuter"><div id="progressBarInner"></div></div>
                                        </> : null}
                                    {(!isAsyncCall || testingGarminCreds) ?

                                        <div id="spinnerDiv">
                                            <ThreeDots color="hotpink" height={40} width={40} />
                                        </div>
                                        : null
                                    }
                                </div>
                                :
                                <div id="processingDialogInnerText">
                                    <div id="modalInvalidGarminCredsCloseWrapper">
                                        <span id="modalInvalidGarminCredsCloseButton" onClick={(e) => closeGarminInvalidCredsPopup()}>X</span>
                                    </div>
                                    <div id="errorTextInvalidGarminCreds">
                                        Invalid Garmin credentials. Please check the username and password of your Garmin Connect account.
                                        Save these before importing your activities.
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                    : null}
            </div>

            <div className="pageBody">

                <div className="row m-1">
                    <div className="col-md-12 col-sm-12 p-1">
                        <div className="card p-2" id="pageheaderbar">
                            <h5 className="p1">Settings, admin and batch processing</h5>
                        </div>
                    </div>
                </div>

                <div className="row m-1">
                    <div className="col-md-6 col-sm-6 p-1">
                        <div className="card p-2 bgLight">
                            <div id="garminheaderbar">
                                <div id="garminsectiontitle">Garmin settings and import</div>
                                <div id="garminlogobox"><img id="garminLogo" src="/GarminLogo.png" height="30px" width="30px" alt="Garmin Connect logo" title="Garmin Connect logo" /></div>
                            </div>
                            <hr />
                            <div className="logobox">
                                Garmin username:&nbsp;
                                <input type="text" id="garminusername" className="garminInputField" /><br />
                                Garmin password:&nbsp;
                                <input type="password" id="garminpassword" className="garminInputField" /><br />
                                <div id="resultCredentialTest" className="resGarminCredTest"></div>
                                <button id="submitButtonGarminData" className="btnStandard btnLargeFont" onClick={(e) => buttonClick('submitgarmindata', e)}>Save</button>
                                <br /><br />
                                <input type="checkbox" id="refreshallfits" onClick={(e) => buttonClick('boxchecked', e)} /> <span id="labelRefreshAllFits" onClick={(e) => buttonClick('boxchecked', e)}>Fresh import of all FIT files</span>
                                <br /><span id="cautionText" className="cautionTextWhite">
                                    Note: with the box above checked, a full import of all activities is triggered.
                                    Processing speed is around 100 activities per minute. Please be patient. :-)</span>
                                <br /><br />
                                <button className="btnStandard btnWide btnLargeFont" onClick={(e) => buttonClick('initiatestepprocessor', e)}>Import activities</button>
                            </div>
                        </div>

                    </div>

                    <div className="col-md-6 col-sm-6 p-1">


                        <div className="card p-2 bgLight">
                            <h4>User settings</h4>
                            <hr />
                            <div className="avatarBox">
                                <div className="avatarHeader">Profile picture</div>
                                {avatarImage()}
                                <br />
                                <a href="#" className="avatarChangeLink" onClick={(e) => toggleAvatarUpload()}>Change</a>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row m-1">
                    <div className="col-md-6 col-sm-6 p-1">
                        <div className="card p-2 bgLight">
                            <h4>FIT file manual upload and processing</h4>
                            <hr />
                            <div>
                                <FormGroup>
                                    <Input
                                        style={{ 'height': '45px', 'color': 'black', 'paddingTop': '10px', 'paddingLeft': '25px', }}
                                        type="file"
                                        accept=".fit,.FIT"
                                        id="uploadFileBrowser"
                                        name="customFile"
                                        label={fileName || 'choose FIT file'}
                                        onChange={handleFitFileChange}
                                        invalid={invalidFile} />
                                </FormGroup>
                                <div id="uploadErrorTextAppSettings" className="uploadErrorText">{errorText}</div>
                                <div id="uploadSuccessTextAppSettings" className="uploadSuccessText">{successText}</div>
                            </div>

                            <div className="buttonContainer">

                                <div className="buttonBox">
                                    <ReactiveButton
                                        id="button_upload"
                                        buttonState={uploading ? 'loading' : 'idle'}
                                        disabled={!buttonEnabled}
                                        errorText={errorText}
                                        idleText={'Upload FIT'}
                                        loadingText={'Uploading...'}
                                        successText={'Done!'}
                                        messageDuration={3000}
                                        className="appSettingsReactiveButton"
                                        animation={true}
                                        size="medium"
                                        onClick={(e) => buttonClick('upload', e)}
                                    />
                                </div>

                                <div className="buttonBox">
                                    <ReactiveButton
                                        id="button_process"
                                        buttonState={processing ? 'loading' : 'idle'}
                                        idleText={'Process uploads'}
                                        loadingText={'Processing...'}
                                        successText={'Done!'}
                                        messageDuration={3000}
                                        className="appSettingsReactiveButton"
                                        animation={true}
                                        size="medium"
                                        onClick={(e) => buttonClick('process', e)}
                                    />
                                </div>

                                <div className="buttonBox">
                                    <ReactiveButton
                                        id="button_processtotals"
                                        buttonState={readingTotals ? 'loading' : 'idle'}
                                        idleText={'Recalculate Totals'}
                                        loadingText={'Recalculating Totals...'}
                                        successText={'Done!'}
                                        messageDuration={3000}
                                        className="appSettingsReactiveButton"
                                        animation={true}
                                        size="medium"
                                        onClick={(e) => buttonClick('processtotals', e)}
                                    />
                                </div>

                                <div className="buttonBox">
                                    <ReactiveButton
                                        id="button_download_files"
                                        buttonState={downloadingFiles ? 'loading' : 'idle'}
                                        idleText={'Download files '}
                                        loadingText={'Downloading files...'}
                                        successText={'Done!'}
                                        messageDuration={3000}
                                        className="appSettingsReactiveButton"
                                        animation={true}
                                        size="medium"
                                        onClick={(e) => buttonClick('downloadfiles', e)}
                                    />
                                </div>

                                <div className="buttonBox">
                                    <ReactiveButton
                                        id="button_import_titles"
                                        buttonState={gettingTitles ? 'loading' : 'idle'}
                                        idleText={'Import titles'}
                                        loadingText={'Importing titles...'}
                                        successText={'Done!'}
                                        messageDuration={3000}
                                        className="appSettingsReactiveButton"
                                        animation={true}
                                        size="medium"
                                        onClick={(e) => buttonClick('gettitles', e)}
                                    />
                                </div>

                                <div className="buttonBox">
                                    <ReactiveButton
                                        id="button_initiateprocessor"
                                        buttonState={fullImporting ? 'loading' : 'idle'}
                                        idleText={'4-step import'}
                                        loadingText={'Importing 4-step...'}
                                        successText={'Done!'}
                                        messageDuration={3000}
                                        className="appSettingsReactiveButton"
                                        animation={true}
                                        size="medium"
                                        onClick={(e) => buttonClick('initiatestepprocessor', e)}
                                    />
                                </div>


                                
                                <br /><br />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 col-sm-6 p-1">
                        <div className="card p-2 bgLight">
                            <h4>Power and Pace zones manual processing</h4><hr />
                            Note: these are DB intensive operations that may require several minutes to process. Please be patient if you click one of the buttons below.
                            <br /><br />
                            <div className="buttonContainer">

                                <div className="buttonBox" id="buttonBoxProcessPowerZones">
                                <ReactiveButton
                                    id="button_processpowerzones"
                                    buttonState={processingPowerzones ? 'loading' : 'idle'}
                                    idleText={'Re-apply Power Zones'}
                                    loadingText={'Re-applying Power Zones...'}
                                    successText={'Done!'}
                                    messageDuration={3000}
                                    className="appSettingsReactiveButton"
                                    animation={true}
                                    size="medium"
                                    onClick={(e) => buttonClick('processpowerzones', e)}
                                    />
                                </div>

                                <div className="buttonBox" id="buttonBoxProcessPaceZones">
                                <ReactiveButton
                                    id="button_processpacezones"
                                    buttonState={processingPacezones ? 'loading' : 'idle'}
                                    idleText={'Re-apply Pace Zones'}
                                    loadingText={'Re-applying Pace Zones...'}
                                    successText={'Done!'}
                                    messageDuration={3000}
                                    className="appSettingsReactiveButton"
                                    animation={true}
                                    size="medium"
                                    onClick={(e) => buttonClick('processpacezones', e)}
                                    />
                                    </div>
                                <br /><br />
                            </div>
                            <div id="reApplySuccessText">{reApplySuccessText}</div>
                        </div>
                    </div>
                </div>                

                <div className="row m-1">
                    <div className="col-md-12 col-sm-12 p-1">
                        <div className="envTagAppSettings">{process.env.REACT_APP_ENVNAME} {process.env.npm_package_name} V{process.env.REACT_APP_VERSION}</div>
                    </div>
                </div>

            </div>

        </>

    );
}



        
    
