
export default function ActivityDataSummary(props) {

    return (
        <div className="row m-1 px-2">

            <div className="col-md-2 col-sm-2 p-1">
                <div>
                    <div id="activityMainDataVal">{props.data["display_distance"]}</div>
                    <div id="activityMainDataLabel">Distance</div>
                </div>
            </div>

            <div className="col-md-2 col-sm-2 p-1">
                <div>
                    <div id="activityMainDataVal">{props.data["display_duration"]}</div>
                    <div id="activityMainDataLabel">Duration</div>
                </div>
            </div>

            <div className="col-md-2 col-sm-2 p-1">
                <div>
                    <div id="activityMainDataVal">{props.data["lapcount"]}</div>
                    <div id="activityMainDataLabel">Laps</div>
                </div>
            </div>

            <div className="col-md-2 col-sm-2 p-1">
                <div>
                    <div id="activityMainDataVal">{props.data["avg_pace"]}</div>
                    <div id="activityMainDataLabel">Avg pace</div>
                </div>
            </div>
            {(props.data["avg_power"] != '0 W') ? (
                <div className="col-md-2 col-sm-2 p-1">
                    <div>
                        <div id="activityMainDataVal">{props.data["avg_power"]}</div>
                        <div id="activityMainDataLabel">Avg power</div>
                    </div>
                </div>
            ) : null}

            <div className="col-md-2 col-sm-2 p-1">
                <div>
                    <div id="activityMainDataVal">{props.data["display_kilojoules"]}</div>
                    <div id="activityMainDataLabel">Work</div>
                </div>
            </div>


        </div>

    );

}