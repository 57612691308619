import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Bar } from "react-chartjs-2";
import { ThreeDots } from 'react-loader-spinner'
import { asPercentage } from '../../utils/Utils';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import TextAnimate from '../../utils/TextAnimate';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip,Legend, } from 'chart.js';

export default function PowerZoneTable(parentprops) {
    const [weekfilter_start, setWeekFilterStart] = useState(parentprops.weekfilter_start);
    const [weekfilter_end, setWeekFilterEnd] = useState(parentprops.weekfilter_end);
    const [activityId, setActivityId] = useState(parentprops.activityId);
    const [rawData, setRawData] = useState({});
    const [rawDataFetched, setRawDataFetched] = useState(false);
    const [chartData, setChartData] = useState({});
    const [chartDataFetched, setChartDataFetched] = useState(false);
    const [chartOptions, setChartOptions] = useState({});

    // NOTE the empty dependency array [] at the end of useEffect. This will prevent useEffect from
    // being called ad infinitum, when state refreshes are done
    useEffect(() => {
        fetchData(activityId);
    }, []); // includes empty dependency array here to prevent infinite loop of calls

    const fetchData = (activityId) => {
        setRawDataFetched(false);

        let url = process.env.REACT_APP_APIBASEURL + '/api/activity/timeinzones/' + activityId;

        axios.get(url)
            .then((getResponse) => {
                let data = getResponse.data;
                setRawData(data);
                setRawDataFetched(true);
                updateChart(data);
            }
            ).catch((error) => {
                console.error('ERROR retrieving powerzone data');
                console.error(error);
            });
    }

    const updateChart = (data) => {
        setChartDataFetched(false);
        let labels = ['P1', 'P2', 'P3', 'P4', 'P5', 'P6', 'P7'];
        let values = [];

        // Extract the efficiency data
        let dict = data[0];
        let counter = 1;
        for (let i = 1; i < 8; i++) {
            let key = 'PowerZone' + i + 'Share';
            values[i - 1] = dict[key];
        }

        
        const horBarData = {
            labels: labels,
            
            datasets: [
                {
                    data: values,
                    axis: 'y',
                    label: 'Duration per zone',
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                        'rgba(255, 99, 132, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255,99,132,1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                        'rgba(255,99,132,1)',
                    ],
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(200, 200, 200, 1)',
                    hoverBorderColor: 'rgba(200, 200, 200, 1)',
                    hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                    hoverBorderColor: 'rgba(255,99,132,1)',

                    
                },
            ]
        };
        

        let chartOptions = {
            indexAxis: 'y',
            maintainAspectRatio: true,
            responsive: true,
            scaleShowValues: true,
            scales: {
                yAxes: {
                    ticks: {
                        autoSkip: false
                    }
                }
            },
            plugins: {
                title: { display: true, text: 'Share in zone (%)', font: { size: 14, } },
                legend: { display: false, position: 'right' },
                datalabels: {
                    display: true,
                    font: { size: 12, },
                    color: "grey",
                    formatter: asPercentage,
                    render: "percentage",
                    anchor: "end",
                    offset: 5,
                    align: "start"
                }
            },
            legend: {
                display: false
            }
        };

        setChartOptions(chartOptions);
        setChartData(horBarData);
        setChartDataFetched(true);

    }

    return (
        <main>
            <hr />
            {
                chartDataFetched ?
                    (<div><Bar data={chartData} options={chartOptions} plugins={[ChartDataLabels]} /></div>)
                    :
                    (<div className="dotWrapper"><ThreeDots color="hotpink" height={40} width={40} /></div>)
            }
        </main >);
}

