    import React, { useState, useEffect } from "react";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { toastOnError, capitalizeFirst } from "../../utils/Utils";
import ConfirmDelete from "../../components/utils/ConfirmDelete";
import { ThreeDots } from 'react-loader-spinner';
import '../../css/datatable.css';


export default function PaceZonesTable(parentprops) {
    const [dataFetched, setfetched] = useState(false);
    const [colHeaders, setColHeaders] = useState(null);
    const [data, setData] = useState(null);
    const [url, setUrl] = useState(process.env.REACT_APP_APIBASEURL + '/api/pacezones/');
    const [toggleTriggerDeleteModal, setToggleTriggerDeleteModal] = useState(false);
    const [zoneToDelete, setZoneToDelete] = useState(null);
    const [fieldErrors, setFieldErrors] = useState(false);
    const [processingPaceZonesForActivities, setProcessingPaceZonesForActivities] = useState(false);
    const [currentZoneProcessing, setCurrentZoneProcessing] = useState(null);
    // Needed to control the visibility if the Datepicker boxes
    const [startDate, setStartDate] = useState(new Date());
    const [newZoneDate, setNewZoneDate] = useState(new Date());
    const [zoneDates, setZoneDates] = useState({});
    const [isAsyncCall, setIsAsyncCall] = useState(false);


    // NOTE the empty dependency array [] at the end of useEffect. This will prevent useEffect from
    // being called ad infinitum, when state refreshes are done
    useEffect(() => {
        fetchData(url);
    }, []); // includes empty dependency array here to prevent infinite loop of calls

    const fetchData = (url) => {
        setfetched(false);

        axios.get(url)
            .then((getResponse) => {
                // Data comes in with HH:MM:SS time format, form takes MM:SS
                // Strip off the hours
                stripHours(getResponse.data);
                // Fill the state dict with dates, to be used in the Datepicker component
                populateZoneDates(getResponse.data);
                setData(getResponse.data);
            })
            .then(
                axios.get("/api/colheaders")
                    .then((getResponse) => {
                        let headers = getResponse.data['PaceZone']
                        setColHeaders(headers);
                        setfetched(true);
                    })
            )
            .catch((error) => {
                console.error('ERROR retrieving data');
                console.error(error);
            });
    }

    // The pacezone data for the pace zones is stored in the DB as a time field
    // as HH:MM:SS. 
    // The form expects data in a mm:ss format
    // The stripHours function removes the leading hours
    const stripHours = (dataDict) => {
        if (dataDict === null) return;
        for (let i = 0; i < dataDict.length; i++) {
            dataDict[i]['easyPace'] = stripHour(dataDict[i]['easyPace']);
            dataDict[i]['moderatePace'] = stripHour(dataDict[i]['moderatePace']);
            dataDict[i]['hardPace'] = stripHour(dataDict[i]['hardPace']);
        }
    }

    const populateZoneDates = (dataDict) => {
        if (dataDict === null) return;
        let dict = {};
        for (let i = 0; i < dataDict.length; i++) {
            dict[dataDict[i]['id']] = new Date(dataDict[i]['date']);
        }
        dict['NEW'] = new Date();
        setZoneDates(dict);
    }

    const stripHour = (stringWithHour) => {
        let timeparts = stringWithHour.split(':');
        let mins = timeparts[1];
        let secs = timeparts[2];
        let pace = mins + ":" + secs;
        return pace;
    }

    const addHour = (stringWithoutHour) => {
        let timeparts = stringWithoutHour.split(':');
        let mins = timeparts[0];
        let secs = timeparts[1];
        let pace = "00:" + mins.padStart(2, '0') + ":" + secs.padStart(2, '0');
        return pace;
    }

    const addPaceZone = (paceZoneData) => {
        axios
            .post("/api/pacezones/", paceZoneData)
            .then(response => { fetchData(url); })
            .catch(error => {
                toastOnError(error);
                setfetched(true);
            });
    };

    const updatePaceZone = async (paceZoneData, paceZoneId) => {
        try {
            setCurrentZoneProcessing(paceZoneData['name']);
            let response =
                await axios
                    .put("/api/pacezones/" + paceZoneId + "/", paceZoneData)
                    .then(updatePaceZonesForActivities(paceZoneId))
                    .catch(error => { toastOnError(error);});
        } catch (error) {
            toastOnError(error);
        }
    }
    // Scroll and the 'updated' text out of view and hide --> Not used, replaced by overlay and messaging in popup box
    /* 
    const disapparate = (elName, initHeight) => {

        let el = document.getElementById(elName);

        if (el != null) {
            let currentHeight = el.offsetHeight;
            // At first pass, get the initial height for the element
            // and store that to pass on to subsequent (recursive) calls
            // It will be used to reset the element to its original height
            // on the last iteration
            if (initHeight == null) {
                initHeight = currentHeight + "px";
            }
            if (parseInt(currentHeight) === 0) {
                document.getElementById(elName).className = 'updateConfirmationHidden';
                document.getElementById(elName).setAttribute("style", "height:" + initHeight);
                return;
            }
            // Subract a pixel
            let newHeight = parseInt(currentHeight) - 1;
            newHeight = newHeight + "px";
            el.setAttribute("style", "height:" + newHeight);
            // Recurseviely call the function
            setTimeout(disapparate, 50, elName, initHeight);
            return;
        }
    }
    */

    const updatePaceZonesForActivities = (paceZoneId) => {
        setProcessingPaceZonesForActivities(true);
        // Call the API to process the pace zones
        let url = process.env.REACT_APP_PROCESSPACEZONESSURL + "?pacezone=" + paceZoneId;
        axios.get(url)
            .then((getResponse) => {
                //console.log("Processing pace zone. Response:");
                //console.log(getResponse);
                let jobId = getResponse.data["job_id"];
                if (jobId === -1) {
                    // A synchronous call was made. 
                    // Once the response returns, the process is done
                    // In the mean time, no status updates can be retrieved
                    setProcessingPaceZonesForActivities(false);
                    setCurrentZoneProcessing(null);

                    // Re-run the update totals call
                    axios.get(process.env.REACT_APP_TOTALSURL).catch((error) => {
                        console.error(error);
                    });

                }
                else {
                    setIsAsyncCall(true);
                    pollJobStatus(jobId);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const pollJobStatus = (jobId) => {

        document.getElementById('processingDialogProgressText').style.visibility = 'visible';
        document.getElementById('progressBarOuter').style.visibility = 'visible';

        let url = process.env.REACT_APP_JOBSTATUS + jobId;
        axios.get(url)
            .then((getResponse) => {

                //console.log("Processing pace zones. Response:");
                //console.log(getResponse);
                let status = getResponse.data["status"];
                let progress = getResponse.data["progress"];
                let numTotal = getResponse.data["numtotal"];
                let numProcessed = getResponse.data["numprocessed"];
                let newWidth = parseInt(numProcessed / numTotal * 100) + '%';

                if (status === 'finished') {
                    // Done, close the overlay
                    setProcessingPaceZonesForActivities(false);
                    setCurrentZoneProcessing(null);

                    // Re-run the update totals call
                    axios.get(process.env.REACT_APP_TOTALSURL).catch((error) => {
                        console.error(error);
                    });
                }
                else if (status === 'processing') {
                    // Still processing, write the progress info into the overlay
                    document.getElementById('processingDialogProgressText').innerHTML = 'Laps processed: ' + progress;
                    document.getElementById('progressBarInner').setAttribute("style", "width:" + newWidth);
                    // Recursively call self to re-poll in 1s
                    setTimeout(pollJobStatus, 100, jobId);
                }
                else {
                    // Unknown status
                    console.error('Unable to retrieve job status for job ', jobId)
                }

            })
            .catch((error) => {
                console.error(error);
            });
    }

    const deletePaceZone = (paceZoneId) => {
        setZoneToDelete(paceZoneId);
        deletePaceZoneWithConfirm(true, false);
    };

    const deletePaceZoneWithConfirm = (requestConfirm, confirmed) => {
        if (requestConfirm) {
            // If the delete button was hit, without the modal having been triggered, trigger the modal
            setToggleTriggerDeleteModal(true);
            return;
        }
        else if (confirmed) {
            axios
                .delete("/api/pacezones/" + zoneToDelete)
                .then(response => { fetchData(url); })
                .catch(error => {
                    toastOnError(error);
                });
        }
        setToggleTriggerDeleteModal(false);

    };

    const rowDelete = (rowId) => {
        let paceZoneId = rowId['id'].toString();
        deletePaceZone(paceZoneId);
    }

    const validateTitleField = (rowId, inputId, errTextEl, buttonId) => {
        let titleValue = document.getElementById(inputId).value.toString();
        let err = '';

        document.getElementById(errTextEl).innerHTML = '';
        if (titleValue.length === 0) {
            err = 'Empty name. Please provide a name for the pacezone.';
            document.getElementById(errTextEl).innerHTML = err;
            return true;
        }
        if (titleValue.length > 99) {
            err = 'Please use max 100 characters.';
            document.getElementById(errTextEl).innerHTML = err;
            return true;
        }
        return false;
    }

    const validatePaceField = (rowId, inputId, errTextEl, buttonId) => {
        // Regex for the mm:ss format. Will allow entries from 0:00 to 29:59
        let regexPattern = /^([0-2]{0,1}[0-9]):([0-5][0-9])$/ig;
        let paceValue = document.getElementById(inputId).value.toString();
        let err = '';

        document.getElementById(errTextEl).innerHTML = '';
        let hits = paceValue.search(regexPattern);

        if (hits == null || hits < 0) {
            err = 'Please use mm:ss format and a pace of max 29:59.';
            document.getElementById(errTextEl).innerHTML = err;
            return true;
        }
        return false;
    }

    const setZoneDate = (date, rowId) => {
        // Needed to get the Datepicker to close
        setStartDate(new Date());

        // Hide the update success text
        document.getElementById('updateConfirmation_' + rowId).className = 'updateConfirmationHidden';

        // Update the newly passed date into the state dict
        let dict = zoneDates;
        dict[rowId] = date;
        setZoneDates(dict);
    }
    // Validate that all fields in the row meet criteria
    const validateRow = (rowId) => {

        // Note form field validation for the date is not needed, as incorrect dates will be set back
        // to the original date by the Datepicker component

        // Hide the update success text
        document.getElementById('updateConfirmation_' + rowId).className = 'updateConfirmationHidden';

        // Set button to active and enabled
        let errors = false;
        document.getElementById('rowButton_' + rowId).disabled = false;
        if (rowId === 'NEW') {
            document.getElementById('rowButton_' + rowId).className = "btnStandard btnStandardAddNew";
        }
        else {
            document.getElementById('rowButton_' + rowId).className = "btnStandard btnStandardUpdate";
        }
        
        let res1 = false, res2 = false, res3 = false, res4 = false;
        res1 = validatePaceField(rowId, 'easyPace_' + rowId, 'easyPace_errortext' + rowId, 'rowButton_' + rowId);
        res2 = validatePaceField(rowId, 'moderatePace_' + rowId, 'moderatePace_errortext' + rowId, 'rowButton_' + rowId);
        res3 = validatePaceField(rowId, 'hardPace_' + rowId, 'hardPace_errortext' + rowId, 'rowButton_' + rowId);
        res4 = validateTitleField(rowId, 'name_' + rowId, 'name_errortext' + rowId, 'rowButton_' + rowId);

        errors = errors + res1 + res2 + res3 + res4;

        // Set button to inactive and disabled style if errors found
        if (errors > 0) {
            document.getElementById('rowButton_' + rowId).className = "btnStandard btnStandardDisabled";
            document.getElementById('rowButton_' + rowId).disabled = true;
        }
        setFieldErrors(errors);
    }

    const formatColHeaders = (columnTitle) => {
        switch (columnTitle) {
            case 'name':
                return 'Pace zone name';
            case 'date':
                return 'Date (yyyy-mm-dd)';
            case 'easyPace':
                return 'Easy pace (min/km)';
            case 'moderatePace':
                return 'Moderate pace (min/km)';
            case 'hardPace':
                return 'Hard pace (min/km)';
            default:
                return columnTitle;
        }
    }

    const rowClick = (rowId, isNew) => {
        
        let paceZoneId = rowId['id'].toString();
        if (fieldErrors) {
            console.error('Cannot update, validations failed for ', paceZoneId);
        }
        else {
            let dict = {};
            for (let i = 0; i < colHeaders.length; i++) {
                let elName = colHeaders[i] + '_' + paceZoneId;
                
                let val = document.getElementById(elName).value;
                // If this is a pacezone mm:ss value, the hours need adding back in
                if ((colHeaders[i] === 'easyPace') || (colHeaders[i] === 'moderatePace') || (colHeaders[i] === 'hardPace')) {
                    val = addHour(val);
                }
                dict[colHeaders[i]] = val;
                
            }

            if (isNew) {
                addPaceZone(dict);
            }
            else {
                updatePaceZone(dict, paceZoneId);

                // In the case of a field update, write the values of the inputs back into the state's data dict,
                // to keep the state data in sync with the remote data
                let tempData = data;
                for (let i = 0; i < tempData.length; i++) {
                    if (tempData[i]['id'] == paceZoneId) {
                        // The id key-value pair needs adding back in
                        // as this is not present in the data coming from the table
                        dict['id'] = paceZoneId;
                        tempData[i] = dict;
                    }
                }
                setData(tempData);
            }
        }
    }

    const renderTableHeader = () => {
        return colHeaders.map((key, index) => {
            return <th key={index}>{formatColHeaders(key)}</th>
        })
        return;
    }

    const writeDataRow = (paceZone) => {
        const { id, name, date, easyPace, moderatePace, hardPace } = paceZone; //destructuring. See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Destructuring_assignment

        return (
            <tr key={id} id={'tablerow_' + id}>
                <td style={{ 'width': '20px', 'display': 'none', }}>
                    {id}<input type="text" id={'id_' + id} value={id} readOnly />
                </td>
                <td className="cellW210">
                    <input type="text" id={'name_' + id} onChange={(e) => validateRow(id)} className="inputZoneTitleValue" defaultValue={name} />
                    <br />
                    <span id={'name_errortext' + id} className="errorTextWrongPaceFormat"></span>
                </td>
                <td className="cellW110">
                    <Datepicker
                        id={'date_' + id}
                        className="dateSelector"
                        dateFormat="yyyy-MM-dd"
                        selected={zoneDates[id]}
                        onChange={(date) => setZoneDate(date, id)}
                        fixedHeight
                    />
                    <span id={'date_errortext' + id} className="errorTextWrongPaceFormat"></span>
                </td>
                <td className="cellW110">
                    <input type="text" id={'easyPace_' + id} onChange={(e) => validateRow(id)} className="inputPaceValue" defaultValue={easyPace} />
                    <br />
                    <span id={'easyPace_errortext' + id} className="errorTextWrongPaceFormat"></span>
                </td>
                <td className="cellW110">
                    <input type="text" id={'moderatePace_' + id} onChange={(e) => validateRow(id)} className="inputPaceValue" defaultValue={moderatePace} />
                    <br />
                    <span id={'moderatePace_errortext' + id} className="errorTextWrongPaceFormat"></span>
                </td>
                <td className="cellW110">
                    <input type="text" id={'hardPace_' + id} onChange={(e) => validateRow(id)} className="inputPaceValue" defaultValue={hardPace} />
                    <br />
                    <span id={'hardPace_errortext' + id} className="errorTextWrongPaceFormat"></span>
                </td>
                <td className="cellW110">
                    <button id={'rowButton_' + id} className="btnStandard btnStandardUpdate" onClick={(e) => rowClick({ id }, false)}>
                        Update
                    </button>
                    <span id={'updateConfirmation_' + id} className="updateConfirmationHidden">Successfully updated</span>
                </td>
                <td className="cellW100">
                    <button id={'deleteButton_' + id} className="btnStandard btnStandardDelete" onClick={(e) => rowDelete({ id })}>
                        Delete
                    </button>
                </td>
            </tr>
        )
    }

    const renderTableData = () => {
        if (data == null) return '';
        // Use array map function to loop through the data elements
        return data.map(writeDataRow);
    }

    const renderEmptyRow = () => {
        let id = 'NEW';
        return (
            <tr key={id}>
                <td style={{ 'width': '20px', 'display': 'none', }}>
                    <input type="text" id={'id_' + id} value="" readOnly />
                </td>
                <td className="cellW210">
                    <input type="text" id={'name_' + id} onChange={(e) => validateRow(id)} className="inputZoneTitleValue" defaultValue="New zones entry" />
                    <br />
                    <span id={'name_errortext' + id} className="errorTextWrongPaceFormat"></span>
                </td>
                <td className="cellW110">
                    <Datepicker
                        id={'date_' + id}
                        className="dateSelector"
                        dateFormat="yyyy-MM-dd"
                        selected={zoneDates[id]}
                        onChange={(date) => setZoneDate(date, id)}
                        fixedHeight
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                    />
                    <span id={'date_errortext' + id} className="errorTextWrongPaceFormat"></span>
                </td>
                <td className="cellW110">
                    <input type="text" id={'easyPace_' + id} onChange={(e) => validateRow(id)} className="inputPaceValue" defaultValue="5:00" />
                    <br />
                    <span id={'easyPace_errortext' + id} className="errorTextWrongPaceFormat"></span>
                </td>
                <td className="cellW110">
                    <input type="text" id={'moderatePace_' + id} onChange={(e) => validateRow(id)} className="inputPaceValue" defaultValue="4:45" />
                    <br />
                    <span id={'moderatePace_errortext' + id} className="errorTextWrongPaceFormat"></span>
                </td>
                <td className="cellW110">
                    <input type="text" id={'hardPace_' + id} onChange={(e) => validateRow(id)} className="inputPaceValue" defaultValue="3:55" />
                    <br />
                    <span id={'hardPace_errortext' + id} className="errorTextWrongPaceFormat"></span>
                </td>
                <td className="cellW100">
                    <button id={'rowButton_' + id} className="btnStandard btnStandardAddNew" onClick={(e) => rowClick({ id }, true)}>
                        Add New
                        <span id={'updateConfirmation_' + id} className="updateConfirmationHidden">Successfully added</span>
                    </button>
                </td>
                <td className="cellW100"></td>
            </tr>
        )
    }

    return (
        <>
            <div>
                {processingPaceZonesForActivities ?

                    <div id="overlayPane">
                        <div id="processingDialogModalWindow">
                            <div id="processingDialogInnerText">Updating and applying new paces for pacezone <i>{currentZoneProcessing}</i>.
                                <br /><br />
                                <div id="processingDialogProgressText">
                                    Please bear with the lap cruncher... this may take some time.&nbsp;
                                </div>
                                <br />
                                {isAsyncCall ?
                                    <div id="progressBarOuter"><div id="progressBarInner"></div></div>
                                    :
                                    <div id="spinnerDiv">
                                        <ThreeDots className="spinner" color="#00BFFF" height={40} width={40} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
            </div>
            {toggleTriggerDeleteModal ? (<ConfirmDelete title="Delete Pacezone?" text="Are you sure you want to delete this pacezone?" callback={deletePaceZoneWithConfirm} />) : null}
            <div>
                {dataFetched ? (
                    <div style={{ 'overflowX': 'auto' }}>
                        <table id='datatable'>
                            <tbody>
                                <tr>{renderTableHeader()}<th></th><th></th></tr>
                                {data != null ? (renderTableData()) : null}
                                {renderEmptyRow()}
                            </tbody>
                        </table>
                    </div>
                ) : <div className="dotWrapper"><ThreeDots color="hotpink" height={40} width={40} /></div>}
            </div>
        </>

    )
}