import React from "react";
import NavigationBar from "../../components/nav/NavigationBar";
import ActivityCalendar from "../../components/dataviews/ActivityCalendar";

export default function ActivityOverview() {

    return (
        <main>
            <NavigationBar />
            <div className="pageBody">
                <div className="row m-1">
                    <div className="col-md-12 col-sm-12 p-1">
                        <div className="card p-2" id="pageheaderbar">
                            <h5 className="p1" id="activityPageHeader">Activity Overview</h5>
                        </div>
                    </div>
                </div>

                <div className="row m-1">
                    <div className="col-md-12 col-sm-12 p-1">
                        <div className="card p-2" >
                            <ActivityCalendar callbackelement="activityPageHeader" />
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}


