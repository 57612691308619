import React, { Component } from 'react';
import axios from "axios";
import { ThreeDots } from 'react-loader-spinner';
import DataTable, { createTheme } from 'react-data-table-component';

// createTheme creates a new theme named solarized that overrides the build in dark theme
createTheme('solarized_exp', {
    text: {
        font: 'Arial, Helvetica, sans- serif',
        primary: '#c0c6cc',
        secondary: '#2aa198',
    },
    background: {
        default: '#265282',
    },
    context: {
        background: '#cb4b16',
        text: '#FFFFFF',
    },
    divider: {
        default: '#073642',
    },
    action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
    },
}, 'dark');


export default class TableRowExpandedRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataFetched: false,
            tableHeaders: [],
            tableData: [],
        }
    }
    componentDidMount = () => {
        this.fetchData();
    }

    fetchData = () => {

        this.setState({ dataFetched: false });

        let model = '', dataUrl = '', headers = '', columns = [];

        // Determine what model the parent row has
        // This is done based on the properties of the parent {data} object
        for (const [key, value] of Object.entries(this.props.data)) {
            // This is a 'Totals' object if the first key is a 'weeknum' field,
            // the model to retrieve the details of are the underlying activities
            if (key == 'weeknum') {
                model = 'Activity';
                dataUrl = process.env.REACT_APP_APIBASEURL + '/api/activities?weeknum=' + value;
                break;
            }
            // This is an 'Activities' object if the first key is an 'activityID' field
            // the model to retrieve the details of are the underlying laps
            if (key == 'activityID') {
                model = 'Lap';
                dataUrl = process.env.REACT_APP_APIBASEURL + '/api/laps?activity=' + value;
                break;
            }
        }

        // first get the headers
        axios.get(process.env.REACT_APP_APIBASEURL + '/api/colheaders')
            .then((getResponse) => {

                // Retrieve the headers via the model name
                headers = getResponse.data[model];

                for (let i = 0; i < headers.length; i++) {
                    let colDef = {};

                    //colDef['id'] = headers[i];
                    // If the fieldname contains 'display_' remove this and capitalize all fieldnames
                    let name = headers[i].replace('display_', '');
                    name = name[0].toUpperCase() + name.substring(1);
                    colDef['name'] = name;
                    let selector = 'row.' + headers[i];
                    colDef['selector'] = row => eval(selector);

                    // Omit the weeknum column, as that will always have the value of the
                    // activity / totals week selected
                    if (name == 'Weeknum') colDef['omit'] = true;
                    colDef['sortable'] = true;
                    // Omit the activityID column for laps, as that will always have the value of the 
                    // actvity selected
                    if (model == 'Lap' && name == 'ActLap') colDef['omit'] = true;

                    // Add hyperlink if activityId column
                    if (model == 'Activity' && headers[i] == 'activityID') {
                        colDef['cell'] = row => <a className="DataTableExpandedRowLink" href={'/activity/' + row.activityID}>{ row.activityID }</a>;
                    }

                    // Add hyperlink if Title (of actvity) column
                    if (model == 'Activity' && headers[i] == 'title') {
                        colDef['cell'] = row => <a className="DataTableExpandedRowLink" href={'/activity/' + row.activityID}>{row.title}</a>;
                    }

                    // Format number to two-digits if the column conatains a distance
                    if (headers[i] == 'distance') {
                        let format = 'row.' + headers[i] + '.toFixed(2)';
                        colDef['format'] = row => eval(format);
                    }
                    columns.push(colDef);

                }

                // Next retrieve the data
            }).then(
                axios.get(dataUrl)
                    .then((getResponse) => {
                        let data = getResponse.data;

                        /*
                        // Dirty hack to make a hyperlink for the activity URLs
                        if (model == 'Activity') {
                            for (let i = 0; i < data.length; i++) {
                                let dict = data[i];
                                let activityId = dict['activityID'];
                                let hyperlink = '<a href="/activity/' + activityId + '">' + activityId + '</a>';
                                console.log(hyperlink);
                                dict['activityID'] = eval(hyperlink);
                                data[i] = dict;
                            }
                            console.log(data);
                        }
                        */

                        this.setState({ tableData: data, tableHeaders: columns, dataFetched: true, })
                    }).catch((error) => {
                        console.error('ERROR retrieving expanded row data');
                        console.error(error);
                    }));
        return;
    }
   
     

    render = () => {
        if (this.state.dataFetched) {
            return (<main><div><DataTable columns={this.state.tableHeaders} data={this.state.tableData} highlightOnHover theme={this.props.theme}/></div></main>);
        }
        else {
            return (<main><div className="row m-1"><div className="col-md-12 col-sm-12 mx-auto p-1">
                        <ThreeDots color="#00BFFF" height={40} width={40} />
                    </div></div></main>
                );
        }
    }
}