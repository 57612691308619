import React, { useState } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { logout2 } from "../../components/login/LoginActions";
import ModalFitUpload from "../../views/app/ModalFitUpload";
import ModalFitDownload from "../../views/app/ModalFitDownload";
import useBreakpoints from "../../utils/useBreakpoints";
import '../../css/navigation.css';

export default function NavigationBar(parentprops) {
    const [modalFitUpload, setModalFitUpload] = useState(false);
    const [modalFitDownload, setModalFitDownload] = useState(false);    
    // Helper function to make use of breakpoints and set the contents of the responsive menu based on those
    const point = useBreakpoints();

    // Toggle function for the togglestate of the upload modal window
    // Passsed to the upload modal as callback, to control the togglestate from there
    const togglefitupload = () => {
        setModalFitUpload(!modalFitUpload);
    };

    // Toggle function for the togglestate of the download modal window
    // Passsed to the download modal as callback, to control the togglestate from there
    const togglefitdownload = () => {
        setModalFitDownload(!modalFitDownload);
    };    

    // get username from localstorage
    var username = () => { return "" };
    var avatarImage = () => { return (<img className="navAvatarImage30px" src="/profile.png"/>) };
    var usr = JSON.parse(localStorage.getItem("user"));
    if (usr != null) {
        username = () => { return (usr["username"]); }

        if (usr["avatar"] != null) {
            avatarImage = () => { return (<img className="navAvatarImage" src={usr["avatar"]}/>); }
        }
    }

    const getNavClass = () => {
        if (process.env.REACT_APP_ENVNAME === 'DEV') {
            return "mainNavBar mainNavBarDevEnvironment";
        }
        if (process.env.REACT_APP_ENVNAME === 'STAG') {
            return "mainNavBar mainNavBarStagEnvironment";
        }
        return "mainNavBar";
    }

    return (
        <>
            {modalFitUpload ? (<ModalFitUpload callback={togglefitupload} />) : null}
            {modalFitDownload ? (<ModalFitDownload callbackToShowModal={togglefitdownload} />) : null}

            {process.env.REACT_APP_ENVNAME != 'PROD' ?
                (<div className="envTag">{process.env.REACT_APP_ENVNAME} {process.env.npm_package_name} V{process.env.REACT_APP_VERSION}</div>) : null
            }

            <Navbar id="navbar" className={getNavClass()} variant="dark" collapseOnSelect expand="md" fixed="top">
                <Navbar.Brand id="navbrand" href="/"><img src="/Fa_cutout.png" width="42" alt="home" title="Home" className="navBrandImage" /></Navbar.Brand>
                <Nav.Link href="/" id="navtitle">FIT Analyzer</Nav.Link>
                {(point === 'sm' || point === 'xs') ? (
                    <span className="avatarBoxSmallscreen" >{avatarImage()} </span>
                ) : null}
                <Navbar.Toggle></Navbar.Toggle>
                <Navbar.Collapse className="justify-content-end" id="navcollapse">
                    <Nav className="justify-content-end">
                        <Nav.Link href="/dashboard" className="navLinkBox"><img src="/dashboard.png" alt="dashboard" title="Dashboard" className={(point != 'sm' && point != 'xs') ? ("navLinkImage") : null} />{(point === 'sm' || point === 'xs') ? <span className="navLinkItem">Dashboard</span> : null}</Nav.Link>
                        <Nav.Link href="/activities" className="navLinkBox"><img src="/calendar.png" alt="activities" title="Activity calendar" className={(point != 'sm' && point != 'xs') ? ("navLinkImage") : null} />{(point === 'sm' || point === 'xs') ? <span className="navLinkItem">Activities</span> : null}</Nav.Link>
                            <Nav.Link href="/datatables" className="navLinkBox"><img src="/table.png" alt="datatables" title="Data tables" className={(point != 'sm' && point != 'xs') ? ("navLinkImage") : null} />{(point === 'sm' || point === 'xs') ? <span className="navLinkItem">Data tables</span>: null}</Nav.Link>
                            <Nav.Link id="lnkDownloadFromGarmin" onClick={(e) => togglefitdownload(e)} className="navLinkBox"><img src="/download_connect.png" alt="Download activities from Garmin Connect" title="Download activities from Garmin Connect" className={(point != 'sm' && point != 'xs') ? ("navLinkImage") : ("downloadConnectIcon")} />{(point === 'sm' || point === 'xs') ? <span className="navLinkItem">Download Garmin activities</span>: null}</Nav.Link>
                            <Nav.Link onClick={(e) => togglefitupload(e)} className="navLinkBox"><img src="/upload.png" alt="Upload FIT" title="Manually upload FIT file" className={(point != 'sm' && point != 'xs') ? ("navLinkImage") : null} />{(point === 'sm' || point === 'xs') ? <span className="navLinkItem">Manually upload FIT</span>: null}</Nav.Link>
                            <Nav.Link href="/pacezones" className="navLinkBox"><img src="/pace.png" alt="pacezones" title="Pace zones" className={(point != 'sm' && point != 'xs') ? ("navLinkImage") : null} />{(point === 'sm' || point === 'xs') ? <span className="navLinkItem">Pace zones</span>: null}</Nav.Link>
                            <Nav.Link href="/powerzones" className="navLinkBox"><img src="/power.png" alt="powerzones" title="Power zones" className={(point != 'sm' && point != 'xs') ? ("navLinkImage") : null} />{(point === 'sm' || point === 'xs') ? <span className="navLinkItem">Power zones</span>: null}</Nav.Link>
                            <Nav.Link href="/settings" className="navLinkBox"><img src="/settings.png" alt="settings" title="Settings, admin and batch processing" className={(point != 'sm' && point != 'xs') ? ("navLinkImage") : null} />{(point === 'sm' || point === 'xs') ? <span className="navLinkItem">Settings</span>: null}</Nav.Link>
                        {(point !== 'sm' && point !== 'xs') ? (
                            <Navbar.Text className="navText" >
                                <div className="navProfileContainer">{avatarImage()} 
                                    <span className="navUserTooltip">
                                        {username()}<br />
                                        <Nav.Link id="nav_logout" onClick={logout2}><img src="/logout.png" alt="logout" title="Logout" />&nbsp;Logout</Nav.Link>
                                    </span></div>
                            </Navbar.Text>
                        ) : null
                        }
                        {(point === 'sm' || point === 'xs') ? (
                                <Nav.Link id="nav_logout" onClick={logout2} className="navLinkBox"><img src="/logout.png" alt="logout" title="Logout" className={(point != 'sm' && point != 'xs') ? ("navLinkImage") : null} />{(point === 'sm' || point === 'xs') ? <span className="navLinkItem">{(point === 'sm' || point === 'xs') ? ('Logout user ' + username()) : null}</span>: null}</Nav.Link>
                        ) : null}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    );
}