import { useEffect, useRef } from 'react';
import axios from "axios";
import { toast } from "react-toastify";

export const setAxiosAuthToken = token => {
    if (typeof token !== "undefined" && token) {
        // Apply for every request
        axios.defaults.headers.common["Authorization"] = "Token " + token;
    } else {
        // Delete auth header
        delete axios.defaults.headers.common["Authorization"];
    }
};

export const toastOnError = error => {
    if (error.response) {
        // known error
        toast.error(JSON.stringify(error.response.data));
    } else if (error.message) {
        toast.error(JSON.stringify(error.message));
    } else {
        toast.error(JSON.stringify(error));
    }
};

export const capitalizeFirst = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const textEllipsis = (string, length) => {
    if (string === null) return '';
    if (string.length < length) return string;
    return string.slice(0, length) + '...';
}

export const isEmpty = value =>
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0);

// Formatter function to return percentage values for stacked bars
// Takes the dataIndex property from the context object. This corresponds to the x-axis position of the bar
// Takes the datasetIndex property from the context object. This corresponds to the position of the stack in the stack elements
// Uses these two numbers to pull all the values for the 'neighbouring' stack elements and sum these, 
// then divides the element value by this total to get the percentage value
export function stackedBarFormatter(value, ctx) {
    let numStacksInBarChart = ctx.chart.data.datasets.length;
    let total = 0;
    for (let i = 0; i < numStacksInBarChart; i++) {
        total += ctx.chart.data.datasets[i].data[ctx.dataIndex];
    }

    // A bit hacky, but don't return values below 8% as they get printed over 
    // other values and will mess up the UX
    if ((value / total * 100) > 8)
        return `${(value / total * 100).toFixed(0)}%`;
    else
        return '';
}

// Formatter function to return 2-decimal fixed format
export function horBarFormatter(value, ctx) {
    if (value != null)
        return `${(value).toFixed(2)}`;
    return '';

}

// Formatter function to return 2-decimal fixed format
export function asPercentage(value, ctx) {
    if (value != null && value > 1)
        return `${(value).toFixed(1)}%`;
    return '';

}

// Formatter function to return 2-decimal percentage from a fraction
export function percentageFromFraction(value, ctx) {
    if (value != null && value > 0)
        return `${(value*100).toFixed(0)}%`;
    return '';

}

// Format date into format 'Saturday, September 17, 2016'
export function prettyDateTime(rawDate) {
    //return (rawDate);
    var dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', };
    var timeOptions = { hour: '2-digit', minute: '2-digit' };
    var theDate = new Date(rawDate);
    return (theDate.toLocaleDateString("en-NL", dateOptions) + ' at ' + theDate.toLocaleTimeString("nl-NL", timeOptions)); 
}

// Format date into format 'Saturday, September 17, 2016'
export function shortDate(rawDate) {
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    var theDate = new Date(rawDate);
    return (theDate.toLocaleDateString("en-US", options)); // Saturday, September 17, 2016
}

// This script is released to the public domain and may be used, modified and
// distributed without restrictions. Attribution not necessary but appreciated.
// Source: https://weeknumber.com/how-to/javascript

// Returns the ISO week number of the date, .
// NOTE: The week is returned as string with a leading '0' if it is a single digit week
export function getWeekNum(dt = new Date()) {
    var date = new Date(dt.getTime());
    date.setHours(0, 0, 0, 0);

    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);

    // January 4 is always in week 1.
    var week1 = new Date(date.getFullYear(), 0, 4);

    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    let week = (1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
        - 3 + (week1.getDay() + 6) % 7) / 7)).toString();

    // Add a leading 0 to the week if it is a single digit number
    if (week.length == 1) week = '0' + week;

    return week;
}

// Returns the four-digit year corresponding to the ISO week of the date.
export function getWeekYear(dt = new Date()) {
    var date = new Date(dt.getTime());
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    return date.getFullYear();
}

export function addDays(dt, days) {
    var date = new Date(dt);
    date.setDate(date.getDate() + days);
    return date;
}

export function getFirstMonday(dt) {
    var date = new Date(dt);

    // Decrement the date until a Monday is hit
    while (date.getDay() !== 1) {
        date.setDate(date.getDate() - 1);
    }
    return date;
}

export function dateAsYYYYMMDD(dt) {
    var d = new Date(dt),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}